import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router";
import HildaAccountSubheader from "../../../components/hildaAccountSubheader/hildaAccountSubheader";
import Loading from "../../../components/loading/loading";
import PageHeader from "../../../components/pageHeader/pageHeader";
import ProductDetailsContainer from "../../../components/product/productDetailsContainer/productDetailsContainer";
import TabComponent from "../../../components/tabComponent/tabComponent";
import { IHilda } from "../../../shared/interfaces/ihilda.interface";
import { IProductSummary } from "../../../shared/interfaces/iproductSummary.interface";
import { IUser } from "../../../shared/interfaces/iuser.interface";
import { formatCurrency } from "../../../shared/utils/gridUtils";
import Loans from "../../loans/loans";
import { GET_HILDA_BY_ID } from "./hildaDetailsInfo.service";
import { useStyles } from "./hildaDetailsInfo.styles";

const HildaDetailsInfo = () => {
  const { hildaAccountId }: any = useParams();
  const classes = useStyles();
  const { data, loading, error, refetch } = useQuery(GET_HILDA_BY_ID, {
    variables: {
      whereHilda: { id: hildaAccountId },
      productSummariesWhereHilda: { id: hildaAccountId },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch({ whereHilda: { id: hildaAccountId } });
    // eslint-disable-next-line
  }, [hildaAccountId]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading isLoading />
      </div>
    );

  if (error) return null;

  const hilda: IHilda = data.hilda;

  const accountInfo = {
    accountNumber: hilda.id || "",
    joined: hilda.createdAt || "",
  };

  const advisor: IUser = hilda.advisor;
  const advisorName = advisor.firstName + " " + advisor.lastName;

  const productSummariesArray: IProductSummary[] = data.productSummaries;

  const productSummaries = productSummariesArray.filter(
    (productSummary) => productSummary.bankAllocations.length !== 0
  );

  const productDetails = productSummaries.map((productSummary) => {
    const bA = productSummary.bankAllocations[0];
    const complexProductData = productSummary.complexProductData;
    return {
      bankAllocationId: bA.id,
      productId: bA.product.id,
      name: bA.product.name,
      description: bA.product.description,
      shortName: bA.product.shortName,
      productReturn: productSummary.productReturnAverage || [],
      amount: formatCurrency(productSummary.amount || "0.00"),
      availableNowAmount: formatCurrency(
        productSummary.availableNowAmount || "0.00"
      ),
      bankReturnAverage: productSummary.bankReturnAverage,
      complexProductData,
    };
  });

  const amounts = productSummariesArray
    .filter((ps) => !!ps.amount)
    .map((ps) => parseFloat(ps.amount || ""));

  const total = formatCurrency(
    String(
      amounts.reduce((sum, num) => {
        return sum + num;
      }, 0)
    )
  );

  return (
    <div>
      <PageHeader
        title={hilda.name || ""}
        accountInfo={accountInfo}
        advisorName={advisorName}
        menuTabs={
          <TabComponent
            tabItems={[
              {
                label: "IFI Deposits",
                children: (
                  <>
                    <HildaAccountSubheader
                      totalAmount={total}
                      advisorCanTransact={hilda.advisorCanTransact}
                    />
                    <ProductDetailsContainer
                      productDetails={productDetails}
                      advisorCanTransact={hilda.advisorCanTransact}
                    />
                  </>
                ),
              },
              {
                label: "IFI Loans",
                children: <Loans />,
              },
            ]}
            containerStyle={classes.containerStyle}
            tabsRowStyle={classes.tabsRowStyle}
            childrenStyle={classes.childrenStyle}
          />
        }
      />
    </div>
  );
};

export default HildaDetailsInfo;
