import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    mainClientConfigContainer: {
      width: '95%',
      margin: '50px auto 40px auto',
    },
    advisorName: {
      width: '70%',
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.primary.main,
      fontFamily: Typography.FontFamily2,
      fontSize: '2.125rem',
      fontWeight: 500,
      lineHeight: 0.94,
      margin: '10px 0 50px 0',
      paddingBottom: 10,
    },
    subdomainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 20,
      marginBottom: 50,
    },
    subdomain: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.625rem',
      fontWeight: 600,
      lineHeight: 1.31,
      color: Colors.TextGray,
    },
    urlInput: {
      '& .MuiInputBase-input': {
        textAlign: 'right',
      },
      fontFamily: Typography.FontFamily2,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.palette.primary.main,
      '&:before': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.primary.main,
        transform: 'none',
      },
      '&:after': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.primary.main,
        transform: 'none',
      },
      marginBottom: 15,
    },
    subdomainUrl: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: 2.13,
      color: Colors.TextGray,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 30,
      marginBottom: 50,
    },
    formTypography: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.42,
      color: Colors.TextGray,
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    logoContainerButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
    },
    deleteButton: {
      width: 38,
      height: 38,
      backgroundColor: Colors.BorderAndBG,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: Colors.BorderAndBG,
      },
    },
    deleteIcon: {
      width: 14,
      height: 18,
    },

    colorInput: {
      width: 48,
      height: 48,
      border: 'none',
    },

    colorTextInput: {
      width: 90,
      height: 48,
    },

    codeContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    codeInput: {
      resize: 'none',
      width: '100%',
      fontFamily: Typography.FontFamily2,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 2.13,
      color: Colors.TextGray,
      borderRadius: 6,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      marginTop: 20,
      '&:placeholder': {
        marginLeft: 20,
      },
      outlineColor: theme.palette.primary.main,
      padding: 10,
      cursor: 'copy',
    },

    buttonsContainer: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
    cancelButton: {
      width: '126px',
      height: '38px',
      fontFamily: Typography.FontFamily2,
      fontSize: '1rem',
      fontWeight: 600,
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      padding: '8px 34px 7px',
      '&:hover': {
        backgroundColor: Colors.White,
      },
    },
    saveButton: {
      width: '126px',
      height: '38px',
      fontFamily: Typography.FontFamily2,
      fontSize: '1rem',
      fontWeight: 600,
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      padding: '8px 34px 7px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);
