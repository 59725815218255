import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.TextGray,
      textTransform: 'capitalize',
      margin: '0 20px',
      paddingLeft: 0,
      paddingRight: 0,
    },
    active: {
      color: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
  })
);
