import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      margin: '65px 65px 35px 65px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      fontFamily: Typography.FontFamily1,
      fontSize: Typography.H4,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.83,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.primary.main,
    },
    buttonReset: {
      padding: '8px 15px 7px',
      borderRadius: 3,
      border: 'solid 1px',
      borderColor: theme.palette.primary.main,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Body,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
    stepperBox: {
      margin: '30px 55px 80px 55px',
      borderRadius: 12,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      '& .MuiSvgIcon-root': {
        width: '34px',
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& .MuiStepIcon-text': {
        fill: Colors.White,
        fontSize: Typography.Footnotes,
        fontFamily: Typography.FontFamily2,
        fontWeight: 500,
      },
      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: '16px',
        textAlign: 'center',
        fontSize: Typography.Body,
        fontFamily: Typography.FontFamily2,
      },
    },
    container: {
      padding: '25px 55px 25px 55px',
    },
    content: {
      margin: '20px 0 50px 0',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 0 30px',
      '$ .Mui-disabled': {
        display: 'hidden',
      },
      '& .MuiButton-contained.Mui-disabled': {
        color: Colors.White,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
      },
    },
    buttonBack: {
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      width: 126,
      height: 38,
      color: theme.palette.primary.main,
      fontSize: Typography.Body,
      fontFamily: Typography.FontFamily2,
      fontWeight: 600,
    },
    buttonNext: {
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      width: 126,
      height: 38,
      color: Colors.White,
      fontSize: Typography.Body,
      fontFamily: Typography.FontFamily2,
      fontWeight: 600,
    },
    buttonHidden: {
      visibility: 'hidden',
    },
  })
);
