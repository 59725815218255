import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { FC, useState, useEffect, ChangeEvent } from "react";
import QRCodeCanvas from "qrcode.react";
import { useStyles } from "./profile.styles";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import useUserData from "../../shared/hooks/useUserData";
import { IUser } from "../../shared/interfaces/iuser.interface";
import { triggerAlert } from "../../components/toast/toast";
import { gql, useMutation } from "@apollo/client";
import Loading from "../../components/loading/loading";

const ACTIVATE_MFA_MUTATION = gql`
  mutation ActivateTOTP($username: String!) {
    activateMFA(username: $username) {
      firstName
    }
  }
`;

export const Profile: FC = () => {
  const [QRCodeURL, setQRCodeURL] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { userData } = useUserData();

  const [activateMFA] = useMutation(ACTIVATE_MFA_MUTATION);

  const classes = useStyles();

  const getCurrentUser = async (): Promise<CognitoUser> => {
    const user = Auth.currentAuthenticatedUser();

    if (!user) throw new Error("Unauthenticated user");

    return user;
  };

  const buildQRCode = (secret: string, { email }: IUser): string => {
    if (!email) throw new Error("User not authenticated");

    return `otpauth://totp/IFI:${email}?secret=${secret}&issuer=IFI`;
  };

  const startMFASetup = async () => {
    const user = await getCurrentUser();
    const secret = await Auth.setupTOTP(user);

    console.info("User data", userData);

    const code = buildQRCode(secret, userData.user);

    setQRCodeURL(code);
  };

  useEffect(() => {
    if (userData.authState !== "loading") startMFASetup();
  }, [userData]);

  const completeMFA = async () => {
    const user = await getCurrentUser();

    try {
      const username = await user.getUsername();

      await activateMFA({
        variables: {
          username,
        },
      });

      triggerAlert({
        msg: "You've successuflly setup MFA",
        title: "MFA",
        type: "success",
      });
    } catch (error: unknown) {
      if (error instanceof Error)
        triggerAlert({
          msg: error.message || "MFA has failed, please reach out to an admin",
          title: "MFA Activation Failed",
          type: "error",
        });
    } finally {
        setLoading(false)
    }
  };

  const validateToken = async (
    user: CognitoUser,
    token: string
  ): Promise<Boolean> => {
    if (token.length !== 6)
      throw new Error(
        "Please enter a 6-digit code. Ensure there are no extra characters or spaces."
      );

    try {
      const session = await Auth.verifyTotpToken(user, token);

      // @ts-expect-error outdated type in amplify
      return session.Status;
    } catch (error) {
      console.info("Token validation failed", error);
      throw new Error(
        "The code entered is not valid. Please try again or generate a new code."
      );
    }
  };

  const verifyToken = async (): Promise<void> => {
    setLoading(true);
    const user = await getCurrentUser();

    try {
      const isValid = await validateToken(user, token);

      console.info("Token is valid", isValid);

      await completeMFA();
    } catch (error) {
        setLoading(false);

      let message = "Unexpected error";

      if (error instanceof Error) message = error.message;

      triggerAlert({
        title: "Verification Failed",
        msg: message,
        type: "error",
      });
    }
  };

  const enterToken = (e: ChangeEvent<HTMLInputElement>): void => {
    setToken(e.target.value);
  };

  if (userData.authState === "loading") return <Loading isLoading />;

  return (
    <Grid
      container
      alignItems="stretch"
      spacing={6}
      className={classes.container}
    >
      <Grid item sm={12} className={classes.center}>
        <h1 className={classes.title}>Keep your account safe</h1>
      </Grid>
      <Grid item sm={12} className={classes.center}>
        <h2 className={classes.subtitle}>
          Secure your account with Multi-Factor Authentication (MFA) using the
          QR code below. Simply scan it with your authentication app to add an
          extra layer of protection to your login process.
        </h2>
      </Grid>

      <Grid item lg={12} sm={12} md={6} className={classes.center}>
        {QRCodeURL && <QRCodeCanvas value={QRCodeURL} size={200} />}
      </Grid>

      <Grid item lg={12} sm={12} className={classes.center}>
        <TextField
          type="text"
          className={classes.textField}
          onChange={enterToken}
          placeholder="6-digit Verification Code"
          label="Enter verification code"
        />
      </Grid>

      <Grid item lg={12} sm={12} className={classes.center}>
        <Button className={classes.submit} onClick={verifyToken} disabled={loading}>
          Verify token
          {loading && <CircularProgress size={14} className={classes.spinner} />}
        </Button>
      </Grid>
    </Grid>
  );
};
