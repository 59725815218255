import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';
import { diagonals } from '../../shared/utils/pathConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      paddingTop: "30vh"
    },
    fullHeight: {
      height: "100%"
    },
    header:{
      position:"relative", 
      top:"35vh", 
      marginLeft:"6vw",
      fontSize:"1.6rem",
    },
    paragraph:{
      position:"relative", 
      marginLeft:"6vw", 
      marginTop:"1.6vh",
      fontSize:"1.2rem",
    },
    logo:{      
    },
    image: {
      width: '100vw',
      height: '92.7vh',
    },
    title: {
      fontFamily: Typography.FontFamily1,
      fontSize: '2.125rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.secondary.main,
      margin: '20px 0px',
      textTransform: 'capitalize',
    },
    container: {
      alignSelf: 'flex-start',
      marginLeft: "60px"
    },
    iconButton: {
      width: 15,
      height: 18,
      marginRight: 10,
    },
    buttonStyle: {
      borderRadius: 3,
      border: `solid 1px ${theme.palette.secondary.main}`,
      width: 145,
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      padding: 8,
      marginBottom: 10,
    },
    linkStyle: {
      borderRadius: 3,
      border: `solid 1px ${theme.palette.secondary.main}`,
      width: 145,
      display: 'flex',
      justifyContent: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.secondary.main,
      cursor: 'pointer',
      padding: 8,
      marginBottom: 10,
      position:"relative",
      textDecoration: 'none'
    },
    legal: {
      padding: 8,
      borderTop: '1px solid rgba(0, 0, 0, .07)',
      fontSize: '12px'
    },
    buttonText: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Body,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    footerContainer: {
      height: '32.5vh',
      background: `url(${diagonals}) no-repeat`,
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
    },
    footer: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.BasicText,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
      paddingTop: '20vh',
      width: 'fit-content',
    },
  })
);
