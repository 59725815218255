import { withStyles } from '@material-ui/core';
import { Colors, Typography } from './shared/theme/styles.enum';

export const GlobalOverrideCss = withStyles(({ palette }) => ({
  '@global': {
    '.top-menu-active-style': {
      color: `${Colors.White} !important`,
      borderBottom: `4px solid ${palette.primary.main} !important`,
    },
    '.nav-link-active-account-result': {
      display: 'flex',
      borderLeft: '5px solid',
      borderLeftColor: palette.primary.main,
      backgroundColor: Colors.TableHover,
    },
    '.MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '.MuiTablePagination-root': {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.875rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#b7b7b7',
      marginTop: '0.875rem',
    },

    '.MuiTablePagination-toolbar .MuiTypography-body2': {
      fontSize: '0.875rem',
    },
  },
}))(() => null);
