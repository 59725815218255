import { gql } from '@apollo/client';

export const SUBMIT_ADVISOR_PREFILL = gql`
  mutation Mutation($onboardingForm: OnboardingForm!) {
    submitAdvisorPreFill(onboardingForm: $onboardingForm) {
      success
      message
    }
  }
`;
