import React, { useRef, useState } from 'react';
import { InputBase, useTheme } from '@material-ui/core';
import { clear } from '../../../shared/utils/pathConstants';
import { useStyles } from './inputFilter.styles';
import _ from 'lodash';
import { timeout } from '../../../shared/utils/valueConstants';
import MagnifierIcon from '../../../shared/icons/magnifierIcon';
import { Typography } from '../../../shared/theme/styles.enum';

interface ISearchFilterProps {
  searchValue: string;
  setSearchValue: any;
}

const InputFilter = (props: ISearchFilterProps) => {
  const { searchValue, setSearchValue } = props;
  const getServiceValue = (value: React.SetStateAction<string>) =>
    setSearchValue(value);
  const setDebouncedValue = useRef(_.debounce(getServiceValue, timeout));
  const [value, setValue] = useState(searchValue);
  const theme = useTheme();
  const classes = useStyles();

  const handleChange = ({ currentTarget: { value } }: any) => {
    setValue(value);
    setDebouncedValue.current(value);
  };

  const handleClear = () => {
    setValue('');
    setSearchValue('');
  };

  return (
    <div className={classes.search}>
      <MagnifierIcon
        alt="Search accounts"
        className={classes.searchIcon}
        maincolor={theme.palette.primary.main}
        size={Typography.BasicText}
      />
      <InputBase
        className={classes.input}
        placeholder="Search"
        value={value}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
      />
      <img
        className={classes.clearIcon}
        src={clear}
        alt="Clear search"
        onClick={handleClear}
      />
    </div>
  );
};

export default InputFilter;
