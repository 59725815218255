import React, { useState } from 'react';
import { Box, Tab, Tabs, useTheme } from '@material-ui/core';
import { useStyles } from './tabComponent.styles';
interface ITabItem {
  label: string;
  children?: any;
}
interface ITabComponentProps {
  tabItems: ITabItem[];
  containerStyle?: string;
  tabsRowStyle?: string;
  childrenStyle?: string;
}

const TabComponent = (props: ITabComponentProps) => {
  const { tabItems, containerStyle, tabsRowStyle, childrenStyle } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const theme = useTheme();

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={containerStyle}>
      <Tabs
        className={tabsRowStyle}
        value={value}
        TabIndicatorProps={{
          style: {
            color: theme.palette.primary.main,
            background: theme.palette.primary.main,
            borderBottom: '4px solid',
          },
        }}
        indicatorColor="primary"
        scrollButtons="auto"
        onChange={handleChange}
      >
        {tabItems?.map(({ label }, index) => (
          <Tab
            className={classes.tab}
            key={index}
            label={label}
            {...tabProps(index)}
          />
        ))}
      </Tabs>
      {tabItems?.map(({ children }, index) => (
        <div
          key={`simple-tabpanel-${index}`}
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          {value === index && <Box className={childrenStyle}>{children}</Box>}
        </div>
      ))}
    </div>
  );
};

export default TabComponent;
