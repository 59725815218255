import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../shared/theme/styles.enum';

export const viewsUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    globalViewContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
    },
    globalSideNavMenuItem: {
      color: Colors.TextGray,
      cursor: 'pointer',
      padding: '15px 0px 15px 22.9px',
      '&:hover': {
        backgroundColor: Colors.TableHover,
      },
    },
    globalSideNavTextItem: {
      fontSize: '1.375rem',
      fontFamily: Typography.FontFamily2,
      fontWeight: 600,
    },
    globalSideNavMenu: {
      textDecoration: 'none'
    },
    globalSideNavMenuActive: {
      borderLeft: '5px solid',
      borderLeftColor: theme.palette.primary.main,
      backgroundColor: Colors.TableHover,
    },
  })
);
