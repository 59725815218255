import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
      height: "100%",
    },
  })
);
