import { Typography } from '@material-ui/core';
import ConfirmationForm from '../../../../components/forms/confirmation/confirmation';
import { IAwaitingClientApprovalFields } from '../../../../shared/interfaces/iform';
import { useStyles } from './awaitingClientApprovalComponent.styles';

interface IProps {
  hildaAccountInfo: IAwaitingClientApprovalFields;
  advisorInfo: boolean;
}

const AwaitingClientApprovalComponent = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h2">
          This account is pending client approval
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          Contact IFI support if you have any questions.
        </Typography>
        <hr className={classes.divider} />
        <div className={classes.content}>
          <Typography variant="h3" className={classes.accountSummary}>
            Account Summary
          </Typography>
          <ConfirmationForm
            displayTitle
            hildaAccountInfo={props.hildaAccountInfo}
            advisorInfo={props.advisorInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default AwaitingClientApprovalComponent;
