import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import { IComplexProductData } from "../../../shared/interfaces/icomplexProductData.interface";
import { formatCurrency } from "../../../shared/utils/gridUtils";
import { useStyles } from "./productCard.styles";

interface IProps {
  complexProductBankTableDetails: IComplexProductData;
  showButton: (amount: string, role?: string, canTransact?: boolean) => boolean;
  handleDeposit: () => void;
}

const ProductBankTable = (props: IProps) => {
  const { complexProductBankTableDetails, showButton, handleDeposit } = props;
  const classes = useStyles({});

  return (
    <div className={classes.hybaContainer}>
      <div className={classes.hybaWrapper}>
        <Grid container className={classes.grid} spacing={4}>
          <Grid container item sm={3} md={3} lg={3} direction="column">
            <Typography className={classes.header}>HYBA Banks</Typography>
          </Grid>
          <Grid container item sm={9} md={9} lg={9} direction="column">
            <Typography className={classes.header}>APY</Typography>
          </Grid>
          {(complexProductBankTableDetails.positions || []).map(
            (item, index: number) => {
              const apy =
                item.apy === "0" || isNaN(Number(item.apy))
                  ? "- %"
                  : Number(item.apy).toFixed(2) + "%";
              return (
                <>
                  <Grid container item sm={3} md={3} lg={3} direction="column">
                    <Typography className={classes.name}>
                      {item.bankName}
                    </Typography>
                  </Grid>
                  <Grid container item sm={2} md={2} lg={1} direction="column">
                    <Typography className={classes.apy}>{apy}</Typography>
                  </Grid>
                  <Grid container item sm={7} md={7} lg={8} direction="column">
                    <div style={{ display: "flex" }}>
                      <Hidden only={["sm"]}>
                        <hr className={classes.line} />
                      </Hidden>
                      {showButton(item.amount || "") ? (
                        <Button
                          className={`${classes.depositButton}`}
                          onClick={handleDeposit}
                        >
                          Deposit Now!
                        </Button>
                      ) : (
                        <Typography className={classes.totalAmount}>
                          {item.amount === "0" ? (
                            <span className={classes.hybaTotal}>$-</span>
                          ) : (
                            <span className={classes.hybaTotal}>
                              Total: ${formatCurrency(item.amount || "")}
                            </span>
                          )}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </>
              );
            }
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ProductBankTable;
