import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: Colors.White,
      width: '100%',
      border: '3px dashed',
      borderColor: theme.palette.primary.main,
      borderRadius: 10,
      padding: '3rem',
    },

    uploadButton: {
      padding: '0.85em 2.85em 0.85em 2.85em',
      fontFamily: Typography.FontFamily2,
      fontSize: '1.3125em',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.95,
      letterSpacing: 0.32,
      textAlign: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    uploadButtonWorkflow: {
      padding: '8px 17.5px 7px 14px',
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.95,
      letterSpacing: 0.32,
      textAlign: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    uploadLogoButton: {
      width: 152,
      height: 38,
      padding: '8px 12.5px 7px 14px',
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    maintext: {
      color: Colors.Black,
      fontSize: Typography.H5,
      fontWeight: 'bold',
      padding: 0,
      margin: 0,
    },

    subtext: {
      color: Colors.TextGray,
      margin: '1rem 0 0.5rem 0',
    },

    folderIcon: {
      marginBottom: '.2rem',
      maxWidth: '100%',
      fontSize: 133,
    },

    progressBar: {
      marginTop: 10,
      display: 'view',
    },

    fileNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logoButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logoImage: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },

    uploadedFileText: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.Black,
      marginLeft: '.5rem',
    },
    deleteButton: {
      width: 38,
      height: 38,
      backgroundColor: Colors.BorderAndBG,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: Colors.BorderAndBG,
      },
    },
    logoSizetext: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Captions,
      lineHeight: 1.93,
      color: Colors.TextGray,
    },
  })
);
