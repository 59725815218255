import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    formControl: { width: '100%' },
    textfield: {
      width: '100%',
      padding: '20px 65px',
      borderRadius: 12,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: Colors.White,
      '& .MuiInputBase-input': {
        fontFamily: Typography.FontFamily2,
        fontSize: Typography.Footnotes,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: Colors.TextGray,
        paddingRight: 18,
      },
    },
    agree: {
      width: 'fit-content',
      '& .MuiFormControlLabel-label': {
        fontFamily: Typography.FontFamily2,
        fontSize: Typography.Body,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& svg': {
        width: '1em !important',
      },
    },
  })
);
