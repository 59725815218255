import { ReactElement, useContext } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { UserContext } from '../../shared/context/userContext';

interface ILinkProps {
  index?: number;
  id?: string; // This id is used to set our Active styles.
  to: string;
  hildaAccountId?: string;
  hildaStatusId?: string;
  hildaSubmitted?: boolean;
  children: ReactElement<any, any> | string;
  className?: string;
  activeClassName?: string;
  style?: any;
}

const Link = (props: ILinkProps) => {
  const { userData } = useContext(UserContext);
  const {
    index,
    id,
    to,
    hildaAccountId,
    hildaStatusId,
    hildaSubmitted,
    children,
    className,
    activeClassName,
    style,
  } = props;

  const path = generatePath(to, {
    advisorFirmId: userData.user.advisorFirmId,
    hildaAccountId: hildaAccountId || '0',
    hildaStatusId: hildaStatusId || '0',
    hildaSubmitted: hildaSubmitted,
  });

  return (
    <NavLink
      id={id}
      key={index}
      to={path}
      exact
      className={className}
      activeClassName={activeClassName}
      style={
        style
      }
      isActive={(match, location) => {
        if (id === 'Accounts') {
          return location.pathname.split('/').includes('accounts');
        } else if (id === 'Overview') {
          return location.pathname.split('/').includes('overview');
        } else if (match) return true;
        return false;
      }}
    >
      {children}
    </NavLink>
  );
};

export default Link;
