import { GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';

export const mapToColumns = (arrayOfObjects: {}[]) => {
  return Object.keys(arrayOfObjects[0])
    .filter((key) => key !== '__typename' && key !== 'id')
    .map((key) => {
      return { field: key, headerName: _.startCase(key), flex: 1 };
    });
};

export const mapRenderCell = (
  columns: { field: string; headerName: string }[],
  renderCell: (params: GridRenderCellParams) => JSX.Element
) => {
  return columns.map((column) => {
    return { ...column, renderCell };
  });
};

export const getLocaleDateTime = (dateTime: string = '') => {
  const d = new Date(dateTime);
  return [moment(d).format('MM/DD/YYYY'), moment(d).format('h:mm A')];
};

export const getLocaleTime = (dateTime: string = '') => {
  const d = new Date(dateTime);
  return d.toLocaleTimeString([], { timeStyle: 'short' });
};

export const getLocaleDate = (date: string = '') => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

export const getDateMonth = (date: string = '') => {
  const d = new Date(date);
  const index = d.getUTCMonth();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[index] + ', ' + d.getUTCFullYear();
};

export const convertDateFormat = (
  date?: string,
  fromFormat: string = 'YYYY-MM-DD',
  toFormat: string = 'MM/DD/YYYY'
) => {
  if (!date) return '';
  return moment(date, fromFormat).format(toFormat);
};

export const formatCurrency = (currency: string) => {
  const number = Number(currency);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .split('$')[1];
};

export const formatCurrencyCompact = (currency: string) => {
  const number = Number(currency);
  return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(number);
};

export const formatCurrencyNoDecimals = (currency: string) => {
  const number = Number(currency);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(number);
};

export const formatCurrencyNumber = (currency: string) => {
  const number = Number(currency);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .split('$')[1];
};
