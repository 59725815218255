import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, Typography } from "../../shared/theme/styles.enum";

export const useStyles = (drawerSize?: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      list: {
        width: drawerSize || 800,
      },
      fullList: {
        width: "auto",
      },
      transferButton: {
        padding: "3px 13px 1px 13px",
        width: "max-content",
        height: 42,
        backgroundColor: theme.palette.primary.main,
        color: Colors.White,
        borderRadius: 21,
        fontSize: "1.25rem",
        fontFamily: Typography.FontFamily2,
        fontWeight: "bold",
        textAlign: "center",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    })
  );
