import { OperationVariables, FetchResult, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import PlaidLinkComponent from './plaidLinkComponent';
import { CREATE_LINK_TOKEN, ICreateLinkTokenRes } from './plaidLink.service';
import moment from 'moment';

const PlaidLinkWrapper = () => {
  const [createLinkToken] = useMutation(CREATE_LINK_TOKEN);

  useEffect(() => {
    const tkn = localStorage.getItem('linkToken');
    const linkToken = tkn !== null ? JSON.parse(tkn) : '';

    if (linkToken === '' || !!linkTokenExpired()) {
      generateToken();
    }
    // eslint-disable-next-line
  }, []);

  const generateToken = async () => {
    const response: FetchResult<ICreateLinkTokenRes, OperationVariables> =
      await createLinkToken();

    localStorage.setItem(
      'linkToken',
      JSON.stringify(response.data?.createLinkToken.linkToken || '')
    );

    localStorage.setItem(
      'linkTokenTimestamp',
      JSON.stringify(moment(new Date()))
    );
  };

  const linkTokenExpired = () => {
    const timestamp = localStorage.getItem('linkTokenTimestamp');
    if (timestamp !== null) {
      const timeStampDateExpiration = moment(
        new Date(JSON.parse(timestamp))
      ).add(29, 'minutes');
      const now = moment(new Date());

      return timeStampDateExpiration.isBefore(now);
    }
  };

  return (
    <>
      <PlaidLinkComponent />
    </>
  );
};

export default PlaidLinkWrapper;
