import { useContext, useState } from "react";
import { UserContext } from "../../shared/context/userContext";
import Drawer from "../drawer/drawer";
import Transfer from "../drawer/transfer/transfer";
import { useStyles } from "./hildaAccountSubheader.styles";

interface IProps {
  totalAmount: string;
  advisorCanTransact: boolean;
}

const HildaAccountSubheader = (props: IProps) => {
  const { totalAmount, advisorCanTransact } = props;
  const { userData } = useContext(UserContext);
  const roleName = userData.user.role.name;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleToggleDrawer = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const showButton = () => {
    if (roleName === "HildaUserVO")
      return false;
    else if (roleName === "HildaUser" || advisorCanTransact) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <main className={classes.container}>
      <div className={classes.amount}>
        {totalAmount === "0" ? (
          <span className={classes.dash}>-</span>
        ) : (
          <span>Total Value: ${totalAmount}</span>
        )}
      </div>
      <div className={classes.transferBtn}>
        {showButton() && (
          <Drawer
            size={800}
            anchor={"right"}
            button={"+ Transfer"}
            open={open}
            handleToggleDrawer={handleToggleDrawer}
            handleOpenDrawer={handleOpenDrawer}
            handleCloseDrawer={handleCloseDrawer}
            content={<Transfer handleCloseDrawer={handleCloseDrawer} />}
          />
        )}
      </div>
    </main>
  );
};

export default HildaAccountSubheader;
