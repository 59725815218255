import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import _ from 'lodash';
import {
  mapToColumns,
  getLocaleDate,
  formatCurrency,
} from '../../../shared/utils/gridUtils';
import TableHeader from '../../tableHeader/tableHeader';
import { useStyles } from './transactionsTable.styles';
import { useQuery, NetworkStatus } from '@apollo/client';
import { GET_TRANSACTIONS_BY_BANK_ALLOCATION_ID } from './transactionsTable.service';
import Loading from '../../loading/loading';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import ExportIcon from '../../../shared/icons/exportIcon';
import { ITransaction } from '../../../shared/interfaces/itransaction.interface';
import { useTheme } from '@material-ui/core';
import { Typography } from '../../../shared/theme/styles.enum';

const initialPageSize = 5;

interface IProps {
  amount: string;
  bankAllocationId: string;
}

const TransactionsTable = (props: IProps) => {
  const classes = useStyles();
  const { amount, bankAllocationId } = props;
  const [take, setTake] = useState<number>(initialPageSize);
  const [page, setPage] = useState<number>(0);
  const [skip, setSkip] = useState<number | undefined>(undefined);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [withinDates, setWithinDates] = useState<
    { dateParam: string; range: string[] } | undefined
  >(undefined);
  const theme = useTheme();
  const { loading, data, error, networkStatus, refetch } = useQuery(
    GET_TRANSACTIONS_BY_BANK_ALLOCATION_ID,
    {
      variables: {
        where: {
          bankAllocationId,
        },
        countWhere: {
          bankAllocationId,
        },
        searchCriteria: {
          take,
          skip,
          withinDates,
        },
        countSearchCriteria: {
          withinDates,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [amount, bankAllocationId]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [withinDates, skip]);

  useEffect(() => {
    setSkip(page * take);
  }, [page, take]);

  useEffect(() => {
    if (dateRange.length === 0) {
      setWithinDates(undefined);
      // Reset table
      setPage(0);
    } else if (dateRange.length === 2) {
      setWithinDates({ dateParam: 'postedDate', range: dateRange });
      setPage(0);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const handleOnPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPage(0);
    setTake(pageSize);
  };

  if (loading || networkStatus === NetworkStatus.refetch || !data)
    return (
      <>
        <TableHeader title="Transaction Report" setDateRange={setDateRange} />
        <div className={classes.container}>
          <Loading isLoading />
        </div>
      </>
    );

  if (error) return null;

  const transactions = data.transactionsByBankAllocationId;
  const count = data.transactionsCountByBankAllocationId;

  if (transactions.length === 0)
    return (
      <>
        <TableHeader title="Transaction Report" setDateRange={setDateRange} />
        <div className={classes.container}>
          <h4 className={classes.noRows}>No rows to display</h4>
        </div>
      </>
    );

  // Map Columns to Table and filter Status field and CreatedAt
  const arr = mapToColumns(transactions).filter(
    (column) => column.field !== 'status' && column.field !== 'createdAt'
  );

  const valueFormatter = (params: GridValueGetterParams) => {
    const row = params.row;
    if (params.field === 'postedDate') {
      return row.status.status === 'pending'
        ? _.capitalize(row.status.status)
        : getLocaleDate(row.postedDate);
    }
    if (params.field === 'amount') {
      return '$' + formatCurrency(row.amount);
    }
    if (params.field === 'type') {
      return row.type.type;
    } else {
      return params.value;
    }
  };

  const sortComparator: GridComparatorFn = (
    v1: any,
    v2: any,
    cellParams1,
    cellParams2
  ) => {
    if (cellParams1.field === 'postedDate') {
      const api = cellParams1.api;
      const status1 = api.getRowParams(cellParams1.id).status?.status;
      const status2 = api.getRowParams(cellParams2.id).status?.status;
      if (status1 === 'pending' || status2 === 'pending') return 1;
      const date1 = moment(v1, 'L').format();
      const date2 = moment(v2, 'L').format();
      return moment(date1).isAfter(date2) ? 1 : -1;
    } else if (cellParams1.field === 'amount') {
      return v1 - v2;
    } else {
      return 1;
    }
  };

  const columns: GridColumns = arr.map((column, idx) => {
    if (idx === arr.length - 1) {
      return {
        ...column,
        headerAlign: 'right',
        align: 'right',
        valueFormatter,
        sortComparator,
      };
    }
    if (column.field === 'postedDate') {
      return {
        ...column,
        valueFormatter,
        type: 'date',
        sortComparator,
      };
    } else {
      return { ...column, valueFormatter, sortComparator };
    }
  });

  const exportTransactionsToCSV = async (data: ITransaction[]) => {
    const transactions = data.map((transaction: ITransaction) => {
      const date = transaction.postedDate
        ? getLocaleDate(transaction.postedDate)
        : 'Pending';
      return {
        postedDate: date,
        type: transaction.type.type,
        amount: transaction.amount,
      };
    });
    const headers = Object.keys(transactions[0]).map(
      (header) => header.charAt(0).toUpperCase() + header.slice(1)
    );
    const options = {
      fieldSeparator: ',',
      filename: 'Transaction Report',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Transaction Report',
      useBom: true,
      useTextFile: false,
      useKeysAsHeaders: true,
      headers: headers,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(transactions);
  };

  return (
    <>
      <TableHeader
        title="Transaction Report"
        setDateRange={setDateRange}
        buttonItems={[
          {
            icon: (
              <ExportIcon
                alt="button icon"
                maincolor={theme.palette.primary.main}
                size={Typography.BasicText}
              />
            ),
            label: 'Export',
            onClick: () => exportTransactionsToCSV(transactions),
            style: classes.button,
          },
        ]}
      />
      <div className={classes.container}>
        <DataGrid
          rowCount={count}
          columns={columns}
          rows={transactions}
          disableColumnMenu
          classes={{
            columnHeader: classes.header,
            cell: classes.cell,
          }}
          style={{ border: 'none' }}
          pagination
          paginationMode="server"
          pageSize={take}
          rowsPerPageOptions={[initialPageSize, 25, 50, 100]}
          page={page}
          onPageChange={(page) => handleOnPageChange(page)}
          onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
          hideFooterSelectedRowCount
        />
      </div>
    </>
  );
};

export default TransactionsTable;
