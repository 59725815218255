import { SvgIcon } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fillGray: {
      fill: Colors.TextGray,
    },
  })
);

const AccountUploadIcon = (props: { alt: string; className?: any }) => {
  const { fillGray } = useStyles();
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="41.292"
      viewBox="0 0 35 41.292"
      className={props.className}
    >
      <g id="account_circle_black_24dp" transform="translate(-1 -1)">
        <path
          id="Path_419"
          data-name="Path 419"
          d="M0 0h34v34H0z"
          transform="translate(2 1)"
          style={{ fill: 'none' }}
        />
        <g
          id="Group_777"
          data-name="Group 777"
          transform="translate(1796 3777)"
        >
          <g id="noun_account_4190813" transform="translate(-1795 -3775)">
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_131"
                data-name="Path 131"
                d="M23.755 19.2a7.6 7.6 0 1 0-7.6-7.6 7.607 7.607 0 0 0 7.6 7.6z"
                transform="translate(-10.655 -4)"
                className={fillGray}
              />
              <path
                id="Path_132"
                data-name="Path 132"
                d="M21.473 42.261a9.412 9.412 0 0 1 .084-1.339 11.382 11.382 0 0 1 4.3-7.728 11.519 11.519 0 0 1 2.16-1.346c.281-.133.568-.259.863-.372A13.1 13.1 0 0 0 8.31 42.254v.175a1.51 1.51 0 0 0 1.508 1.508H21.6a10.735 10.735 0 0 1-.119-1.431c-.008-.069-.008-.154-.008-.245z"
                transform="translate(-8.31 -11.509)"
                className={fillGray}
              />
            </g>
          </g>
          <path
            id="Exclusion_1"
            data-name="Exclusion 1"
            d="M9.209 18.419a9.209 9.209 0 1 1 9.21-9.21 9.22 9.22 0 0 1-9.21 9.21zm0-15.289a1 1 0 0 0-.768.36l-4 4.805a1 1 0 0 0 .759 1.64h1.283v3.532a2 2 0 0 0 2 2h1.455a2 2 0 0 0 2-2V9.938h1.277a1 1 0 0 0 .769-1.641l-4-4.805a1 1 0 0 0-.775-.362z"
            transform="translate(-1779.418 -3753.126)"
            className={fillGray}
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AccountUploadIcon;
