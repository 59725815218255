import { makeStyles } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles({
  container: {
    marginTop: 0,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    '& .apexcharts-legend-text': {
      marginLeft: 10,
    },
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: 50,
    },
  },
  chartTitle: {
    color: Colors.TextGray,
    fontFamily: Typography.FontFamily2,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: 0.83,
    transform: 'rotate(-90deg)',
    paddingTop: 40,
  },
  chartContainer: {
    border: '1px solid',
    borderColor: Colors.TextGray,
    borderRadius: '12px',
    marginBottom: '1rem',
    width: '100%',
    height: '100%',
    paddingRight: 20,
  },
});
