import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query CurrentUser($searchCriteria: String) {
    currentUser {
      email
      firstName
      lastName
      advisorFirmId
      role {
        name
        permissions
      }
    }
    advisorFirm {
      companyName
    }
    advisorFirmConfig {
      logo
      subDomainName
    }
    accountsByHildaStatus(searchCriteria: $searchCriteria) {
      description
      accounts {
        id
        name
        statusId
        submitted
      }
      count
    }
  }
`;
