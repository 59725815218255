import { gql } from '@apollo/client';
export const GET_TRANSACTIONS_BY_BANK_ALLOCATION_ID = gql`
  query TransactionsByBankAllocationId(
    $where: BankAllocationTableWhere!
    $searchCriteria: SearchCriteria
    $countWhere: BankAllocationTableWhere!
    $countSearchCriteria: SearchCriteria
  ) {
    transactionsCountByBankAllocationId(
      where: $countWhere
      searchCriteria: $countSearchCriteria
    )
    transactionsByBankAllocationId(
      where: $where
      searchCriteria: $searchCriteria
    ) {
      id
      postedDate
      type {
        type
      }
      amount
      status {
        status
      }
    }
  }
`;
