import { makeStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles({
  toastMsgContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.3rem',
  },
  title: {
    display: 'flex',
    fontSize: Typography.H5,
    fontFamily: Typography.FontFamily2,
    fontWeight: 'bold',
    gap: 5,
  },
  mainText: {
    fontSize: Typography.Footnotes,
    fontFamily: Typography.FontFamily2,
    paddingLeft: '1.75rem',
  },
  toastContainer: {
    '& .Toastify__progress-bar--success': {
      backgroundColor: Colors.Success,
    },
    '& .Toastify__progress-bar--warning': {
      backgroundColor: Colors.Warning,
    },
    '& .Toastify__progress-bar--error': {
      backgroundColor: Colors.Error,
    },
  },
});
