import { gql } from '@apollo/client';

export const GET_USERS_BY_HILDA = gql`
  query UsersByHildaId($hildaId: BigInt!, $whereHilda: WhereHilda!) {
    usersByHildaId(hildaId: $hildaId) {
      id
      firstName
      lastName
      email
      roleId
      advisorFirmId
      dateOfBirth
      phoneNumber
    }
    hilda(whereHilda: $whereHilda) {
      id
      name
      email
      createdAt
      hildaTypeId
      trustDocumentURL
      taxId
      secondaryTaxId
      accountTitle
      advisor {
        firstName
        lastName
        email
      }
      address {
        streetAddress1
      }
    }
  }
`;
