import { Typography } from "@material-ui/core";
import { useStyles } from "./uploadInstructions.styles";

interface IUploadInstructionsProps {
  tips: string[];
  ifFailureText?: string;
}

const UploadInstructions = (props: IUploadInstructionsProps) => {
  const { tips, ifFailureText } = props;
  const { container } = useStyles();

  return (
    <div className={container}>
      <Typography style={{ fontWeight: 600 }} variant="h6">
        Tips
      </Typography>
      {tips.map((tip, index) => (
        <Typography key={index} variant="body2">
          {tip}
        </Typography>
      ))}
      {ifFailureText && (
        <>
          <br />
          <Typography variant="body2">
            <p>
              If failure occurs,{' '}
              <b>
                please provide consent for our technical leads to work with you
                directly
              </b>{' '}
              on data issues.
            </p>
          </Typography>
        </>
      )}
    </div>
  );
};

export default UploadInstructions;
