import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, TextField, Typography, useTheme } from '@material-ui/core';
import { LoadingModalContext } from '../../../components/loadingModal/loadingModalContext';
import UploadFile from '../../../components/uploadFile/uploadFile';
import { Colors } from '../../../shared/theme/styles.enum';
import {
  COPIED_TO_CLIPBOARD,
  ERROR,
  ERROR_ADVISOR_CONFIG,
  GET_EMBED_CODE_BUTTON,
  IFI_NETWORK_URL,
  SUCCESS,
  SUCCESS_ADVISOR_CONFIG,
} from '../../../shared/utils/textConstants';
import {
  GET_ADVISOR_FIRM_CONFIG,
  UPSERT_ADVISOR_FIRM_CONFIG,
} from './clientConfigDetails.service';
import { useStyles } from './clientConfigDetails.styles';
import { triggerAlert } from '../../../components/toast/toast';
import { UserContext } from '../../../shared/context/userContext';
import { uploadLogoToBucket } from '../../../components/uploadFile/uploadFile.service';
import { IAdvisorFirmConfig } from '../../../shared/interfaces/iadvisorFirmConfig.interface';
import { GET_CURRENT_USER } from '../../../shared/context/userContext.service';
import { Errors, Types } from '../../../shared/interfaces/iform';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';
import {
  formatFormInput,
  isFormInputValid,
} from '../../../shared/utils/formUtils';
import * as Sentry from "@sentry/react";

const errorsDefault: Errors = {
  subDomainName: false,
  primaryColor: false,
  secondaryColor: false
};

const types: Types = {
  subDomainName: FormTypes.Alphanumeric,
  primaryColor: FormTypes.PrimaryColor,
  secondaryColor: FormTypes.SecondaryColor
};

interface IAdvisorFirmConfigInput {
  logo?: string;
  primaryColor: string;
  secondaryColor: string;
}

const defaultAdvisorFirmConfig: IAdvisorFirmConfig = {
  subDomainName: '',
  primaryColor: Colors.Teal,
  secondaryColor: Colors.Black,
  logo: '',
};

const ClientConfigDetails = () => {
  const [advisorFirmConfig, setAdvisorFirmConfig] =
    useState<IAdvisorFirmConfig>(defaultAdvisorFirmConfig);

  const [embedCode, setEmbedCode] = useState<string>('');
  const [errors, setErrors] = useState(errorsDefault);
  const { userData } = useContext(UserContext);
  const { setOpenModal } = useContext(LoadingModalContext);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [logoToUpload, setLogoToUpload] = useState<File | null>(null);
  const theme = useTheme();

  const { data, loading, error, refetch } = useQuery(GET_ADVISOR_FIRM_CONFIG);

  const [createOrUpdate] = useMutation(UPSERT_ADVISOR_FIRM_CONFIG, {
    refetchQueries: [GET_CURRENT_USER, 'CurrentUser'],
  });

  const classes = useStyles();

  const companyName: string = data?.advisorFirm?.companyName || '';

  useEffect(() => {
    if (!loading && !error && data && data.advisorFirmConfig) {
      updateAdvisorFirmConfigWithData();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    checkIfCanSave();
    // eslint-disable-next-line
  }, [advisorFirmConfig, logoToUpload, errors]);

  const handleSave = async () => {
    try {
      const advisorFirmConfigInput: IAdvisorFirmConfigInput = advisorFirmConfig;
      setOpenModal(true);
      const logoRes = logoToUpload && (await uploadLogoToBucket(logoToUpload));
      await createOrUpdate({
        variables: {
          whereAdvisorFirmConfig: {
            advisorFirmId: userData.user.advisorFirmId,
          },
          advisorFirmConfigInput: {
            ...advisorFirmConfigInput,
            logo: logoRes ? logoRes.uploadUrl : undefined,
          },
        },
      });
      refetch();
      setOpenModal(false);
      triggerAlert({
        type: 'success',
        title: SUCCESS,
        msg: SUCCESS_ADVISOR_CONFIG,
      });
    } catch (e) {
      Sentry.captureException(e)
      setOpenModal(false);
      console.error(e);
      triggerAlert({
        type: 'error',
        title: ERROR,
        msg: ERROR_ADVISOR_CONFIG,
      });
    }
  };

  const handleSetLogoFile = (file: File) => {
    setLogoToUpload(file);
  };

  const handleCancel = () => {
    updateAdvisorFirmConfigWithData();
    setLogoToUpload(null);
  };

  const updateAdvisorFirmConfigWithData = () => {
    if (!data.advisorFirmConfig) {
      setAdvisorFirmConfig(defaultAdvisorFirmConfig);
      setEmbedCode('');
      return;
    }
    const {
      subDomainName,
      primaryColor,
      secondaryColor,
      logo,
    }: IAdvisorFirmConfig = data.advisorFirmConfig;
    const url = 'https://' + subDomainName + IFI_NETWORK_URL;
    setEmbedCode(GET_EMBED_CODE_BUTTON(url, theme.palette.primary.main));
    setAdvisorFirmConfig({
      subDomainName,
      primaryColor,
      secondaryColor,
      logo,
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    setAdvisorFirmConfig((prevState) => {
      return { ...prevState, [label]: formatFormInput(types[label], value) };
    });
  };

  const copyCodeToClipboard = () => {
    clipboardCopy(embedCode || '');
    triggerAlert({
      title: SUCCESS,
      type: 'success',
      msg: COPIED_TO_CLIPBOARD,
    });
  };
  const clipboardCopy = async (input: string) => {
    await navigator.clipboard.writeText(input);
  };

  const checkIfCanSave = () => {
    if (!data || loading || error) {
      setCanSave(false);
      return;
    }
    if (!data) return;
    if (!data.advisorFirmConfig) {
      setCanSave(true);
      return;
    }
    const { subDomainName, primaryColor, secondaryColor } =
      data.advisorFirmConfig;
    if(errors.primaryColor || errors.secondaryColor){
      setCanSave(false);
      return 
    }
    if (
      subDomainName !== advisorFirmConfig.subDomainName ||
      primaryColor !== advisorFirmConfig.primaryColor ||
      secondaryColor !== advisorFirmConfig.secondaryColor
    ) {
      setCanSave(true);
      return;
    }
    if (!!logoToUpload) {
      setCanSave(true);
      return;
    }
    const errorList = Object.entries(errors).filter((key, value) => value);
    if (errorList.length > 0) {
      setCanSave(true);
      return;
    }
    setCanSave(false);
  };

  return (
    <div>
      <div className={classes.mainClientConfigContainer}>
        <div>
          <Typography className={classes.advisorName}>{companyName}</Typography>
        </div>
        <form>
          <div className={classes.subdomainContainer}>
            <Typography className={classes.subdomain}>
              Subdomain name:
            </Typography>
            <Input
              name="subDomainName"
              disabled
              style={{ width: '15rem' }}
              className={classes.urlInput}
              value={advisorFirmConfig.subDomainName}
            />
            <Typography className={classes.subdomainUrl}>
              {IFI_NETWORK_URL}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.formTypography}>Logo:</Typography>
            <div className={classes.logoContainer}>
              <div className={classes.buttonContainer}>
                <UploadFile
                  isLogo
                  defaultLogo={advisorFirmConfig.logo}
                  handleSetLogoFile={handleSetLogoFile}
                  logoSubtitle="size: 75 X 320 px"
                />
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <Typography className={classes.formTypography}>
              Main color:
            </Typography>
            <TextField
              name="primaryColor"
              className={`${classes.textField} ${classes.colorTextInput}`}
              variant="filled"
              size="small"
              value={advisorFirmConfig.primaryColor}
              onChange={handleChange}
              error={errors.primaryColor}
              helperText={errors.primaryColor?"Invalid Hex":" "}
            />
            <input
              name="primaryColor"
              className={classes.colorInput}
              type="color"
              value={advisorFirmConfig.primaryColor}
              onChange={handleChange}
            />
          </div>
          <div className={classes.container}>
            <Typography className={classes.formTypography}>
              Secondary color:
            </Typography>
            <TextField
              name="secondaryColor"
              size="small"
              className={`${classes.textField} ${classes.colorTextInput}`}
              variant="filled"
              value={advisorFirmConfig.secondaryColor}
              error={errors.secondaryColor}
              helperText={errors.secondaryColor?"Invalid Hex":" "}
              onChange={handleChange}
            />
            <input
              name="secondaryColor"
              className={classes.colorInput}
              type="color"
              value={advisorFirmConfig.secondaryColor}
              onChange={handleChange}
            />
          </div>
          <div className={classes.codeContainer}>
            <Typography className={classes.formTypography}>
              Code to embed:
            </Typography>
            <textarea
              name="embedCode"
              rows={5}
              cols={50}
              className={classes.codeInput}
              onClick={copyCodeToClipboard}
              value={embedCode}
              readOnly
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button className={classes.cancelButton} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              onClick={handleSave}
              style={canSave ? {} : { opacity: 0.5, color: 'white' }}
              disabled={!canSave}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClientConfigDetails;
