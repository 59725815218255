import { useTheme } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { IReturnAverage } from "../../../shared/interfaces/ireturnAverage.interface";
import { Colors, Typography } from "../../../shared/theme/styles.enum";
import { formatCurrency } from "../../../shared/utils/gridUtils";
import Loading from "../../loading/loading";
import { useStyles } from "./returnChart.styles";

interface IProps {
  bankReturnAverage: IReturnAverage[];
  productReturn: IReturnAverage[];
  shortName: string;
}

const ReturnChart = (props: IProps) => {
  const { bankReturnAverage, productReturn, shortName } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const barColors = [theme.palette.primary.dark, theme.palette.primary.main];
  const categories = productReturn.map((product) =>
    product.year === 1 ? `${product.year} Year` : `${product.year} Years`
  );
  const valueColor = categories.map(() => Colors.TextGray);

  useEffect(() => {
    setTimeout(() => setIsLoading(true));
    setTimeout(() => setIsLoading(false), 300);
  }, [props]);

  const series = useMemo(() => {
    return [
      {
        name: "Bank Avg",
        data: bankReturnAverage.map((bra) => parseFloat(bra.return)),
      },
      {
        name: shortName,
        data: productReturn.map((product) => parseFloat(product.return)),
      },
    ];
  }, [bankReturnAverage, shortName, productReturn]);

  const indicators = useMemo(() => {
    return [
      bankReturnAverage.map(({ indicator }) => indicator),
      productReturn.map(({ indicator }) => indicator),
    ];
  }, [bankReturnAverage, productReturn]);

  const options: any = {
    chart: {
      width: "100%",
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    grid: {
      show: true,
      strokeDashArray: 3,
    },

    legend: {
      position: "top",
      show: true,
      fontSize: "19px",
      fontFamily: Typography.FontFamily2,
      fontWeight: "normal",
      labels: {
        colors: Colors.TextGray,
      },
      itemMargin: {
        horizontal: 25,
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },

    colors: barColors,

    plotOptions: {
      bar: {
        columnWidth: 70,
        horizontal: false,
        borderRadius: 4,
        distributed: false,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabledOnSeries: [1],
      formatter: (val: number, opts: any) => {
        if (indicators[opts.seriesIndex][opts.dataPointIndex] === "currency") {
          return "$" + formatCurrency(val.toString());
        }
        return isNaN(Number(val)) ? "- %" : Number(val).toFixed(2) + "%";
      },
      enabled: true,
      offsetY: -10,
      style: {
        fontSize: "1rem",
        fontWeight: 600,
        fontFamily: "Poppins",
        colors: valueColor,
        lineHeight: 0.56,
      },
    },

    stroke: {
      show: true,
      width: 15,
      colors: ["transparent"],
    },

    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "1rem",
          fontWeight: 500,
          fontFamily: "Poppins",
          colors: valueColor,
        },
      },
    },

    yaxis: {
      decimalsInFloat: 0,
      forceNiceScale: true,
      tickAmount: 3,
      max: Math.max(...series[1].data) + Math.max(...series[1].data) / 5,
      min: 0,
      axisTicks: {
        tickAmount: 6,
      },
      labels: {
        show: false,
      },
    },

    tooltip: {
      enabled: false,
    },

    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  };

  return (
    <main className={classes.container}>
      <h4 className={classes.chartTitle}>Return</h4>
      <div className={classes.chartContainer}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "311px",
            }}
          >
            <Loading isLoading />
          </div>
        ) : (
          <Chart options={options} series={series} type="bar" height="311px" />
        )}
      </div>
    </main>
  );
};

export default ReturnChart;
