import PlaidLinkWrapper from '../components/plaidLinkComponent/plaidLinkWrapper';
import SideNav from '../components/sideNav/sideNav';
import { viewsUseStyles } from './views.styles';

const PlaidConnect = () => {
  const classes = viewsUseStyles();

  return (
    <>
      <SideNav />
      <div className={classes.globalViewContent}>
        <PlaidLinkWrapper />
      </div>
    </>
  );
};

export default PlaidConnect;
