import { SvgIcon } from '@material-ui/core';

const WireTransferIcon = (props: {
  alt: string;
  className?: any;
  size: string | number;
}) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="97"
      height="97"
      viewBox="0 0 97 97"
      className={props.className}
      style={{ fontSize: `${props.size}` }}
    >
      <g data-name="Grupo 2366">
        <path
          data-name="Rectángulo 6503"
          style={{ fill: 'none' }}
          d="M0 0h97v97H0z"
        />
        <g data-name="Grupo 2375-2">
          <path
            data-name="Trazado 10543"
            d="M-51.806 24.119a9.719 9.719 0 0 1-3.381 3.481 11.765 11.765 0 0 1-5.286 1.691v3.8h-3.054v-3.8a12.445 12.445 0 0 1-7.141-2.775 7.789 7.789 0 0 1-2.725-6.2h5.733a5.085 5.085 0 0 0 1.176 3.03 4.817 4.817 0 0 0 2.958 1.527V14.536a37.654 37.654 0 0 1-5.122-1.622A8.187 8.187 0 0 1-72 10.307a7.775 7.775 0 0 1-1.4-4.885 8.488 8.488 0 0 1 2.725-6.457 11.71 11.71 0 0 1 7.146-2.983v-3.808h3.053v3.808a11.263 11.263 0 0 1 6.649 2.6 8.855 8.855 0 0 1 2.844 6.129h-5.733A4.647 4.647 0 0 0-57.84 2.1 4.977 4.977 0 0 0-60.474.532v10.15a35.245 35.245 0 0 1 5.122 1.577 8.064 8.064 0 0 1 3.336 2.584 7.775 7.775 0 0 1 1.363 4.857 8.967 8.967 0 0 1-1.153 4.419zM-66.79 8.147a8.311 8.311 0 0 0 3.263 1.735V.4a5.178 5.178 0 0 0-3.217 1.454 4.506 4.506 0 0 0-1.2 3.29 3.917 3.917 0 0 0 1.154 3.003zm9.556 15.053a4.714 4.714 0 0 0 1.18-3.217 3.79 3.79 0 0 0-1.158-2.939 8.521 8.521 0 0 0-3.263-1.713v9.538a5.224 5.224 0 0 0 3.242-1.67z"
            style={{ fill: '#fff' }}
            transform="translate(112.393 35.826)"
          />
        </g>
        <path
          data-name="Trazado 10544"
          d="M-99.8-18.3h10.647l-1 .876a40.129 40.129 0 0 0-13.657 27.737 40.131 40.131 0 0 0 9.955 29.27 40.244 40.244 0 0 0 25.786 13.484v-7.276A33.33 33.33 0 0 1-96.71 12.9 32.874 32.874 0 0 1-84.247-13l.812-.646v11.719h7.183v-23.557H-99.8zm53.1 69.58h23.549v-7.186H-33.8l1-.876a40.264 40.264 0 0 0 13.538-26.333 40.126 40.126 0 0 0-8.93-29.6A40.116 40.116 0 0 0-54.886-27.27V-20A33 33 0 0 1-33.508-7.847a32.963 32.963 0 0 1 7.059 24.394 32.959 32.959 0 0 1-12.258 22.239l-.812.65V27.724H-46.7z"
          style={{ fill: '#fff' }}
          transform="translate(112.393 35.826)"
        />
      </g>
    </SvgIcon>
  );
};

export default WireTransferIcon;
