import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import Link from '../../link/link';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { AccordionOverrideCss, useStyles } from './accountsResult.styles';
import { getRoutes } from '../../../shared/routes/routeList';
import { MenuIndex } from '../../menu/menu.enum';
import { IHildaResult } from '../../../shared/interfaces/ihildaResult.interface';
import { IAccordionItem } from '../accountsSearch';

interface IAccordionItemsProps {
  data: IHildaResult[];
  accordionItems: IAccordionItem[];
  setAccordionItems: any;
}

const AccountsResult = (props: IAccordionItemsProps) => {
  const { data, accordionItems, setAccordionItems } = props;
  const classes = useStyles();

  const route = getRoutes(MenuIndex.Default).find(
    (routes) => routes.componentName === 'HildaDetails'
  );
  const routePath = route?.path || '';

  const handleExpand = (description: string) => {
    if (accordionItems.length > 0) {
      const index = accordionItems.findIndex(
        (item) => item.status === description
      );
      accordionItems[index].expanded
        ? (accordionItems[index].expanded = false)
        : (accordionItems[index].expanded = true);
      setAccordionItems(accordionItems);
    }
  };

  const isExpanded = (status: string) => {
    return !!accordionItems?.find((item) => item.status === status)?.expanded;
  };

  return (
    <React.Fragment>
      <AccordionOverrideCss />
      {data.map((status, index) => {
        return (
          <Accordion
            key={status.description}
            id={`accordion-${index}`}
            defaultExpanded
            expanded={isExpanded(status.description)!}
            onChange={() => handleExpand(status.description)}
          >
            <AccordionSummary
              aria-controls="accordion-content"
              id={`accordion-header${index}`}
              className={classes.accordionSummary}
              expandIcon={<ExpandLessIcon />}
            >
              <Typography
                className={classes.statusDescriptionItems}
              >{`${status.description} (${status.count})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.list}>
                {status.accounts.map((account, index) => (
                  <Link
                    key={index}
                    to={routePath}
                    id={account.id}
                    hildaAccountId={account.id}
                    hildaStatusId={account.statusId}
                    hildaSubmitted={account.submitted}
                    activeClassName={classes.navLinkActive}
                    className={classes.link}
                  >
                    <ListItem key={index} className={classes.listItem} divider>
                      <Typography className={classes.accountNamesItems}>
                        {account.name}
                      </Typography>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};

export default AccountsResult;
