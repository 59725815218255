import { gql } from '@apollo/client';
export const GET_POSITIONS_BY_BANK_ALLOCATION_ID = gql`
  query Positions($where: BankAllocationTableWhere!) {
    positions(where: $where) {
      bankName
      fedId
      apy
      amount
    }
  }
`;
