import Link from '../../../components/link/link';
import { useStyles } from './fundedAccountsHeaderGraph.styles';
import { useContext } from 'react';
import { UserContext } from '../../../shared/context/userContext';
import { getAllRoutes } from '../../../shared/routes/routeList';
import { getIsRouteAuthenticated } from '../../../shared/utils/auth';
import { IHildaResult } from '../../../shared/interfaces/ihildaResult.interface';

const FundedAccountsHeaderGraph = (props: {
  componentName: string;
  displayName: string;
  hildaTypesData: IHildaResult[];
  hildaStatusData: IHildaResult[];
}) => {
  
  const { componentName, displayName, hildaTypesData, hildaStatusData } = props;
  const { userData } = useContext(UserContext);

  const classes = useStyles();
  const routes = getAllRoutes();
  const routeLink = routes.find(
    (route) => route.componentName === componentName
  );
  const hasAccessToLink = getIsRouteAuthenticated(
    userData.user.role.permissions,
    routeLink?.permission
  );
  const notFunded = hildaStatusData.find(
    (status) => status.description === 'Not Funded'
  )?.count;
  const funded =
    hildaTypesData
      .map((hildaType) => hildaType.count)
      .reduce((prev, curr) => prev + curr, 0);

  const allFunds = funded + (notFunded? notFunded: 0);

  return (
    <div className={classes.container}>
      <div className={classes.counts}>
        <div className={classes.funded}>
          <p>{funded}</p>
        </div>
        <div className={classes.allFunded}>
          <p>{`out of ${allFunds }`}</p>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.title}>
          <p>Funded Accounts</p>
        </div>
        {hasAccessToLink && (
          <div className={classes.text}>
            <p>
              To load more accounts, go to{' '}
              <Link to={routeLink?.path || ''} className={classes.link}>
                {displayName}
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FundedAccountsHeaderGraph;
