import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography, Colors } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      padding: '20px 50px',
      flexGrow: 1,
    },
    grid: {
      flexGrow: 1,
      padding: '50px 0px',
    },
    innerGrid: {
      overflowX: 'auto',
    },
    mainAssetsGraphContainer: {
      width: '95%',
      margin: '50px auto 40px auto',
    },
    assetSummary: {
      borderLeft: '6px solid',
      borderLeftColor: theme.palette.primary.main,
      height: '90px',
    },
    assetType: {
      width: '242px',
      height: '67px',
      margin: '0px 0px 0px 10px',
      paddingBottom: 50,
      fontFamily: Typography.FontFamily2,
      fontSize: '3rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.73,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.Black,
      textTransform: 'capitalize',
    },
    assetAmount: {
      width: '362px',
      height: '33px',
      margin: '3px 0 0 10px',
      fontFamily: Typography.FontFamily2,
      fontSize: '1.5rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.73,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.Black,
    },
    graphDetails: {
      width: '517.3px',
      height: '499px',
      margin: '107.2px -10px 0 0px',
    },
    chartImg: {
      width: '480px',
      height: '706.3px',
      maxWidth: 'none',
    },
  })
);
