import { SvgIcon } from '@material-ui/core';

const UrlCopyButtonIcon = (props: {
  alt: string;
  size: number | string;
  maincolor: string;
}) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      style={{ fontSize: `${props.size}` }}
    >
      <path data-name="Path 180" d="M0 0h27v27H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 181"
        d="M18.211 1H4.316A2.3 2.3 0 0 0 2 3.273v15.909h2.316V3.273h13.895zm-1.158 4.545L24 12.364v11.363A2.3 2.3 0 0 1 21.684 26H8.936a2.291 2.291 0 0 1-2.3-2.273l.007-15.909a2.291 2.291 0 0 1 2.3-2.273zM15.895 13.5h6.368l-6.368-6.25z"
        style={{ fill: `${props.maincolor}` }}
      />
    </SvgIcon>
  );
};

export default UrlCopyButtonIcon;
