interface IRenderMessageProps {
  message: string;
}

const RenderMessage = (props: IRenderMessageProps) => (
  <p
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1rem',
    }}
  >
    {props.message}
  </p>
);

export default RenderMessage;
