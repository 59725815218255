import { SvgIcon } from '@material-ui/core';
import { Colors } from '../theme/styles.enum';

const LinkIcon = (props: { alt: string; className?: any }) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={props.className}
    >
      <path d="M0 0h34v34H0z" style={{ fill: 'none' }} />
      <path
        d="M4.692 14.083a4.4 4.4 0 0 1 4.391-4.391h5.667V7H9.083a7.083 7.083 0 1 0 0 14.167h5.667v-2.692H9.083a4.4 4.4 0 0 1-4.391-4.392zM10.5 15.5h11.333v-2.833H10.5zM23.25 7h-5.667v2.692h5.667a4.392 4.392 0 0 1 0 8.783h-5.667v2.692h5.667A7.083 7.083 0 0 0 23.25 7z"
        transform="rotate(45 15 15)"
        style={{ fill: Colors.TextGray }}
      />
    </SvgIcon>
  );
};

export default LinkIcon;
