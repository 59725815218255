import { Grid } from '@material-ui/core';
import { viewsUseStyles } from '../views.styles';
import { useStyles } from './loans.styles';

const Loans = () => {
  const globalclasses = viewsUseStyles();
  const classes = useStyles();
  return (
    <div className={globalclasses.globalViewContent}>
      <Grid container className={classes.gridContainer} spacing={4}>
        <Grid
          container
          className={classes.gridColumn}
          item
          sm={12}
          md={12}
          lg={12}
          direction="column"
        >
          <span className={classes.mainTitle}>
            Interested in getting a loan?
          </span>
          <span className={classes.mainSubtitle}>
            Connect with IFI for more information on receiving a competitive
            loan.
          </span>
        </Grid>
        <Grid
          container
          className={classes.gridColumn}
          item
          sm={12}
          md={12}
          lg={12}
          direction="column"
        >
          <span className={classes.titleText}>CALL US</span>
          <span className={classes.subtitleText}>(801)656-9652</span>
        </Grid>
        <Grid
          container
          className={classes.gridColumn}
          item
          sm={12}
          md={12}
          lg={12}
          direction="column"
        >
          <span className={classes.titleText}>EMAIL US</span>
          <span className={classes.subtitleText}>sales@ifinetwork.com</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default Loans;
