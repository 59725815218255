import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: Colors.TableHover,
    },
    titleHeader: {
      display: 'flex',
      width: 'max-content',
      padding: '0.75rem 0 0.5rem 1.5rem',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    title: {
      fontSize: 24,
      '@media (max-width: 950px)': {
        // eslint-disable-line no-useless-computed-key
        fontSize: '1.5rem',
        fontWeight: 600,
      },
    },
    rowItems: {
      display: 'flex',
      gap: 15,
      margin: '1rem',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: Colors.White, //alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    tabsRow: {
      backgroundColor: Colors.TableHover,
    },
    tab: {
      fontWeight: 600,
      color: Colors.TextGray,
    },
    button: {
      width: 'max-content',
    },
  })
);
