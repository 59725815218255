import { SvgIcon } from '@material-ui/core';

interface IProps {
  maincolor?: string;
  className?: any;
  alt?: string;
  size: string | number;
}

const PersonIcon = (props: IProps) => {
  return (
    <SvgIcon
      id="person"
      xmlns="http://www.w3.org/2000/svg"
      width="27.1"
      height="32.4"
      viewBox="0 0 27.08 32.43"
      className={props.className}
    >
      <g id="Group_14" data-name="Group 14" transform="translate(0 -1)">
        <g id="Group_2094" data-name="Group 2094" transform="translate(0 1)">
          <g id="Group_2093" data-name="Group 2093">
            <path
              id="Path_10469"
              data-name="Path 10469"
              d="M13.1 15.2a7.6 7.6 0 1 0-7.6-7.6 7.6 7.6 0 0 0 7.6 7.6"
              style={{ fill: `${props.maincolor}` }}
            />
            <path
              id="Path_10470"
              data-name="Path 10470"
              d="M24.74 23.26a13.042 13.042 0 0 0-11.2-5.62A13.1 13.1 0 0 0 0 30.74v.18a1.522 1.522 0 0 0 1.51 1.51h24.06a1.528 1.528 0 0 0 1.51-1.51v-.18a13.144 13.144 0 0 0-2.34-7.48"
              style={{ fill: `${props.maincolor}` }}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default PersonIcon;
