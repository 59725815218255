import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '95%',
      margin: '50px auto 40px auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    amount: {
      fontFamily: Typography.FontFamily2,
      fontSize: '2.125rem',
      lineHeight: '1.5rem',
      color: Colors.Black,
      '& span': {
        fontSize: '1.375rem',
        fontFamily: Typography.FontFamily2,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: Colors.Black,
        justifySelf: 'end',
        alignSelf: 'center',
        '& sup': {
          fontSize: '1.3rem',
        },
      },
    },
    dash: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      letterSpacing: 0,
      textAlign: 'right',
    },
    transferBtn: {
      marginRight: 5,
    },
    transferButton: {
      padding: '3px 13px 1px 13px',
      width: 'max-content',
      height: 42,
      backgroundColor: theme.palette.primary.main,
      color: Colors.White,
      borderRadius: 21,
      fontSize: '1.25rem',
      fontFamily: Typography.FontFamily2,
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);
