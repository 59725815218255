import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    padding: '2.5rem 0 0.5rem 1rem',
    fontFamily: 'Poppins',
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  counts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '15%',
    paddingTop: 10,
  },
  funded: {
    fontSize: '5rem',
    fontWeight: 600,
    letterSpacing: 3,
    padding: 0,
    lineHeight: 1,
    color: palette.primary.main,
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '2.5rem',
    },
  },
  allFunded: {
    lineHeight: 1,
    color: Colors.TextGray,
    fontSize: '1.3rem',
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '0.75rem',
      letterSpacing: 0,
    },
  },
  rightSide: {
    width: '80%',
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      width: '80%',
    },
  },
  title: {
    fontSize: '2rem',
    letterSpacing: 1,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    borderBottom: '2px solid',
    borderBottomColor: palette.primary.main,
    flex: 1,
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  text: {
    fontSize: '0.95rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 5,
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '0.8rem',
    },
  },
  link: {
    fontSize: '1rem',
    fontWeight: 500,
    color: palette.primary.main,
    textDecoration: 'none',
    '@media (max-width:950px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '0.8rem',
    },
    
  },
}));
