import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, Typography } from "../../shared/theme/styles.enum";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        maxWidth: "600px",
        margin: "auto"
    },
    center: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "2.1rem",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      textTransform: "capitalize",
      textAlign: "center",
      width: "100%",
      marginTop: theme.spacing(2)
    },
    subtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: Colors.Black,
      textAlign: "center",
      width: "100%",
    },
    textField: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0,
      textAlign: "left",
      border: "none !important",
      color: Colors.BorderAndBG,
      width: "100%",
      "& .MuiFilledInput-input": {
        padding: "15px 5px",
        fontFamily: Typography.FontFamily2,
      },
      "& .MuiInputBase-formControl": {
        width: "100%",
      },
      "& .MuiTypography-colorTextSecondary": {
        paddingTop: 3.5,
      },
    },
    submit: {
      padding: "8px 34px 7px",
      borderRadius: 3,
      border: `solid 1px ${theme.palette.primary.main}`,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: Colors.Black,
      textTransform: "capitalize",
      cursor: "pointer",
      width: "100%"
    },
    spinner: {
        marginLeft: "1em",
        color: Colors.TextGray
    }
  })
);
