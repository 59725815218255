import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  Observable,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Auth from "@aws-amplify/auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { config } from "./aws-custom-exports";
import Amplify from "aws-amplify";
import sharedStorage from "./shared/utils/sharedStorage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Amplify.configure(config);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

let inMemoryToken: string;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  if (inMemoryToken) return buildAuthToken(inMemoryToken, headers);
  try {
    const { signInUserSession } = await Auth.currentAuthenticatedUser();
    inMemoryToken = signInUserSession.idToken.jwtToken;
    return buildAuthToken(inMemoryToken, headers);
  } catch (error) {
    console.warn(`Couldn't get token:`, error);
  }
});

const unauthenticatedErrorLink = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors && graphQLErrors[0].message === "TokenExpired") {
      // unauthenticated. token expired. Refetch token using refresh token
      console.warn("Refreshing token and trying again");
      return promiseToObservable(refreshSession()).flatMap(() =>
        forward(operation)
      );
    }
  }
);

const client = new ApolloClient({
  link: from([unauthenticatedErrorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

// helper functions
const buildAuthToken = (token: string, headers: any) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
};

const promiseToObservable = (promise: Promise<any>) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const refreshSession = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: any, session: any) => {
          if (err) {
            console.error("Failed refreshing session");
            reject(err);
          }

          const { idToken } = session;
          inMemoryToken = idToken.jwtToken;
          resolve("");
        }
      );
    });
  } catch (e) {
    Sentry.captureException(e);
    console.log("Unable to refresh Token", e);
  }
};
const console = (function (oldCons) {
  return {
    log: function (...text: any) {
      oldCons.log(window.origin, ...text);
      // Your code
    },
    info: function (...text: any) {
      oldCons.info(...text);
      // Your code
    },
    warn: function (...text: any) {
      oldCons.warn(...text);
      // Your code
    },
    error: function (...text: any) {
      oldCons.error(...text);
      // Your code
    },
  };
})(window.console);
//Then redefine the old console
Object.defineProperty(window, "console", {
  value: console,
});
Object.defineProperty(window, "sharedStorage", {
  value: sharedStorage,
});
const isListening = sharedStorage.setUpListener();
if (!isListening) {
  sharedStorage.setDomainsVisited();
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
