import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperText: {
      alignItems: 'center',
    },
    text: {
      margin: '0px 35px 40px 0px',
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.45,
      letterSpacing: 'normal',
      textAlign: 'left',
      paddingLeft: '0.5rem',
      paddingBottom: '0.4rem',
      color: Colors.TextGray,
    },
    grid: { display: 'flex', margin: '30px 10px' },
    title: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.7,
      letterSpacing: 'normal',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
    line: {
      width: '100%',
      margin: 18,
      border: 'none',
      alignItems: 'center',
      borderTop: `1px dotted ${Colors.TextGray}`,
    },
    amount: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.7,
      letterSpacing: 'normal',
      textAlign: 'right',
      marginRight: 15,
      alignItems: 'center',
    },
  })
);
