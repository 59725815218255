export enum AccountAccess {
  ACH = '1',
  Wire = '2',
  Internal = '3',
}

export enum AccountAccessVal {
  ACH = 'ACH',
  Wire = 'Wire',
  Internal = 'Internal',
}

export const accountAccessDic = {
  '1': 'ACH',
  '2': 'Wire',
  '3': 'Internal',
};
