import { gql } from '@apollo/client';

export const GET_ACCOUNTS_BY_TYPES_STATUS_FOR_ADVISOR = gql`
query AccountsByHildaType {
  accountsByHildaType (ignorePending: true) {
    description
    count
  }
  accountsByHildaStatus (ignorePending: true) {
    description
    count
  }
}
`;