import { Theme } from "@material-ui/core";
import * as React from "react";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core";
import { defaultTheme } from "../theme/useDynamicTheme";
type ThemeContextData = {
  setTheme: (theme: Theme) => void;
};

export const ThemeContext = React.createContext<ThemeContextData>({
  setTheme: (theme: Theme) => undefined,
});

const ThemeProvider: React.FC<{ children: React.ReactElement | null }> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<Theme>(defaultTheme);
  return (
    <ThemeContext.Provider value={{ setTheme }}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
