import { gql } from "@apollo/client";

export const GET_HILDA_BY_ID = gql`
  query HildaById(
    $whereHilda: WhereHilda!
    $productSummariesWhereHilda: WhereHilda!
  ) {
    hilda(whereHilda: $whereHilda) {
      id
      name
      createdAt
      submitted
      advisorCanTransact
      advisor {
        firstName
        lastName
      }
    }
    productSummaries(whereHilda: $productSummariesWhereHilda) {
      amount
      availableNowAmount
      productReturnAverage {
        indicator
        year
        return
      }
      bankReturnAverage {
        indicator
        year
        return
      }
      complexProductData {
        positions {
          bankName
          apy
          fedId
          rate
          amount
        }
      }
      bankAllocations {
        id
        amount
        availableNowAmount
        product {
          id
          name
          description
          shortName
          return {
            indicator
            year
            return
          }
        }
      }
    }
  }
`;
