import { makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../shared/theme/styles.enum';

interface StyleProps {
  iconRotation: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dropdownItem: {
    float: 'right',
    color: theme.palette.secondary.contrastText,
    textTransform: 'capitalize',
    fontSize: Typography.H4,
    fontFamily: Typography.FontFamily2,
    fontWeight: 'bold',
  },
  expandMoreIcon: {
    transform: (props) => props.iconRotation,
    color: theme.palette.primary.main,
    transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));
