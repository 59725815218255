import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR } from './accountsSearch.service';
import Loading from '../loading/loading';
import InputFilter from './inputFilter/inputFilter';
import CheckboxFilter from './checkboxFilter/checkboxFilter';
import AccountsResult from './accountsResult/accountsResult';
import { IHildaResult } from '../../shared/interfaces/ihildaResult.interface';
import { useStyles } from './accountsSearch.styles';
import { UserContext } from '../../shared/context/userContext';
import { Typography } from '@material-ui/core';
export interface ICheckboxItem {
  status: string;
  checked: boolean;
}

export interface IAccordionItem {
  status: string;
  expanded: boolean;
}

const AccountsSearch = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState<string>(
    userData.accountsSearch.searchValue
  );
  const [checkboxItems, setCheckboxItems] = useState<ICheckboxItem[]>(
    userData.accountsSearch.checkboxItems
  );
  const [accordionItems, setAccordionItems] = useState<IAccordionItem[]>(
    userData.accountsSearch.accordionItems
  );
  const classes = useStyles();

  const { data, loading, error, refetch } = useQuery(
    GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR,
    {
      variables: { searchCriteria: searchValue },
    }
  );

  useEffect(() => {
    refetch();
    setUserData((prevState) => {
      return {
        ...prevState,
        accountsSearch: { ...prevState.accountsSearch, searchValue },
      };
    });
    // eslint-disable-next-line
  }, [searchValue, refetch]);

  useEffect(() => {
    if (!loading && !error) {
      setDataCheckboxItems(data.accountsByHildaStatus);
      setAccordionExpandedItems(data.accountsByHildaStatus);
      setUserData((prevState) => {
        return {
          ...prevState,
          accountsSearch: {
            ...prevState.accountsSearch,
            checkboxItems,
            accordionItems,
          },
        };
      });
    }
    // eslint-disable-next-line
  }, [loading, data]);

  if (loading)
    return (
      <main>
        <InputFilter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <CheckboxFilter
          checkboxItems={checkboxItems}
          setCheckboxItems={setCheckboxItems}
        />
        <div className={classes.loading}>
          <Loading isLoading />
        </div>
      </main>
    );
  if (error) return null;

  const setDataCheckboxItems = (accountsByHildaStatus: IHildaResult[]) => {
    setCheckboxItems(
      accountsByHildaStatus.map((item) => {
        const prevCheck = checkboxItems.find(
          (cbItem) => cbItem.status === item.description
        );
        return {
          status: item.description,
          checked: prevCheck ? prevCheck.checked : false,
        };
      })
    );
  };

  const setAccordionExpandedItems = (accountsByHildaStatus: IHildaResult[]) => {
    setAccordionItems(
      accountsByHildaStatus.map((item) => {
        const prevExpand = accordionItems.find(
          (cbItem) => cbItem.status === item.description
        );
        return {
          status: item.description,
          expanded: prevExpand ? prevExpand.expanded : true,
        };
      })
    );
  };

  const getFilteredData = (
    accountsByHildaStatus: IHildaResult[],
    checkboxItems: ICheckboxItem[]
  ) => {
    const allFiltersOff =
      checkboxItems.filter((chk) => !chk.checked).length ===
      checkboxItems.length;
    if (allFiltersOff) {
      return accountsByHildaStatus;
    } else {
      return accountsByHildaStatus.filter((account: { description: string }) =>
        checkboxItems.find(
          (checkbox) =>
            account.description === checkbox.status && checkbox.checked === true
        )
      );
    }
  };

  const hildaStatusData: IHildaResult[] = getFilteredData(
    data.accountsByHildaStatus,
    checkboxItems
  );

  const noResults = hildaStatusData.every((r) => r.count <= 0);

  return (
    <main>
      <InputFilter
        searchValue={searchValue}
        setSearchValue={(searchValue: string) => {
          setSearchValue(searchValue);
        }}
      />
      <CheckboxFilter
        checkboxItems={checkboxItems}
        setCheckboxItems={(checkboxItems: ICheckboxItem[]) => {
          setCheckboxItems([...checkboxItems]);
        }}
      />
      <AccountsResult
        accordionItems={accordionItems}
        setAccordionItems={(accordionItems: IAccordionItem[]) => {
          setAccordionItems([...accordionItems]);
        }}
        data={hildaStatusData}
      />
      {noResults && searchValue !== '' && (
        <div className={classes.noResultsContainer}>
          <Typography className={classes.noResults}>
            No results found
          </Typography>
          <Typography className={classes.noResults}>
            Please try another search
          </Typography>
        </div>
      )}
    </main>
  );
};

export default AccountsSearch;
