import { IRoute } from '../interfaces/iroute.interface';

export const getIsRouteAuthenticated = (
  permissions: string[],
  routePermission: string | null = null
) => {
  const result = permissions.find((permission) =>
    findPermission(permission, routePermission)
  );
  return result === '' ? true : !!result;
};

export const getFilteredRoutes = (routes: IRoute[], permissions: string[]) =>
  routes.filter((route) =>
    permissions.find((permission) =>
      findPermission(permission, route.permission)
    )
  );

export const findPermission = (
  permission: string,
  routePermission: string | null
) => permission.split('::')[0] === routePermission || routePermission === '';
