import { viewsUseStyles } from '../views.styles';
import SelfApplyInfo from './selfApplyInfo/selfApplyWorkflow/selfApplyWorkflow';

export default function SelfApplyPage() {
  const classes = viewsUseStyles();

  return (
    <div className={classes.globalViewContent}>
      <SelfApplyInfo />
    </div>
  );
};
