import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_STATEMENTS_PDF_BY_HILDA_ID } from "./statementPDF.service";
import { IStatementPDF } from "../../shared/interfaces/istatementPDF.interface";
import moment from "moment";
import PDFInstance from "./pdfInstance";
import { useStyles } from "./pdfInstance.styles";

interface IProps {
  date: string;
}

const PDFActionButtons = (props: IProps) => {
  const { date } = props;
  const { hildaAccountId }: any = useParams();
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_STATEMENTS_PDF_BY_HILDA_ID, {
    variables: {
      searchCriteria: {
        withinDates: {
          dateParam: "postedDate",
          range: [moment(date).startOf("month"), moment(date).endOf("month")],
        },
      },
      where: { id: hildaAccountId },
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <span className={classes.loading}>View | Download</span>;

  if (error) return null;

  const statements: IStatementPDF[] = data.statementsPDFByHildaId;

  return <PDFInstance statements={statements} />;
};

export default PDFActionButtons;
