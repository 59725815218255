import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { LoadingModalContext } from './loadingModalContext';
import { useContext } from 'react';
import { useStyles } from './loadingModal.styles';
import Loading from '../loading/loading';

const LoadingModal = () => {
  const classes = useStyles();
  const { openModal } = useContext(LoadingModalContext);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <p className={classes.text}>Processing</p>
            <Loading isLoading={true} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default LoadingModal;
