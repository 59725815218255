import { makeStyles } from '@material-ui/core';
import { Colors } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
  },

  snippet: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.White,
    width: '100%',
  },

  stage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dotFlashing: {
    position: 'relative',
    width: 14,
    height: 14,
    borderRadius: 15,
    display: 'flex',
    backgroundColor: palette.primary.main,
    color: palette.primary.main,
    animation: '$dotFlashing 1s infinite linear alternate',
    animationDelay: '.5s',
    '&::before': {
      content: '""',
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: -15,
      width: 14,
      height: 14,
      borderRadius: 15,
      backgroundColor: palette.primary.main,
      color: palette.primary.main,
      animation: `$dotFlashing 1s infinite alternate`,
      animationDelay: '0s',
      marginLeft: -10,
    },
    '&::after': {
      content: '""',
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 15,
      width: 14,
      height: 14,
      borderRadius: 15,
      backgroundColor: palette.primary.main,
      color: palette.primary.main,
      animation: `$dotFlashing 1s infinite alternate`,
      animationDelay: '1s',
      marginLeft: 10,
    },
  },

  '@keyframes dotFlashing': {
    '0%': {
      backgroundColor: palette.primary.main,
    },
    '50%': {},
    '100%': {
      backgroundColor: palette.primary.light,
    },
  },
}));
