import Auth from '@aws-amplify/auth';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../shared/context/userContext';
import { getAllRoutes } from '../shared/routes/routeList';
import { viewsUseStyles } from './views.styles';

const NotFound = () => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const allRoutes = getAllRoutes();
  const classes = viewsUseStyles();

  const routeExist = !!allRoutes.filter(({ path }) => {
    return path === userData.reRoute;
  })[0];

  return (
    <div className={classes.globalViewContent}>
      <div></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {routeExist && userData.authState === 'signedOut' ? (
          <>
            <h2>You are not signed in</h2>
            <button
              onClick={() =>
                Auth.federatedSignIn({
                  customState: userData.reRoute,
                  customProvider: 'COGNITO',
                })
              }
            >
              Sign In
            </button>
          </>
        ) : !routeExist && userData.authState === 'signedOut' ? (
          <>
            <h2>Page Not Found</h2>
            <form action={window.origin}>
              <input type="submit" value="Go Home" />
            </form>
          </>
        ) : !routeExist && userData.authState === 'signedIn' ? (
          <>
            <h2>Page Not Found</h2>
            <button onClick={() => history.push('/')}>Go Back</button>
          </>
        ) : (
          <>
            <h2>Page Not Found</h2>
            <button onClick={() => history.push('/')}>Go Home</button>
          </>
        )}
      </div>
    </div>
  );
};

export default NotFound;
