import { makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: 55,
  },
  greenButton: {
    display: 'flex',
    color: theme.palette.primary.main,
    width: '12.125rem',
    height: '2.688rem',
    backgroundColor: Colors.White,
    textTransform: 'capitalize',
    fontFamily: Typography.FontFamily2,
    fontSize: '1.25rem',
    border: '2px solid',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: Colors.White,
    },
  },
  redButton: {
    color: Colors.Red,
    width: '12.125rem',
    height: '2.688rem',
    backgroundColor: Colors.White,
    textTransform: 'capitalize',
    fontFamily: Typography.FontFamily2,
    fontSize: '1.25rem',
    border: '2px solid',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: Colors.White,
    },
  },
}));
