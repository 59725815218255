import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import Loading from '../../../components/loading/loading';
import { formatCurrency } from '../../../shared/utils/gridUtils';
import { graphNeutral } from '../../../shared/utils/pathConstants';
import { GET_ASSETS } from './assets.service';
import AssetsChart from './assetsChart';

import { useStyles } from './assetsGraph.styles';

interface IAssetsGraphData {
  assetsType?: string;
  assetsTotalAmount?: string;
}

const AssetsGraph = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_ASSETS, {fetchPolicy: 'network-only'});

  if (loading)
    return (
      <>
        <div className={classes.container}>
          <Loading isLoading />
        </div>
      </>
    );

  if (error) return null;

  const assetType = data?.deposits.type;
  const assetTotalAmount = formatCurrency(data?.deposits.totalAmount);
  const assets: IAssetsGraphData = data?.deposits.assets;

  return (
    <>
      <div className={classes.container}>
        <Grid container className={classes.grid} spacing={10}>
          <Grid container item sm={12} md={12} lg={6} direction="column" className={classes.innerGrid}>
            <div className={classes.assetSummary}>
              <Typography className={classes.assetType}>{assetType}</Typography>
              <Typography className={classes.assetAmount}>
                Total: ${assetTotalAmount}
              </Typography>
            </div>
            <div className={classes.graphDetails}>
              <AssetsChart assets={assets} />
            </div>
          </Grid>
          <Grid container item sm={12} md={12} lg={6} direction="column" className={classes.innerGrid}>
            <img
              src={graphNeutral}
              alt="default chart img "
              className={classes.chartImg}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AssetsGraph;
