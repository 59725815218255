import React, { ChangeEvent, useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { useStyles } from '../personalInformation.styles';
import {
  Errors,
  IPersonalInformationIndividualFields,
  Types,
} from '../../../../shared/interfaces/iform';
import { isFormInputValid } from '../../../../shared/utils/formUtils';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';
import { REQUIRED_FIELD_MISSING } from '../../../../shared/utils/textConstants';

const errorsDefault: Errors = {
  firstName: false,
  lastName: false,
  ssn: false,
  dateOfBirth: false,
  phoneNumber: false,
  email: false,
  address: false,
  zipCode: false,
  city: false,
  state: false
};

const types: Types = {
  firstName: FormTypes.Text,
  lastName: FormTypes.Text,
  ssn: FormTypes.SSN,
  dateOfBirth: FormTypes.Date,
  phoneNumber: FormTypes.Phone,
  email: FormTypes.Email,
  address: FormTypes.Address,
  zipCode: FormTypes.ZipCode,
  city: FormTypes.Text,
  state: FormTypes.Text,
};

interface IProps {
  id: string;
  formFields: IPersonalInformationIndividualFields;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
}

const IndividualAccount = (props: IProps) => {
  const { id, formFields, handleSetStepCanAdvance, handleValueChange } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const classes = useStyles();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;
    if (isFormValid) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  return (
    <FormControl className={classes.root} component="fieldset">
      <TextField
        id="firstName"
        name="firstName"
        label="First Name"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.firstName}
        value={formFields.firstName}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.firstName && REQUIRED_FIELD_MISSING}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Last Name"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.lastName}
        value={formFields.lastName}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.lastName && REQUIRED_FIELD_MISSING}
      />
      <TextField
        id="ssn"
        name="ssn"
        label="Social Security Number"
        variant="outlined"
        required
        style={{ width: "15rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        inputProps={{ maxLength: 12 }}
        error={errors.ssn}
        value={formFields.ssn}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
      <TextField
        id="dateOfBirth"
        name="dateOfBirth"
        label="Date of Birth"
        placeholder="MM/DD/YYYY"
        variant="outlined"
        required
        style={{ width: "8.688rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.dateOfBirth}
        value={formFields.dateOfBirth}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
      <TextField
        id="phoneNumber"
        name="phoneNumber"
        label="Phone Number"
        variant="outlined"
        style={{ width: "11.75rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        inputProps={{ maxLength: 16 }}
        error={errors.phoneNumber}
        value={formFields.phoneNumber}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
      <TextField
        id="email"
        name="email"
        label="Email"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.email}
        value={formFields.email}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />

      <TextField
        id="address"
        name="address"
        label="Address"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.address}
        value={formFields.address}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />

      <TextField
        id="city"
        name="city"
        label="City"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.city}
        value={formFields.city}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.city && REQUIRED_FIELD_MISSING}
      />

      <TextField
        id="state"
        name="state"
        label="State"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.state}
        value={formFields.state}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.state && REQUIRED_FIELD_MISSING}
      />

      <TextField
        id="zipCode"
        name="zipCode"
        label="Zip Code"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.zipCode}
        value={formFields.zipCode}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
    </FormControl>
  );
};

export default IndividualAccount;
