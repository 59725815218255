import { useContext } from 'react';
import { useSnapshot } from 'valtio';
import { UserContext } from '../../shared/context/userContext';
import UserOptions from './userOptions/userOptions';
import { AppBar, CssBaseline, useTheme } from '@material-ui/core';
import { useStyles } from './header.styles';
import MenuOptions from '../menu/menu';
import { MenuIndex } from '../menu/menu.enum';
import IFILogo from '../../shared/icons/ifiLogo';
import { state } from '../../shared/store';
import { useHistory } from 'react-router-dom';

interface IHeaderProps {
  children: any;
}

const Header = (props: IHeaderProps) => {
  const { userData } = useContext(UserContext);
  const { children } = props;
  const router = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const snap = useSnapshot(state);

  const navigateHome = () => {
    router.push("/");
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        className={classes.appBar}
        position="fixed"
        style={{ backgroundColor: snap.secondaryColor }}>
        <div className={classes.box}>
          <div className={`${classes.logoContainer} ${classes.clickable}`} onClick={navigateHome}>
            <IFILogo
              className={classes.ifiLogo}
              alt="IFI Logo"
              maincolor={theme.palette.secondary.contrastText}
            />
          </div>
          <div className={classes.optionsContainer}>
            <div className={classes.menuOptionsContainer}>
              <MenuOptions
                type={MenuIndex.TopNav}
                orientation="horizontal"
                typography="inherit"
                navLinkStyle={classes.menu}
                navLinkActiveStyle={classes.menuActive}
                itemStyle={classes.menuItem}
                permissions={userData.user.role.permissions}
              />
            </div>
            <div className={classes.selectMenuContainer}>
              <UserOptions />
            </div>
          </div>
        </div>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Header;
