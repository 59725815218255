import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      padding: '20px 50px',
      flexGrow: 1,
    },
    grid: {
      flexGrow: 1,
      padding: '50px 0px',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px',
    },
    noAvailable: {
      textAlign: 'center',
      paddingTop: 10,
    },
  })
);
