import { IComplexProductData } from '../../../shared/interfaces/icomplexProductData.interface';
import { IReturnAverage } from '../../../shared/interfaces/ireturnAverage.interface';
import ProductCard from '../productCard/productCard';
import { useStyles } from './productDetailsContainer.styles';

interface IProductDetails {
  productId: string;
  name: string;
  amount: string;
  availableNowAmount: string;
  pendingAmount?: string;
  shortName: string;
  description: string;
  bankAllocationId: string;
  bankReturnAverage?: IReturnAverage[];
  productReturn: IReturnAverage[];
  complexProductData?: IComplexProductData;
}

interface IProps {
  productDetails: IProductDetails[];
  advisorCanTransact: boolean;
}

const ProductDetailsContainer = (props: IProps) => {
  const { productDetails, advisorCanTransact } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {productDetails.length > 0 ? (
        productDetails.map((item, index) => {
          return (
            <ProductCard
              key={`${item.name}-${index}`}
              id={`product-card-panel-${index}`}
              heading={item.name}
              amount={item.amount}
              availableNowAmount={item.availableNowAmount}
              advisorCanTransact={advisorCanTransact}
              shortName={item.shortName}
              description={item.description}
              bankReturnAverage={item.bankReturnAverage}
              productReturn={item.productReturn}
              bankAllocationId={item.bankAllocationId}
              complexProductBankTableDetails={
                item.complexProductData ? item.complexProductData : null
              }
            />
          );
        })
      ) : (
        <div className={classes.container}>
          <h4 className={classes.noAvailable}>No Products Available</h4>
        </div>
      )}
    </div>
  );
};

export default ProductDetailsContainer;
