import { SvgIcon } from '@material-ui/core';

const MagnifierIcon = (props: {
  alt: string;
  maincolor: string;
  className?: any;
  size: string | number;
}) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={props.className}
      style={{ fontSize: `${props.size}` }}
    >
      <path data-name="Path 166" d="M0 0h20v20H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 167"
        d="M14 12.684h-.7l-.247-.238a5.731 5.731 0 1 0-.616.616l.238.247V14l4.4 4.393 1.325-1.307zm-5.282 0a3.962 3.962 0 1 1 3.962-3.962 3.956 3.956 0 0 1-3.957 3.962z"
        transform="translate(-.879 -.879)"
        style={{ fill: `${props.maincolor}` }}
      />
    </SvgIcon>
  );
};

export default MagnifierIcon;
