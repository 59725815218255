import {
  IconButton,
  List,
  ListItem,
  MenuList,
  Typography,
} from '@material-ui/core';
import Link from '../link/link';
import { getAllRoutes, getRoutes } from '../../shared/routes/routeList';
import { useStyles } from './menu.styles';
import { findPermission, getFilteredRoutes } from '../../shared/utils/auth';
import AddBoxIcon from '../../shared/icons/addBoxIcon';

type MenuType = 'horizontal' | 'vertical';

interface IMenuItem {
  id: string;
  name: string;
  path?: string;
  getIcon?: (classes: any) => JSX.Element;
  onClick?: () => void;
  linkTo?: string;
}

interface IMenuProps {
  type: number;
  orientation: MenuType;
  typography?: any;
  title?: IMenuItem;
  divider?: boolean;
  navLinkStyle?: string;
  navLinkActiveStyle?: string;
  itemStyle?: string;
  textStyle?: string;
  menuItems?: IMenuItem[];
  permissions: string[];
}

interface INavLinkProps {
  linkName: string;
  path?: string;
  getIcon?: (classes: any) => JSX.Element;
  onClick?: any;
  index?: number;
  navLinkStyle?: string;
  navLinkActiveStyle?: string;
  itemStyle?: string;
  textStyle?: string;
  divider?: boolean;
  typography?: any;
}

const NavLink = (props: INavLinkProps) => {
  const {
    linkName,
    path,
    getIcon,
    onClick,
    index,
    navLinkStyle,
    navLinkActiveStyle,
    itemStyle,
    textStyle,
    divider,
    typography,
  } = props;

  const classes = useStyles();
  return path ? (
    <Link
      id={linkName}
      key={index}
      to={path}
      className={navLinkStyle}
      activeClassName={navLinkActiveStyle}
      >
      <ListItem
        id={`listItem${index}`}
        key={index}
        divider={divider}
        className={itemStyle}
        onClick={onClick}
      >
        {getIcon && getIcon(classes.icon)}
        <Typography variant={typography} className={textStyle}>
          {linkName}
        </Typography>
      </ListItem>
    </Link>
  ) : (
    <ListItem
      id={`listItem${index}`}
      key={index}
      divider={divider}
      className={itemStyle}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {getIcon && getIcon(classes.icon)}
      <Typography variant={typography} className={textStyle}>
        {linkName}
      </Typography>
    </ListItem>
  );
};

const MenuOptions = (props: IMenuProps) => {
  const {
    type,
    orientation,
    typography,
    title,
    divider,
    navLinkStyle,
    navLinkActiveStyle,
    itemStyle,
    textStyle,
    menuItems,
    permissions,
  } = props;
  const routes = getRoutes(type);
  const classes = useStyles();

  const filteredRoutes = getFilteredRoutes(routes, permissions);
  const prevItems = menuItems
    ? menuItems.concat(filteredRoutes)
    : filteredRoutes;
  const items = prevItems.sort((a, b) => a.id.localeCompare(b.id));

  const titleLink = getAllRoutes().find(
    (route) => route.componentName === title?.linkTo
  );

  const titleLinkHasPermission =
    !!title &&
    !!permissions.filter((p) =>
      findPermission(p, titleLink?.permission || null)
    )[0];

  return (
    <MenuList style={{ padding: 0 }}>
      {orientation === 'horizontal' ? (
        <List className={classes.horizontal}>
          {items.map(({ name, path, getIcon, onClick }, index) => (
            <NavLink
              key={index}
              linkName={name}
              path={path}
              getIcon={getIcon}
              onClick={onClick}
              index={index}
              navLinkStyle={navLinkStyle}
              navLinkActiveStyle={navLinkActiveStyle}
              itemStyle={itemStyle}
              textStyle={textStyle}
              divider={divider}
              typography={typography}
            />
          ))}
        </List>
      ) : (
        <List className={classes.vertical}>
          {title && (
            <ListItem
              divider={divider}
              className={classes.titleItem}
              style={{ cursor: 'default' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {title.getIcon && title.getIcon(classes.titleIcon)}
                <Typography variant={typography} className={classes.titleText}>
                  {title.name}
                </Typography>
              </div>

              {titleLinkHasPermission && (
                <div>
                  <Link to={titleLink?.path || ''}>
                    <IconButton className={classes.titleAddButton}>
                      <AddBoxIcon />
                    </IconButton>
                  </Link>
                </div>
              )}
            </ListItem>
          )}
          {items.map(({ name, path, getIcon, onClick }, index) => (
            <NavLink
              key={index}
              linkName={name}
              path={path}
              getIcon={getIcon}
              onClick={onClick}
              index={index}
              navLinkStyle={navLinkStyle}
              navLinkActiveStyle={navLinkActiveStyle}
              itemStyle={itemStyle}
              textStyle={textStyle}
              divider={divider}
              typography={typography}
            />
          ))}
        </List>
      )}
    </MenuList>
  );
};

export default MenuOptions;
