import React from 'react';
import { Step, StepLabel, Stepper, StepIconProps } from '@material-ui/core';
import {
  QontoConnector,
  StepperOverrideCss,
  useQontoStepIconStyles,
} from './workflowTimeline.styles';
import clsx from 'clsx';

export interface IStep {
  label: string;
  form: any;
  buttonBack: boolean;
  nextValidated: boolean | undefined;
}
interface IWorkflowTimelineProps {
  active: number;
  steps: IStep[];
}

const QontoStepIcon = (props: StepIconProps, index: number) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.completed}>
          <label className={classes.label}>{index + 1}</label>
        </div>
      ) : (
        <div className={classes.circle}>
          <label className={classes.label}>{index + 1}</label>
        </div>
      )}
    </div>
  );
};

const WorkflowTimeline = (props: IWorkflowTimelineProps) => {
  const { active, steps } = props;
  const classes = useQontoStepIconStyles();

  return (
    <>
      <StepperOverrideCss />
      <Stepper
        activeStep={active}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel
                StepIconComponent={(props) => QontoStepIcon(props, index)}
              >
                <span className={classes.text}>{step.label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default WorkflowTimeline;
