import Chart from 'react-apexcharts';
import { useStyles } from './barChart.styles';
import { Colors } from '../../../shared/theme/styles.enum';
import { IHildaResult } from '../../../shared/interfaces/ihildaResult.interface';
import { useTheme } from '@material-ui/core';

const BarChart = (props: {
  hildaTypesData: IHildaResult[];
  hildaStatusData: IHildaResult[];
}) => {
  const { hildaTypesData, hildaStatusData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const notFunded = hildaStatusData.find(
    (status) => status.description === 'Not Funded'
  );

  const barColors = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
    theme.palette.primary.main,
    '#ffac60',
  ];
  const labelColors = [
    theme.palette.primary.main,
    theme.palette.primary.main,
    theme.palette.primary.main,
    theme.palette.primary.main,
    '#646464',
  ];
  const category = hildaTypesData.map((hildaType) => hildaType.description);
  const values = hildaTypesData.map((hildaType) => hildaType.count);
  const val: any = Object.values(values);
  const max = Math.max(...val) + 1;
  const maxVal = Math.round(max + max / 5);

  if (notFunded) {
    category.push(notFunded.description);
    values.push(notFunded.count);
  }

  // NO TYPE DECLARATIONS FOR APEXCHATRS
  const options: any = {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    grid: {
      show: true,
      strokeDashArray: 3,
    },

    legend: {
      show: false,
    },

    colors: barColors,

    plotOptions: {
      bar: {
        columnWidth: '55%',
        borderRadius: 5,
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },

    dataLabels: {
      enabled: true,
      offsetY: -10,
      style: {
        fontSize: '2.25rem',
        fontWeight: 600,
        fontFamily: 'Poppins',
        colors: barColors,
        lineHeight: 0.56,
      },
    },

    xaxis: {
      categories: category,
      labels: {
        style: {
          fontSize: '1.25rem',
          fontFamily: 'Poppins',
          colors: labelColors,
        },
      },
    },

    yaxis: {
      decimalsInFloat: 0,
      forceNiceScale: true,
      tickAmount: 6,
      min: 0,
      max: maxVal,
      axisTicks: {
        tickAmount: 6,
      },
      labels: {
        show: true,
        style: {
          fontFamily: 'Poppins',
          fontSize: '1.25rem',
          fontWeight: 500,
          colors: Colors.TextGray,
        },
        offsetY: 10,
      },
    },

    tooltip: {
      enabled: false,
    },

    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  const series = [
    {
      data: values,
    },
  ];

  return (
    <div className={classes.container}>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default BarChart;
