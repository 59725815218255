export enum Colors {
  Teal = '#3fa795',
  Black = '#000000',
  White = '#FFFFFF',
  Red = '#FF0000',
  TextGray = '#646464',
  TableHover = '#EAEAEA',
  Error = '#EB483B',
  Warning = '#FFAC60',
  Success = '#4BC761',
  BorderAndBG = '#CACACA',
  BorderLine = '#E0E0E0',
  InputBorder = '#C4C4C4',
  TextLight = '#B7B7B7',
}

export enum Typography {
  FontFamily1 = 'Josefin Sans', //link to the font-family
  FontFamily2 = 'Poppins', //link to the font-family
  Body = '1rem',
  H1 = '3rem',
  H2 = '2rem',
  H3 = '1.75rem',
  H4 = '1.5rem',
  H5 = '1.125rem',
  H6 = '1rem',
  Footnotes = '0.875rem',
  Captions = '0.75rem',
  BasicText = '1.25rem',
}
