import Cookies from "js-cookie";
import md5 from "md5";
const iFrameIdentifier = "localStorage";

type Message = {
  action: string;
  key?: string;
  subdomain?: string;
  value?: string | null;
};

const createIframe = (
  iframe: HTMLIFrameElement,
  domain: string,
  reactId: string
): Promise<void> => {
  const id = md5(domain);
  iframe = document.createElement("iframe");
  iframe.setAttribute("id", id);
  iframe.setAttribute("title", id);
  iframe.style.cssText = "display: none;";
  iframe.src = domain ? domain : "";
  document.getElementById(reactId)?.append(iframe);
  return new Promise((resolve) => {
    iframe.addEventListener(
      "load",
      () => {
        resolve();
      },
      { once: true }
    );
  });
};

const loadStorages = async () => {
  const promises = getSubdomainsVisited().map(async (key) => {
    await createIframe(
      null as any,
      `${key}?${iFrameIdentifier}=true`,
      "iframes"
    );
  });
  await Promise.all(promises);
};

const sendMessageToIFrame = (contentWindow: Window, message: Message) => {
  contentWindow.postMessage(message, "*");
};

const getValue = async (
  subdomain: string,
  key: string
): Promise<string | null> => {
  const id = md5(`${subdomain}?${iFrameIdentifier}=true`);
  const iframe: HTMLIFrameElement | null = document.getElementById(
    id
  ) as HTMLIFrameElement;
  const iframeExists = !!iframe && !!iframe.contentWindow;

  return new Promise<string | null>((resolve) => {
    if (!iframeExists) {
      return resolve(null);
    }
    sendMessageToIFrame(iframe.contentWindow, {
      action: "get",
      key,
      subdomain: id,
    });
    const handleMessage = (event: MessageEvent<any>) => {
      const { action, value } = event.data;
      if (action === `returnData-${key}-${id}`) {
        window.removeEventListener("message", handleMessage);
        resolve(value);
      }
    };
    window.addEventListener("message", handleMessage, false);
  });
};

const getValues = async (
  key: string
): Promise<{ subdomain: string; value: string | null }[]> => {
  const promises = getSubdomainsVisited().map(async (subdomain) => {
    return {
      subdomain,
      value: await getValue(subdomain, key),
    };
  });
  return Promise.all(promises);
};

const clearStorage = async (subdomain: string): Promise<void> => {
  const id = md5(`${subdomain}?${iFrameIdentifier}=true`);
  const iframe: HTMLIFrameElement | null = document.getElementById(
    id
  ) as HTMLIFrameElement;
  const iframeExists = !!iframe;
  const hasContentWindow = !!iframe?.contentWindow;
  return new Promise<void>((resolve, reject) => {
    if (!iframeExists || !hasContentWindow) {
      return reject(null);
    }
    sendMessageToIFrame(iframe.contentWindow, {
      action: "clear",
      subdomain: id,
    });
    window.addEventListener(
      "message",
      (event) => {
        const { action } = event.data;
        if (action === `clear-${id}`) {
          resolve();
        }
      },
      false
    );
  });
};

const shareStorage = async (subdomain: string): Promise<void> => {
  await createIframe(
    null as any,
    `${subdomain}?${iFrameIdentifier}=true`,
    "iframes"
  );
  const id = md5(`${subdomain}?${iFrameIdentifier}=true`);
  const iframe: HTMLIFrameElement | null = document.getElementById(
    id
  ) as HTMLIFrameElement;
  const iframeExists = !!iframe;
  const hasContentWindow = !!iframe?.contentWindow;
  const messagePromise = new Promise<void>((resolve, reject) => {
    if (!iframeExists || !hasContentWindow) {
      return reject(null);
    }
    const value = JSON.stringify(window.localStorage);
    sendMessageToIFrame(iframe.contentWindow, {
      action: "saveAll",
      subdomain: id,
      value,
    });
    window.addEventListener(
      "message",
      (event) => {
        const { action } = event.data;
        if (action === `saveAll-${id}`) {
          resolve();
        }
      },
      false
    );
  });
  const maxTimeoutPromise = new Promise((reject) => {
    setTimeout(() => reject(`Couldn't share storage with: ${subdomain}`), 2000);
  });
  await Promise.race([messagePromise, maxTimeoutPromise]);
};

const clearAllStorages = async (): Promise<void> => {
  const promises = getSubdomainsVisited().map(async (subdomain) => {
    if (subdomain === window.location.origin) {
      return null;
    }
    await clearStorage(subdomain);
  });
  await Promise.all(promises);
  return new Promise((res) => setTimeout(res, 1000));
};

const sendMessage = (message: Message, source: MessageEventSource) => {
  source.postMessage(message, "*" as any);
};

const setUpListener = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (!urlParams.has(iFrameIdentifier)) {
    return false;
  }
  window.addEventListener(
    "message",
    (event) => {
      const { action, key, subdomain, value } = event.data;
      if (action === "clear" && event.source) {
        localStorage.clear();
        sendMessage(
          {
            action: `clear-${subdomain}`,
            value: "ok",
          },
          event.source
        );
      }
      if (action === "save") {
        window.localStorage.setItem(key, value);
      }
      if (action === "saveAll" && event.source) {
        const values = JSON.parse(value);
        Object.keys(values).forEach((valueKey) => {
          window.localStorage.setItem(valueKey, values[valueKey]);
        });
        sendMessage(
          {
            action: `saveAll-${subdomain}`,
            value: "ok",
          },
          event.source
        );
      }
      if (action === "get" && event.source) {
        const value = localStorage.getItem(key);
        sendMessage(
          {
            action: `returnData-${key}-${subdomain}`,
            key,
            value,
          },
          event.source
        );
      }
      if (action === "get" && event.source) {
        const value = localStorage.getItem(key);
        sendMessage(
          {
            action: `returnData-${key}-${subdomain}`,
            key,
            value,
          },
          event.source
        );
      }
    },
    false
  );
  return true;
};

const getSubdomainsVisited = (): string[] => {
  const subdomains = (Cookies.get("domain" + process.env.REACT_APP_ENV) || "")
    .split("|")
    .filter((subdomain) => !!subdomain);
  return subdomains;
};
function getRootHostName() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  const partsLength = 2;
  for (let i: number = 0; i < partsLength; i++) {
    parts.shift();
  }
  const rootDomain = parts.join(".");
  return rootDomain;
}

const setDomainsVisited = () => {
  const domains = getSubdomainsVisited();
  if (!domains.includes(window.origin)) {
    domains.push(window.origin);
    Cookies.set("domain" + process.env.REACT_APP_ENV, domains.join("|"), {
      domain: `.${getRootHostName()}`,
    });
  }
};

const sharedStorage = {
  setUpListener,
  loadStorages,
  getValue,
  getValues,
  clearStorage,
  clearAllStorages,
  getDomainsVisited: getSubdomainsVisited,
  setDomainsVisited,
  shareStorage,
};
export default sharedStorage;
