import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

interface StyleProps {
  open: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoButton: {
      margin: '20px 8px 15px 8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiButton-label': {
        maxHeight: '70px',
      },
      '& .MuiButton-endIcon': {
        margin: 0,
      },
    },
    labelProps: {
      width: '100%',
      maxHeight: '20px',
    },
    itemsContainer: { padding: 0, maxHeight: '340px' },
    menuItems: {
      width: 320,
      height: 45,
    },
    menuItemFont: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Body,
      color: Colors.TextGray,
      fontWeight: 'normal',
      marginLeft: '1rem',
    },

    logo: {
      width: 300,
      height: 75,
      maxWidth: '100%',
    },
    logoFallbackText: {
      fontSize: Typography.H2,
      textTransform: 'initial',
      whiteSpace: 'nowrap',
      width: 300,
      padding: '1rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: Colors.Black,
    },
    expandMoreIcon: {
      transform: (props) => (props.open ? 'rotate(0deg)' : 'rotate(-90deg)'),
      color: Colors.TextGray,
      transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    divider: {
      borderTop: `1px solid ${Colors.BorderAndBG}`,
      width: '100%',
      margin: 0,
    },
  })
);
