import { Colors, Typography } from '../../shared/theme/styles.enum';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.White,
      boxShadow: theme.shadows[5],
      width: '449px',
      height: '127px',
      padding: '1rem',
      borderRadius: '4px',
    },
    text: {
      fontFamily: Typography.FontFamily1,
      fontSize: '2.125rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: Colors.TextGray,
      textTransform: 'uppercase',
      marginRight: '2.5rem',
    },
  })
);
