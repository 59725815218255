import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNTS_BY_TYPES_STATUS_FOR_ADVISOR } from './advisorDashboard.service';
import BarChart from '../../components/chart/barChart/barChart';
import MenuOptions from '../../components/menu/menu';
import { MenuIndex } from '../../components/menu/menu.enum';
import SideNav from '../../components/sideNav/sideNav';
import { UserContext } from '../../shared/context/userContext';
import { viewsUseStyles } from '../views.styles';
import PageHeader from '../../components/pageHeader/pageHeader';
import FundedAccountsHeaderGraph from './fundedAccountsHeaderGraph/fundedAccountsHeaderGraph';
import { IHildaResult } from '../../shared/interfaces/ihildaResult.interface';
import AccountsSearch from '../../components/accountsSearch/accountsSearch';
import PersonIcon from '../../shared/icons/personIcon';
import { Typography } from '../../shared/theme/styles.enum';

const getPersonIcon = (className: any) => (
  <PersonIcon alt="person" className={className} size={Typography.BasicText} />
);

const AdvisorDashboard = () => {
  const { userData } = useContext(UserContext);
  const classes = viewsUseStyles();

  const { data, loading, error } = useQuery(
    GET_ACCOUNTS_BY_TYPES_STATUS_FOR_ADVISOR,
    {
      fetchPolicy: 'network-only'
    }
  );
  
  if (loading) return null;
  if (error) return null;
  const hildaTypesData: IHildaResult[] = data.accountsByHildaType;
  const hildaStatusData: IHildaResult[] = data.accountsByHildaStatus;

  return (
    <>
      <SideNav
        menu={
          <>
            <MenuOptions
              type={MenuIndex.Accounts}
              orientation="vertical"
              divider
              title={{
                id: '0',
                name: 'Accounts',
                getIcon: getPersonIcon,
                linkTo: 'AdvisorPreFill',
              }}
              navLinkStyle={classes.globalSideNavMenu}
              navLinkActiveStyle={classes.globalSideNavMenuActive}
              itemStyle={classes.globalSideNavMenuItem}
              textStyle={classes.globalSideNavTextItem}
              permissions={userData.user.role.permissions}
            />
            <AccountsSearch />
          </>
        }
      />
      <div className={classes.globalViewContent}>
        <PageHeader
          title={`Welcome, ${userData.user.firstName} ${userData.user.lastName}`}
          urlButton={true}
        />
        <FundedAccountsHeaderGraph
          componentName="AccountsLoad"
          displayName="Overview"
          hildaTypesData={hildaTypesData}
          hildaStatusData={hildaStatusData}
        />
        <BarChart
          hildaTypesData={hildaTypesData}
          hildaStatusData={hildaStatusData}
        />
      </div>
    </>
  );
};

export default AdvisorDashboard;
