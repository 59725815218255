import {
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExportIcon from '../../shared/icons/exportIcon';
import { useStyles } from './marketingCard.styles';
import { Typography as Icon } from '../../shared/theme/styles.enum';

interface IProps {
  date: string;
  title: string;
  url: string;
  isMarketing?: boolean;
}

const MarketingCard = (props: IProps) => {
  const { date, title, url, isMarketing } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleOpenLink = () => {
    window.open(url, '_blank');
  };

  const handleMaketingDownloads = () => {
    window.location.assign(url);
  };

  return (
    <div>
      {isMarketing ? (
        <Card className={classes.card}>
          <Button
            className={classes.cardButton}
            onClick={handleMaketingDownloads}
          >
            <CardContent className={classes.marketingCardContent}>
              <Typography className={classes.marketingTitle}>
                {title}
              </Typography>
              <ExportIcon
                alt="button icon"
                maincolor={theme.palette.primary.main}
                size={Icon.BasicText}
                className={classes.icon}
              />
            </CardContent>
          </Button>
        </Card>
      ) : (
        <Card className={classes.card}>
          <Button className={classes.cardButton} onClick={handleOpenLink}>
            <CardContent className={classes.articleCardContent}>
              <Typography className={classes.articleDate}>{date}</Typography>
              <Typography className={classes.articleTitle}>{title}</Typography>
              <Typography className={classes.url}>{url}</Typography>
            </CardContent>
          </Button>
        </Card>
      )}
    </div>
  );
};

export default MarketingCard;
