import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import { Colors } from '../../../shared/theme/styles.enum';
import { Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: Colors.Black,
    },
    cell: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    fileLink: {
      width: '75%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    status: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    tooltip: {
      fontSize: '0.75rem',
      fontFamily: Typography.FontFamily2,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.17,
      letterSpacing: '0.01px',
      textAlign: 'left',
    },
  })
);

export const TableOverrideCss = withStyles({
  '@global': {
    '.MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '.MuiTablePagination-root': {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.875rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#b7b7b7',
      marginTop: '0.875rem',
    },

    '.MuiTablePagination-toolbar .MuiTypography-body2': {
      fontSize: '0.875rem',
    },
  },
})(() => null);
