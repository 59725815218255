import React, { ChangeEvent } from 'react';
import IndividualAccount from './individualAccount/individualAccount';
import JointAccount from './jointAccount/jointAccount';
import { useStyles } from './personalInformation.styles';
import {
  ENTITY_INFORMATION_TITLE,
  PERSONAL_INFORMATION_TITLE,
} from '../../../shared/utils/textConstants';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';
import InstitutionalAccount from './institutionalAccount/institutionalAccount';
import TrustAccount from './trustAccount/trustAccount';
import {
  HildaType,
  hildaTypesDic,
} from '../../../shared/interfaces/hildaType.enum';

interface IProps {
  id: string;
  formFields: any;
  type: HildaType | string;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
  handleSetFileToUpload: (file: File) => void;
}

const PersonalInformationForm = (props: IProps) => {
  const {
    id,
    formFields,
    type,
    handleSetStepCanAdvance,
    handleValueChange,
    handleSetFileToUpload,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>
        {type === HildaType.Trust || type === HildaType.Institutional ? (
          <span>{ENTITY_INFORMATION_TITLE}</span>
        ) : (
          <span>{PERSONAL_INFORMATION_TITLE}</span>
        )}
        <span className={classes.accountType}>
          &nbsp;"{`${hildaTypesDic[type]} Account`}"
        </span>
      </div>
      <br />
      {type === HildaType.Individual ? (
        <IndividualAccount
          id={id}
          formFields={formFields}
          handleSetStepCanAdvance={handleSetStepCanAdvance}
          handleValueChange={handleValueChange}
        />
      ) : type === HildaType.Joint ? (
        <JointAccount
          id={id}
          formFields={formFields}
          handleSetStepCanAdvance={handleSetStepCanAdvance}
          handleValueChange={handleValueChange}
        />
      ) : type === HildaType.Trust ? (
        <TrustAccount
          id={id}
          formFields={formFields}
          handleSetStepCanAdvance={handleSetStepCanAdvance}
          handleValueChange={handleValueChange}
          handleSetFileToUpload={handleSetFileToUpload}
        />
      ) : (
        <InstitutionalAccount
          id={id}
          formFields={formFields}
          handleSetStepCanAdvance={handleSetStepCanAdvance}
          handleValueChange={handleValueChange}
        />
      )}
    </div>
  );
};

export default PersonalInformationForm;
