import { Fragment } from "react";
import { Button, SwipeableDrawer } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./drawer.styles";

type Anchor = "right" | "left" | "top" | "bottom";

interface IDrawerProps {
  anchor: Anchor;
  button: string;
  content: JSX.Element;
  open: boolean;
  handleToggleDrawer: () => void;
  handleOpenDrawer: () => void;
  handleCloseDrawer: () => void;
  size?: number;
}

const Drawer = (props: IDrawerProps) => {
  const {
    anchor,
    button,
    content,
    open,
    handleToggleDrawer,
    handleOpenDrawer,
    handleCloseDrawer,
    size: drawerSize,
  } = props;
  const classes = useStyles(drawerSize)();

  return (
    <div>
      <Fragment>
        <Button className={classes.transferButton} onClick={handleToggleDrawer}>
          {button}
        </Button>
        <SwipeableDrawer
          anchor={anchor}
          open={open}
          onOpen={handleOpenDrawer}
          onClose={handleCloseDrawer}
        >
          <div
            className={clsx(classes.list, {
              [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
          >
            {content}
          </div>
        </SwipeableDrawer>
      </Fragment>
    </div>
  );
};

export default Drawer;
