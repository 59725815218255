import { Typography } from '@material-ui/core';
import { useStyles } from './confirmation.styles';
import _ from 'lodash';
import {
  HildaType,
  hildaTypesDic,
} from '../../../shared/interfaces/hildaType.enum';
import { getLocaleDate, getLocaleTime } from '../../../shared/utils/gridUtils';
interface IProps {
  displayTitle: boolean;
  hildaAccountInfo: any;
  advisorInfo?: boolean;
}

const ConfirmationForm = (props: IProps) => {
  const {
    accountType,
    firstName,
    lastName,
    ssn,
    dateOfBirth,
    phoneNumber,
    email,
    firstName2,
    lastName2,
    ssn2,
    dateOfBirth2,
    phoneNumber2,
    email2,
    entityName,
    taxId,
    accountTitle,
    entityEmail,
    signDate,
    advisor,
  } = props.hildaAccountInfo;
  const classes = useStyles();

  const signDateDay = getLocaleDate(signDate);
  const signDateTime = getLocaleTime(signDate);

  return (
    <>
      {props.displayTitle && (
        <Typography variant="h3" className={classes.title}>
          Confirmation
        </Typography>
      )}
      <br />
      <div className={classes.container}>
        <div className={classes.box}>
          <div>
            <Typography variant="h6" className={classes.title}>
              Account Type
            </Typography>
            <hr className={classes.divider} />
            <p className={classes.labelText}>
              {_.capitalize(hildaTypesDic[accountType])}
            </p>
            <br />
          </div>
          {(accountType === HildaType.Trust ||
            accountType === HildaType.Institutional) && (
            <div>
              <div className={classes.title}>Entity Information</div>
              <hr className={classes.divider} />
              <p className={classes.labelText}>
                Entity name: <span className={classes.text}>{entityName}</span>
              </p>
              {accountTitle && (
                <p className={classes.labelText}>
                  Account Title:{' '}
                  <span className={classes.text}>{accountTitle}</span>
                </p>
              )}
              <p className={classes.labelText}>
                Tax ID: <span className={classes.text}>{taxId}</span>
              </p>
              <p className={classes.labelText}>
                Email: <span className={classes.text}>{entityEmail}</span>
              </p>
              <br />
            </div>
          )}

          <div>
            <div className={classes.title}>Personal Information</div>
            <hr className={classes.divider} />
            <p className={classes.labelText}>
              First name: <span className={classes.text}>{firstName}</span>
            </p>
            <p className={classes.labelText}>
              Last name: <span className={classes.text}>{lastName}</span>
            </p>
            {(accountType === HildaType.Individual ||
              accountType === HildaType.Joint) && (
              <>
                <p className={classes.labelText}>
                  Social Security Number:{' '}
                  <span className={classes.text}>{ssn}</span>
                </p>
                <p className={classes.labelText}>
                  Date of birth:{' '}
                  <span className={classes.text}>{dateOfBirth}</span>
                </p>
                <p className={classes.labelText}>
                  Phone number:{' '}
                  <span className={classes.text}>{phoneNumber}</span>
                </p>
              </>
            )}
            <p className={classes.labelText}>
              Email: <span className={classes.text}>{email}</span>
            </p>
            {accountType === HildaType.Joint && (
              <>
                <hr className={classes.divider} />
                <p className={classes.labelText}>
                  First name: <span className={classes.text}>{firstName2}</span>
                </p>
                <p className={classes.labelText}>
                  Last name: <span className={classes.text}>{lastName2}</span>
                </p>
                <p className={classes.labelText}>
                  Social Security Number:{' '}
                  <span className={classes.text}>{ssn2}</span>
                </p>
                <p className={classes.labelText}>
                  Date of birth:{' '}
                  <span className={classes.text}>{dateOfBirth2}</span>
                </p>
                <p className={classes.labelText}>
                  Phone number:{' '}
                  <span className={classes.text}>{phoneNumber2}</span>
                </p>
                <p className={classes.labelText}>
                  Email: <span className={classes.text}>{email2}</span>
                </p>
              </>
            )}
          </div>
        </div>

        <div className={classes.box}>
          {props.advisorInfo && (
            <>
              <div className={classes.title}>Advisor Information</div>
              <div>
                <hr className={classes.divider} />
                <p className={classes.labelText}>
                  Advisor:{' '}
                  <span className={classes.text}>
                    {advisor.firstName + ' ' + advisor.lastName}
                  </span>
                </p>
                <p className={classes.labelText}>
                  Phone:{' '}
                  <span className={classes.text}>{advisor.phoneNumber}</span>
                </p>
                <p className={classes.labelText}>
                  Email: <span className={classes.text}>{advisor.email}</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </>
          )}
          {signDate && (
            <>
              <div className={classes.title}>Application Sent</div>
              <div>
                <hr className={classes.divider} />
                <p className={classes.labelText}>
                  Date: <span className={classes.text}>{signDateDay}</span>
                </p>
                <p className={classes.labelText}>
                  Hour: <span className={classes.text}>{signDateTime}</span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationForm;
