import { useState } from 'react';
import LoadingModal from './loadingModal';
import { ILoadingModal, LoadingModalContext } from './loadingModalContext';

const LoadingModalWrapper = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const value: ILoadingModal = { openModal, setOpenModal };

  return (
    <LoadingModalContext.Provider value={value}>
      <LoadingModal />
      {props.children}
    </LoadingModalContext.Provider>
  );
};
export default LoadingModalWrapper;
