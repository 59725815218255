import { gql } from "@apollo/client";

export const GET_ADVISORS_BY_SUBDOMAIN = gql`
  query advisorsBySubdomain($advisorSubdomain: String!) {
    advisorsBySubdomain(advisorSubdomain: $advisorSubdomain) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const getSubdomainFromURL = () => {
  const url = window.location.href;
  const subdomainRegExp = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
  const subdomain = url.match(subdomainRegExp)?.[1];

  return subdomain;
};
