import { gql } from '@apollo/client';

export const UPSERT_ADVISOR_FIRM_CONFIG = gql`
  mutation createOrUpdateAdvisorFirmConfig(
    $whereAdvisorFirmConfig: WhereAdvisorFirmConfig!
    $advisorFirmConfigInput: AdvisorFirmConfigInput!
  ) {
    createOrUpdateAdvisorFirmConfig(
      whereAdvisorFirmConfig: $whereAdvisorFirmConfig
      advisorFirmConfigInput: $advisorFirmConfigInput
    ) {
      advisorFirmId
      subDomainName
      logo
      primaryColor
      secondaryColor
    }
  }
`;

export const GET_ADVISOR_FIRM_CONFIG = gql`
  query AdvisorFirmConfig {
    advisorFirm {
      companyName
    }
    advisorFirmConfig {
      subDomainName
      logo
      primaryColor
      secondaryColor
    }
  }
`;
