import { makeStyles } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    padding: '0px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '340px',
    padding: '0px',
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px 0px 0px 1rem',
    },
    '& .MuiDataGrid-cell': {
      padding: '0px 0px 0px 1.65rem',
    },
  },
  '.MuiIconButton-root': {
    '& #postedDate-svg-icon': {
      backgroundColor: 'blue',
    },
  },
  header: {
    fontFamily: Typography.FontFamily2,
    fontSize: '1.25rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: Colors.Black,
  },
  cell: {
    fontFamily: Typography.FontFamily2,
    fontSize: '1.25rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: Colors.TextGray,
  },
  sortIcon: {
    width: 18,
    height: 16,
    marginBottom: 1,
  },
  renderHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 0px 1rem',
  },
  renderHeaderAlignRight: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '-.5rem',
  },
  noRows: {
    textAlign: 'center',
    paddingTop: 50,
  },
  button: {
    width: 'max-content',
    textTransform: 'capitalize',
    border: 'none',
    fontFamily: Typography.FontFamily2,
    fontSize: '1.25rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'right',
  },
});
