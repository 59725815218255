import PageHeader from '../../components/pageHeader/pageHeader';
import SideNav from '../../components/sideNav/sideNav';
import Tips from './uploadInstructions/uploadInstructions';
import {
  ACCOUNTS_UPLOADS_TIPS,
  IF_FAILURE_TEXT,
  LOAD_CLIENTS_CRM,
} from '../../shared/utils/textConstants';
import { useStyles } from './accountsLoad.styles';
import { useContext } from 'react';
import MenuOptions from '../../components/menu/menu';
import { UserContext } from '../../shared/context/userContext';
import { MenuIndex } from '../../components/menu/menu.enum';
import { viewsUseStyles } from '../views.styles';
import AccountsLoadTable from './accountsLoadTable/accountsLoadTable';
import TableHeader from '../../components/tableHeader/tableHeader';
import UploadFile from '../../components/uploadFile/uploadFile';

const AccountsLoad = () => {
  const { userData } = useContext(UserContext);
  const classes = viewsUseStyles();
  const { content } = useStyles();

  return (
    <>
      <SideNav
        menu={
          <MenuOptions
            type={MenuIndex.Overview}
            orientation={'vertical'}
            divider={true}
            navLinkStyle={classes.globalSideNavMenu}
            navLinkActiveStyle={classes.globalSideNavMenuActive}
            itemStyle={classes.globalSideNavMenuItem}
            textStyle={classes.globalSideNavTextItem}
            permissions={userData.user.role.permissions}
          />
        }
      />
      <div className={classes.globalViewContent}>
        <PageHeader title={LOAD_CLIENTS_CRM} />
        <div className={content}>
          <UploadFile folderFile="uploads" />
        </div>
        <div className={content}>
          <Tips tips={ACCOUNTS_UPLOADS_TIPS} ifFailureText={IF_FAILURE_TEXT} />
        </div>
        <div className={content}>
          <TableHeader title="Upload History" />
          <AccountsLoadTable />
        </div>
      </div>
    </>
  );
};

export default AccountsLoad;
