import React from 'react';

export interface ILoadingModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const defaultLoadingModalContext: ILoadingModal = {
  openModal: false,
  setOpenModal: () => {},
};

export const LoadingModalContext = React.createContext(
  defaultLoadingModalContext
);
