import { SvgIcon } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    addBoxIcon: {
      fill: palette.primary.main,
      width: '33px',
      height: '33px',
    },
  })
);

const AddBoxIcon = (props: any) => {
  const classes = useStyles();
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      className={classes.addBoxIcon}
    >
      <path
        data-name="Trazado 10514"
        d="M0 0h33v33H0z"
        style={{ fill: 'none' }}
      />
      <path
        data-name="Trazado 10515"
        d="M27 3H6a3 3 0 0 0-3 3v21a3 3 0 0 0 3 3h21a3.009 3.009 0 0 0 3-3V6a3.009 3.009 0 0 0-3-3zm-3 15h-6v6h-3v-6H9v-3h6V9h3v6h6z"
        className={classes.addBoxIcon}
      />
    </SvgIcon>
  );
};

export default AddBoxIcon;
