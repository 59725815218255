import * as Sentry from "@sentry/react";
interface IIfiFetchOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: any;
  params?: string | undefined;
}

export const restWrapper = {
  IfiFetch: async (options: IIfiFetchOptions) => {
    const { url, method, params } = options;
    const urlWithParams = params ? url + params : url;
    switch (method) {
      case 'GET':
        try {
          const response = await fetch(urlWithParams);
          return await response.json();
        } catch (e) {
          Sentry.captureException(e,{
            extra:{
              params:JSON.stringify({
                urlWithParams
              })
            }
          })
          Sentry.captureException(e)
          console.error(e);
          throw e;
        }
      case 'POST':
        const { data = {} } = options;
        const config = {
          method: method,
          body: data,
        };
        try {
          const response = await fetch(urlWithParams, config);
          return await response.json();
        } catch (e) {
          Sentry.captureException(e,{
            extra:{
              params:JSON.stringify({
                urlWithParams, 
                config
              })
            }
          })
          console.error(e);
          throw e;
        }
      default:
        break;
    }
  },
};

export const downloadURI = (url: string) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.click();
  anchor.remove();
};
