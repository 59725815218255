import { useContext } from 'react';
import AccountsSearch from '../../components/accountsSearch/accountsSearch';
import MenuOptions from '../../components/menu/menu';
import { MenuIndex } from '../../components/menu/menu.enum';
import SideNav from '../../components/sideNav/sideNav';
import { UserContext } from '../../shared/context/userContext';
import PersonIcon from '../../shared/icons/personIcon';
import { Typography } from '../../shared/theme/styles.enum';
import { viewsUseStyles } from '../views.styles';
import HildaPendingClientInfo from './hildaPendingClientInfo/hildaPendingClientInfo';

const getPersonIcon = (className: any) => (
  <PersonIcon alt="person" className={className} size={Typography.BasicText} />
);

const HildaPendingClient = () => {
  const { userData } = useContext(UserContext);
  const classes = viewsUseStyles();

  return (
    <>
      <SideNav
        menu={
          <>
            <MenuOptions
              type={MenuIndex.Accounts}
              orientation="vertical"
              divider
              title={{
                id: '0',
                name: 'Accounts',
                getIcon: getPersonIcon,
                linkTo: 'AdvisorPreFill',
              }}
              navLinkStyle={classes.globalSideNavMenu}
              navLinkActiveStyle={classes.globalSideNavMenuActive}
              itemStyle={classes.globalSideNavMenuItem}
              textStyle={classes.globalSideNavTextItem}
              permissions={userData.user.role.permissions}
            />
            <AccountsSearch />
          </>
        }
      />
      <div className={classes.globalViewContent}>
        <HildaPendingClientInfo />
      </div>
    </>
  );
};

export default HildaPendingClient;
