import MenuOptions from '../../components/menu/menu';
import PageHeader from '../../components/pageHeader/pageHeader';
import { MenuIndex } from '../../components/menu/menu.enum';
import SideNav from '../../components/sideNav/sideNav';
import { viewsUseStyles } from '../views.styles';
import { useContext } from 'react';
import { UserContext } from '../../shared/context/userContext';
import MarketingMaterialDetails from './marketingDetails/marketingMaterialDetails';

const MarketingMaterials = () => {
  const { userData } = useContext(UserContext);
  const classes = viewsUseStyles();

  return (
    <>
      <SideNav
        menu={
          <MenuOptions
            type={MenuIndex.Overview}
            orientation={'vertical'}
            divider={true}
            navLinkStyle={classes.globalSideNavMenu}
            navLinkActiveStyle={classes.globalSideNavMenuActive}
            itemStyle={classes.globalSideNavMenuItem}
            textStyle={classes.globalSideNavTextItem}
            permissions={userData.user.role.permissions}
          />
        }
      />
      <div className={classes.globalViewContent}>
        <PageHeader title="Marketing materials" />
        <MarketingMaterialDetails />
      </div>
    </>
  );
};

export default MarketingMaterials;
