import { createStyles, makeStyles } from '@material-ui/core';
import { Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    horizontal: {
      display: 'flex',
      gap: 50,
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      padding: 0,
    },
    items: {
      textTransform: 'capitalize',
      margin: 0,
      padding: 0,
    },
    icon: {
      marginRight: 22.9,
      width: 32,
      fontSize: 30,
    },
    titleIcon: {
      marginRight: 22.9,
      fontSize: 32,
      fill: palette.primary.main,
    },
    titleText: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.3125rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.95,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: palette.primary.main,
    },
    titleItem: {
      color: palette.primary.main,
      padding: '12px 22.9px 12px 22.9px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleAddButton: {
      padding: 0,
    },
  })
);
