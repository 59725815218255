import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    subtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    textField: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginBottom: 20,
      '& .MuiInputBase-root': {
        fontFamily: Typography.FontFamily2,
      },
    },
    textFieldInput: { fontFamily: Typography.FontFamily2 },
  })
);
