import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    container: {
      height: '27rem',
      width: '52rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.White,
    },
    colorBackground: {
      width: '20rem',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    wireIcon: {
      padding: 20,
      width: '100%',
      height: '100%',
    },
    wireInstructionsContainer: {
      width: '100rem',
      height: '100%',
      padding: '3rem 2rem',
    },
    title: {
      fontFamily: Typography.FontFamily1,
      textTransform: 'uppercase',
      fontSize: '2.125rem',
      fontWeight: 'bold',
      lineHeight: 1.26,
      letterSpacing: '0.01px',
      textAlign: 'left',
      marginBottom: '2rem',
      color: Colors.Black,
    },
    subtitle: {
      fontSize: Typography.Footnotes,
      fontFamily: Typography.FontFamily2,
      letterSpacing: '0.01px',
      color: Colors.TextGray,
      marginBottom: '0.5rem',
    },
    wireInformationContainer: {
      fontSize: Typography.Footnotes,
      fontFamily: Typography.FontFamily2,
      letterSpacing: '0.01px',
      color: Colors.TextGray,
      marginBottom: '0.5rem',
    },
    grid: {
      flexGrow: 1,
      padding: '30px 0px',
    },
    text: {
      fontSize: Typography.Footnotes,
      fontFamily: Typography.FontFamily2,
      fontWeight: 500,
      letterSpacing: '0.01px',
      whiteSpace: 'nowrap',
    },
    textData: {
      fontSize: Typography.Footnotes,
      fontFamily: Typography.FontFamily2,
      letterSpacing: '0.01px',
      whiteSpace: 'nowrap',
    },
    line: {
      width: '90%',
      border: 'none',
      alignItems: 'center',
      borderTop: `1px dotted ${Colors.TextGray}`,
      marginTop: 10.5,
      marginLeft: 20,
    },
    wireButtonContainer: {
      display: 'flex',
      justifyContent: 'right',
    },
    button: {
      padding: '3.5px 40px',
      borderRadius: 3,
      border: `solid 1px ${theme.palette.primary.main}`,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.White,
      textTransform: 'capitalize',
    },
  })
);
