import { usePDF } from '@react-pdf/renderer';
import { IStatementPDF } from '../../shared/interfaces/istatementPDF.interface';
import { getStatementPeriod } from '../../shared/utils/pdfUtils';
import { useStyles } from './pdfInstance.styles';
import StatementPDF from './statementPDF';

interface IPDFInstanceProps {
  statements: IStatementPDF[];
}

const PDFInstance = (props: IPDFInstanceProps) => {
  const { statements } = props;
  const classes = useStyles();

  const [instance] = usePDF({
    document: <StatementPDF statements={statements} />,
  });

  if (instance.loading)
    return <span className={classes.loading}>View | Download</span>;

  if (instance.error) return null;

  return (
    <>
      <a
        href={instance.url || undefined}
        className={classes.cellLink}
        target="_blank"
        rel="noreferrer"
      >
        View
      </a>
      <span className={classes.colorPrimary}>&nbsp;|&nbsp;</span>
      <a
        href={instance.url || undefined}
        className={classes.cellLink}
        download={
          getStatementPeriod(statements[0].startPeriodDate.toString()) +
          ' Statement.pdf'
        }
      >
        Download
      </a>
    </>
  );
};

export default PDFInstance;
