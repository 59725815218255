import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    margin: '20px auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  noAvailable: {
    textAlign: 'center',
    paddingTop: 10,
  },
});
