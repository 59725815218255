import { gql } from '@apollo/client';

export const GET_STATEMENTS_BY_BANK_ALLOCATION_ID = gql`
  query Statements(
    $where: BankAllocationTableWhere!
    $searchCriteria: SearchCriteria
    $countWhere: BankAllocationTableWhere!
    $countSearchCriteria: SearchCriteria
  ) {
    statementsByBankAllocationId(
      where: $where
      searchCriteria: $searchCriteria
    ) {
      productName
      createdAt
    }
    statementsCountByBankAllocationId(
      where: $countWhere
      searchCriteria: $countSearchCriteria
    )
  }
`;
