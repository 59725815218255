import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginTop: 60,
    },
    gridColumn: {
      alignItems: 'center',
    },
    mainTitle: {
      fontFamily: Typography.FontFamily1,
      fontSize: '2.125rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    themeColor: {
      color: theme.palette.primary.main,
    },
    mainSubtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.BasicText,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.TextGray,
      marginTop: 10,
    },
    titleText: {
      fontFamily: Typography.FontFamily2,
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.TextGray,
    },
    subtitleText: {
      fontFamily: Typography.FontFamily2,
      fontSize: 20,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.TextGray,
    },
  })
);
