import { makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

interface StyleProps {
  isComplexProduct?: boolean;
  expanded?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(({ palette }) => ({
  container: {
    width: '95.6%',
    margin: '0px auto 40px auto',
    display: 'flex',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
  accordion: {
    width: '100%',
    borderLeft: '6px solid',
    borderLeftColor: palette.primary.main,
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 4,
    boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.16)',
    cursor: 'auto',
    '&:hover': {
      outline: '1px solid',
      outlineColor: palette.primary.main,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '90px',
    },
  },
  accordionSummary: {
    padding: 0,
    boxShadow: (props) =>
      props.expanded ? '' : `0px 10px 5px -3px ${Colors.TableHover}`,
  },
  mainGrid: {
    flexGrow: 1,
    alignItems: 'center',
  },
  hyba: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  data: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  productName: {
    display: 'flex',
    alignItems: 'center',
  },
  apyContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  apyTitle: {
    fontSize: Typography.BasicText,
    fontFamily: Typography.FontFamily2,
    fontWeight: 600,
    lineHeight: 1.2,
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      fontWeight: 500,
    },
  },
  totalTitle: {
    fontSize: Typography.BasicText,
    fontFamily: Typography.FontFamily2,
    fontWeight: 500,
    lineHeight: 1.2,
    textAlign: 'right',
    marginRight: 30,
    '& span': {
      fontWeight: 500,
    },
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 33,
  },
  productIcon: {
    marginLeft: 30,
    width: 48,
    height: 51,
  },
  heading: {
    fontFamily: Typography.FontFamily2,
    fontSize: '1.5rem',
    fontWeight: 600,
    color: Colors.Black,
    textAlign: 'left',
    marginLeft: 5,
  },
  secondaryHeading: {
    fontFamily: Typography.FontFamily2,
    fontSize: '2.125rem',
    lineHeight: '1.5rem',
    color: Colors.Black,
    textAlign: 'right',
    marginRight: 30,
    '& span': {
      fontSize: '1.5rem',
      fontFamily: Typography.FontFamily2,
      lineHeight: '1.5rem',
      color: Colors.Black,
      justifySelf: 'end',
      alignSelf: 'center',
    },
  },
  total: {
    fontSize: '1.25rem !important',
  },
  details: {
    borderTop: '1px solid',
    borderTopColor: Colors.TableHover,
    zIndex: 0,
  },
  product: {
    marginTop: 20,
  },
  gridContainer: {
    padding: '5rem 2.5rem 0 2.5rem',
  },
  hybaContainer: {
    '&::before': {
      content: '""',
      display: 'block',
      height: 1,
      width: '100%',
      background: Colors.TableHover,
      margin: '1rem auto',
    },
  },
  hybaWrapper: {
    padding: '0px 30px',
  },
  grid: {
    flexGrow: 1,
    padding: '30px 0px',
  },
  header: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.BasicText,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  name: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.BasicText,
    fontWeight: 500,
    textAlign: 'left',
  },
  apy: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.BasicText,
    fontWeight: 500,
    textAlign: 'left',
  },
  line: {
    width: '90%',
    border: 'none',
    alignItems: 'center',
    borderTop: `1px dotted ${Colors.TextGray}`,
    marginTop: 15,
    marginRight: 50,
  },
  totalAmount: {
    display: 'flex',
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.BasicText,
    fontWeight: 400,
    textAlign: 'right',
    paddingBottom: 5,
  },
  depositButton: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.Body,
    fontWeight: 600,
    color: palette.primary.main,
    borderRadius: 3,
    border: '1px solid',
    borderColor: palette.primary.main,
    textTransform: 'capitalize',
    width: 154,
    height: 32,
    justifySelf: 'end',
    alignSelf: 'center',
    marginRight: 0,
    '&:hover': {
      backgroundColor: Colors.White,
    },
  },
  hybaTotal: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.BasicText,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  hybaAmount: {
    fontFamily: Typography.FontFamily2,
    fontSize: Typography.H3,
    fontWeight: 400,
    lineHeight: 0.86,
  },
  hybaDecimals: {
    fontSize: '1.3rem',
  },

  //TABS
  containerStyle: {
    '& .Mui-selected': {
      color: palette.primary.main,
      fontWeight: 'bold',
    },
    '& .MuiTab-root': {
      minWidth: 'fit-content',
    },
  },
  tabsRowStyle: {
    marginTop: 0,
    borderBottom: `1px solid ${Colors.BorderAndBG}`,
    paddingBottom: 0.2,
  },
  childrenStyle: {
    padding: '2.8rem 0 0 0',
  },
}));
