import { createStyles, makeStyles } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    filterButton: {
      display: 'flex',
      margin: '0px 0px 28px 24px',
      width: '86%',
      padding: '15px 86.5px 15px 86.5px',
      borderRadius: 3,
      fontFamily: Typography.FontFamily2,
      fontSize: '0.9375rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.93,
      letterSpacing: 'normal',
      textAlign: 'left',
      textTransform: 'capitalize',
      color: Colors.White,
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: palette.primary.main,
      },
    },
    popoverContainer: {
      width: '318px',
      marginTop: 5,
      borderRadius: 3,
    },
    selectAll: {
      padding: '0px 20px 0px 14px',
    },
    selectStatus: {
      padding: '0px 20px 0px 14px',
    },
    checkboxText: {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.9375rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.93,
      letterSpacing: 'normal',
      textAlign: 'left',
      textTransform: 'capitalize',
    },
    checkbox: {
      padding: 0,
      margin: 6.5,
      borderRadius: 3,
      border: 'solid 1px',
      borderColor: Colors.BorderAndBG,
      backgroundColor: Colors.White,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.9375rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.93,
      letterSpacing: 'normal',
      textAlign: 'left',
      textTransform: 'capitalize',
    },
    applyButton: {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.9375rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.93,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.White,
      textTransform: 'capitalize',
      margin: '10px 50px 10px 20px',
      padding: '10px 22px 9px 26px',
      borderRadius: 3,
    },
  })
);
