import { Typography } from '@material-ui/core';
import { useSnapshot } from 'valtio';
import ConfirmationForm from '../../../../components/forms/confirmation/confirmation';
import PageHeader from '../../../../components/pageHeader/pageHeader';
import { ISelfApplyFields } from '../../../../shared/interfaces/iform';
import { useStyles } from './selfApplyConfirmation.styles';
import { state } from '../../../../shared/store';

interface IProps {
  hildaAccountInfo: ISelfApplyFields;
}

const SelfApplyConfirmation = (props: IProps) => {
  const classes = useStyles();
  const snap = useSnapshot(state);

  return (
    <>
      <PageHeader title="Welcome" />
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          {snap.companyName} New Account Application
        </Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="h2">
            This application has been sent
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            Contact IFI support if you have any questions.
          </Typography>
          <hr className={classes.divider} />
          <div className={classes.content}>
            <Typography variant="h3" className={classes.accountSummary}>
              Account Summary
            </Typography>
            <ConfirmationForm
              displayTitle={false}
              hildaAccountInfo={props.hildaAccountInfo}
              advisorInfo={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelfApplyConfirmation;
