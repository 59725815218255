import { useEffect } from "react";
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { mapToColumns, formatCurrency } from "../../../shared/utils/gridUtils";
import { useStyles } from "./positionsTable.styles";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_POSITIONS_BY_BANK_ALLOCATION_ID } from "./positionsTable.service";
import Loading from "../../loading/loading";
import { IPosition } from "../../../shared/interfaces/iposition.interface";

const initialPageSize = 5;

interface IProps {
  amount: string;
  bankAllocationId: string;
  isComplexProduct?: boolean;
}

const PositionsTable = (props: IProps) => {
  const classes = useStyles();
  const { amount, bankAllocationId, isComplexProduct } = props;
  const { loading, data, error, networkStatus, refetch } = useQuery(
    GET_POSITIONS_BY_BANK_ALLOCATION_ID,
    {
      variables: {
        where: {
          bankAllocationId,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [amount, bankAllocationId]);

  if (loading || networkStatus === NetworkStatus.fetchMore)
    return (
      <>
        <div className={classes.container}>
          <Loading isLoading />
        </div>
      </>
    );

  if (error) return null;

  const positions: IPosition[] = data.positions;
  const count = 1;

  if (positions.length === 0)
    return (
      <>
        <div className={classes.container}>
          <h4 className={classes.noRows}>No rows to display</h4>
        </div>
      </>
    );

  let arr;
  // Map Columns to Table and filter Status field and CreatedAt
  if (isComplexProduct) {
    arr = mapToColumns(positions).filter(
      (column) =>
        column.field !== "status" &&
        column.field !== "createdAt" &&
        column.field !== "rate"
    );
  } else {
    arr = mapToColumns(positions).filter(
      (column) =>
        column.field !== "status" &&
        column.field !== "createdAt" &&
        column.field !== "apy"
    );
  }

  const valueFormatter = (params: GridValueGetterParams) => {
    const row = params.row;
    if (params.field === "amount") {
      return "$" + formatCurrency(row.amount);
    }
    if (params.field === "rate") {
      return row.rate === "0" || isNaN(Number(row.rate))
        ? "- %"
        : Number(row.rate).toFixed(2) + "%";
    }
    if (params.field === "apy") {
      return row.apy === "0" || isNaN(Number(row.apy))
        ? "- %"
        : Number(row.apy).toFixed(2) + "%";
    } else {
      return params.value;
    }
  };

  const sortComparator: GridComparatorFn = (v1: any, v2: any) => {
    return v1 - v2;
  };

  let columns: GridColumns;
  if (isComplexProduct) {
    columns = arr.map((column, idx) => {
      if (idx === arr.length - 1) {
        return {
          ...column,
          headerAlign: "right",
          align: "right",
          valueFormatter,
          sortComparator,
        };
      }
      if (column.field === "fedId") {
        return {
          ...column,
          valueFormatter,
          headerName: "FED ID",
        };
      } else if (column.field === "apy") {
        return {
          ...column,
          valueFormatter,
          headerName: "APY",
        };
      } else {
        return { ...column, valueFormatter, sortComparator };
      }
    });
  } else {
    columns = arr.map((column, idx) => {
      if (idx === arr.length - 1) {
        return {
          ...column,
          headerAlign: "right",
          align: "right",
          valueFormatter,
        };
      }
      if (column.field === "fedId") {
        return {
          ...column,
          valueFormatter,
          headerName: "FED ID",
        };
      } else {
        return { ...column, valueFormatter };
      }
    });
  }

  const rows = positions.map((p, idx) => {
    return { ...p, id: idx };
  });

  return (
    <div className={classes.container}>
      <DataGrid
        rowCount={count}
        columns={columns}
        rows={rows}
        disableColumnMenu
        classes={{
          columnHeader: classes.header,
          cell: classes.cell,
        }}
        style={{ border: "none" }}
        rowsPerPageOptions={[initialPageSize, 25, 50, 100]}
        hideFooterSelectedRowCount
      />
    </div>
  );
};

export default PositionsTable;
