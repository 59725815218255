import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import { getAllRoutes } from './routeList';
import ProtectedRoute from './protectedRoute';
import NotFound from '../../views/notFound';
import { IRoute } from '../interfaces/iroute.interface';
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/header';
import SelfApply from '../../views/selfApply/selfApply';

const Routes = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [reRoute, setReroute] = useState('/');
  const allRoutes = getAllRoutes();

  useEffect(() => {
    setUserData({ ...userData, reRoute });
    // eslint-disable-next-line
  }, [reRoute]);

  return (
    <BrowserRouter>
      <Header>
        <Switch>
          <Route
            exact
            path={'/selfApply'}
            render={() => {
              if (userData.authState === 'signedIn')
                return <Route component={NotFound} />;
              return <Route component={SelfApply} />;
            }}
          />
          {allRoutes.map((route: IRoute, index: number) => {
            return (
              <ProtectedRoute
                key={index}
                exact
                path={route.path}
                render={(props) =>
                  route.render(props, { userData, setUserData })
                }
                user={userData.user}
                permissions={userData.user.role.permissions}
                routePermission={route.permission}
                setReroute={setReroute}
              />
            );
          })}
          <Route path="*" component={NotFound} />
        </Switch>
      </Header>
    </BrowserRouter>
  );
};

export default Routes;
