import { makeStyles } from '@material-ui/core';
import { Colors } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) => ({
  cellLink: {
    color: palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  colorPrimary: {
    color: palette.primary.main,
  },
  loading: {
    color: Colors.TextLight,
  },
}));
