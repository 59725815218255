import { useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import { GridRenderCellParams, DataGrid } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import Loading from '../../../components/loading/loading';
import {
  getLocaleDateTime,
  mapRenderCell,
  mapToColumns,
} from '../../../shared/utils/gridUtils';
import {
  getFileDownloadUrl,
  GET_ACCOUNTS_UPLOAD_BY_ADVISOR_FIRM,
  IAccountUploadByAdvisorFirm,
} from './accountsLoadTable.service';
import { TableOverrideCss, useStyles } from './accountsLoadTable.styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { downloadURI } from '../../../shared/utils/restWrapper';
import _ from 'lodash';
import RenderMessage from '../../../components/renderMessage/renderMessage';
import { LoadingModalContext } from '../../../components/loadingModal/loadingModalContext';
import * as Sentry from "@sentry/react";

const AccountsLoadTable = () => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_ACCOUNTS_UPLOAD_BY_ADVISOR_FIRM
  );
  const { setOpenModal } = useContext(LoadingModalContext);

  if (loading) return <Loading isLoading />;

  if (error)
    return <RenderMessage message="There was a problem loading the data" />;

  const accountUploadsData: IAccountUploadByAdvisorFirm[] =
    data.accountUploadsByCurrentAdvisorFirm;

  if (accountUploadsData.length === 0)
    return <RenderMessage message="No files have been uploaded" />;

  // Map Columns to Table and filter comments field
  const columns = mapToColumns(accountUploadsData)
    .filter((column) => column.field !== 'comments')
    .map((column) => {
      // Rename display of Url field to File Name and disable sort
      if (column.field === 'url')
        return {
          ...column,
          headerName: 'File Name',
          sortable: false,
        };
      else if (column.field === 'createdAt')
        return {
          ...column,
          headerName: 'Uploaded On',
          sortable: false,
        };
      else return { ...column, sortable: false };
    });

  // Render ToolTip inside in Status Field only
  const columnsWithRender = mapRenderCell(
    columns,
    (params: GridRenderCellParams) => {
      if (params.field === 'status') {
        const comments = params.row.comments || 'No comments';
        const value =
          typeof params.value === 'string' ? _.capitalize(params.value) : '';
        return (
          <Tooltip
            title={<p className={classes.tooltip}>{comments}</p>}
            placement="right-start"
            arrow
          >
            <p className={classes.status}>{value}</p>
          </Tooltip>
        );
      }
      if (params.field === 'url' && params.row.status === 'processing') {
        let key: string;
        let name: string;
        let ext: string;
        let value: string;
        if (typeof params.value === 'string') {
          // Remove autogenerated timestamp for display
          key = params.value;
          name = key.split('[-]')[0];
          ext = key.split('[-]')[1].split('.')[1];
          value = name + '.' + ext;
        } else {
          key = '';
          value = '';
        }
        return (
          <>
            <p className={classes.fileLink}>{value}</p>
            <IconButton color="primary" onClick={() => downloadFile(key)}>
              <GetAppIcon />
            </IconButton>
          </>
        );
      }
      if (params.field === 'url') {
        const value =
          typeof params.value === 'string' ? params.value.split('[-]')[0] : '';
        return <p>{value}</p>;
      } else {
        return <p>{params.value}</p>;
      }
    }
  );

  // Format date and url
  const rows = accountUploadsData.map((row) => {
    const date = getLocaleDateTime(row.createdAt);
    const fileName = row.url?.split('uploads/')[1];
    return {
      ...row,
      createdAt: (
        <div style={{ display: 'flex' }}>
          <span style={{ width: '130px' }}>{date[0]}</span>
          <span>{date[1]}</span>
        </div>
      ),
      url: fileName,
    };
  });

  const downloadFile = async (key: string) => {
    try {
      setOpenModal(true);
      const { url } = await getFileDownloadUrl(key, 'uploads');
      downloadURI(url);
      setOpenModal(false);
    } catch (e) {
      Sentry.captureException(e)
      setOpenModal(false);
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <div style={{ height: '300px' }}>
        <TableOverrideCss />
        <DataGrid
          columns={columnsWithRender}
          rows={rows}
          disableColumnMenu
          classes={{
            columnHeader: classes.header,
            cell: classes.cell,
          }}
          style={{ border: 'none' }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          hideFooterSelectedRowCount
        />
      </div>
    </React.Fragment>
  );
};

export default AccountsLoadTable;
