import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Colors } from '../../shared/theme/styles.enum';

export const drawerWidth = 340;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerParent: {
      position: 'relative',
      //[theme.breakpoints.up('md')]: {
      borderRight: '12px solid #cacaca',
      //},
    },
    drawer: {
      //[theme.breakpoints.up('md')]: {
      marginTop: -46,
      width: drawerWidth,
      flexShrink: 0,
      marginLeft: 'auto',
      '& .MuiBackdrop-root': {
        display: 'none',
      },
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        position: 'absolute',
        transition: 'none !important',
      },
      //},
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 0,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    closeMenuButton: {
      marginRight: 'auto',
      marginLeft: 0,
      color: Colors.White,
    },
    toolbar: {
      backgroundColor: Colors.Black,
    },
    menuActive: {
      backgroundColor: Colors.Black,
      borderLeft: '4.5px solid #5da595 !important',
    },
    menuButton: {
      marginRight: theme.spacing(3),
      //[theme.breakpoints.up('md')]: {
      display: 'none',
      //},
    },
    divider: {
      border: '1px solid',
      borderColor: Colors.TableHover,
      marginBottom: 0,
    },
  })
);
