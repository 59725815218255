import { gql } from '@apollo/client';
import { restWrapper } from '../../../shared/utils/restWrapper';

export interface IAccountUploadByAdvisorFirm {
  id: number;
  createdAt?: string;
  userId?: number;
  advisorFirmId?: number;
  url?: string;
  status?: string;
  comments?: string;
}

export const GET_ACCOUNTS_UPLOAD_BY_ADVISOR_FIRM = gql`
  query AccountUploadsByCurrentAdvisorFirm {
    accountUploadsByCurrentAdvisorFirm {
      id
      url
      status
      comments
      createdAt
    }
  }
`;

export const getFileDownloadUrl = async (key: string, folder: string) => {
  const url = process.env.REACT_APP_SERVERLESS + '/downloadFile';
  const params = `?fileName=${key}&folder=${folder}`;
  const res = await restWrapper.IfiFetch({ url, method: 'GET', params });
  return res;
};
