import { useContext } from 'react';
import { Route, RouteProps } from 'react-router';
import NotFound from '../../views/notFound';
import { UserContext } from '../context/userContext';
import { IUser } from '../interfaces/iuser.interface';
import { getIsRouteAuthenticated } from '../utils/auth';

export type ProtectedRouteProps = {
  user: IUser;
  permissions: string[];
  routePermission: string;
  setReroute: React.Dispatch<React.SetStateAction<string>>;
} & RouteProps;

const ProtectedRoute = ({
  user,
  permissions,
  routePermission,
  setReroute,
  ...routeProps
}: ProtectedRouteProps) => {
  const { userData } = useContext(UserContext);
  const isRouteAuthenticated = getIsRouteAuthenticated(
    permissions,
    routePermission
  );
  const isSignedIn = userData.authState === 'signedIn';

  if (isRouteAuthenticated || isSignedIn) {
    return <Route {...routeProps} />;
  } else {
    typeof routeProps.path === 'string'
      ? setReroute(routeProps.path)
      : setReroute('/');
    return <Route component={NotFound} />;
  }
};

export default ProtectedRoute;
