import { makeStyles, Theme } from '@material-ui/core';
import { Typography, Colors } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles<Theme>(({ palette }) => ({
  card: {
    width: '100%',
    height: 92,
    borderLeft: '6px solid',
    borderLeftColor: palette.primary.main,
    borderRadius: 3,
    boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.16)',
    cursor: 'auto',
    display: 'grid',
    '&:hover': {
      outline: '1px solid',
      outlineColor: palette.primary.main,
    },
  },
  cardButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: Colors.White,
    },
  },
  marketingCardContent: {
    display: 'flex',
    gap: 25,
    justifyContent: 'space-between',
    padding: '0px 0px 0px 45px !important',
  },
  marketingTitle: {
    width: '22vw',
    fontSize: '1.28vw',
    fontWeight: 600,
    fontFamily: Typography.FontFamily2,
    lineHeight: 1,
    color: Colors.Black,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 1280px)': {
      // eslint-disable-line
      width: '30vw !important',
    },
    '@media (max-width: 1700px)': {
      // eslint-disable-line
      width: '16vw',
    },
  },
  icon: {
    width: 60,
  },
  articleCardContent: {
    padding: '0px 45px 0px 45px !important',
  },
  articleDate: {
    fontSize: '0.65vw',
    fontWeight: 500,
    fontFamily: Typography.FontFamily2,
    lineHeight: 2,
    color: Colors.TextGray,
    textAlign: 'left',
  },
  articleTitle: {
    fontSize: '1.28vw',
    fontWeight: 600,
    fontFamily: Typography.FontFamily2,
    lineHeight: 1,
    color: palette.primary.main,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (min-width: 700px)': {
      // eslint-disable-line
      width: '24vw',
    },
  },
  url: {
    fontSize: '0.65vw',
    fontWeight: 500,
    fontFamily: Typography.FontFamily2,
    lineHeight: 2,
    color: Colors.TextGray,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (min-width: 700px)': {
      // eslint-disable-line
      width: '24vw',
    },
  },
}));
