import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '40px 40px 15px 40px',
    },
    title: {
      fontFamily: Typography.FontFamily1,
      fontSize: Typography.H4,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.primary.main,
    },
    closeIcon: {
      margin: '1px 0 1.4px 0px',
      objectFit: 'contain',
      cursor: 'pointer',
    },
    divider: {
      margin: '0px 40px 35px 40px',
      height: 0,
      border: `solid 1px ${Colors.BorderAndBG}`,
    },
    subtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
      margin: '0px 40px 35px 40px',
    },
    grid: {
      flexGrow: 1,
      marginBottom: 40,
    },
    noAccounts: {
      margin: '0px 40px 0px 40px',
    },
    sourceTitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0,
      textAlign: 'left',
    },
    sourceButton: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Captions,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      cursor: 'pointer',
      padding: '0px 8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    sourceText: {
      color: Colors.White,
    },
    dropdownTitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0,
      textAlign: 'left',
      margin: '35px 40px 15px 40px',
    },
    textField: {
      margin: '0px 40px 0px 40px',
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0,
      textAlign: 'left',
      border: 'none !important',
      color: Colors.BorderAndBG,
      '& .MuiFilledInput-input': {
        padding: '15px 5px',
        fontFamily: Typography.FontFamily2,
      },
      '& .MuiInputBase-formControl': {
        width: '100%',
      },
      '& .MuiTypography-colorTextSecondary': {
        paddingTop: 3.5,
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'end',
      gap: 20,
      margin: '60px 40px 20px 40px',
    },
    buttonCancel: {
      padding: '8px 34px 7px',
      borderRadius: 3,
      border: `solid 1px ${theme.palette.primary.main}`,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
    },
    buttonSubmit: {
      padding: '8px 34px 7px',
      borderRadius: 3,
      border: `solid 1px ${theme.palette.primary.main}`,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.White,
      textTransform: 'capitalize',
    },
    disabledButton: {
      color: `${Colors.White} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: 0.5,
    },
  })
);
