import { SvgIcon } from '@material-ui/core';
import { Colors } from '../theme/styles.enum';

const CoinIcon = (props: { alt: string; className?: any }) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      className={props.className}
    >
      <path data-name="Path 419" d="M0 0h34v34H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 423"
        d="M18 2a16 16 0 1 0 16 16A16.006 16.006 0 0 0 18 2zm1.408 25.216V29.2h-2.8v-2.064a6.459 6.459 0 0 1-4.832-4.736l2.64-1.072c.1.352.928 3.344 3.84 3.344 1.488 0 3.168-.768 3.168-2.576 0-1.536-1.12-2.336-3.648-3.248-1.76-.624-5.36-1.648-5.36-5.3a4.925 4.925 0 0 1 4.192-4.736V6.8h2.8v1.984a5.481 5.481 0 0 1 4.256 3.568l-2.528 1.072A3.092 3.092 0 0 0 18.1 11.28c-1.12 0-2.9.592-2.9 2.224 0 1.52 1.376 2.1 4.224 3.04 3.84 1.328 4.816 3.28 4.816 5.52 0 4.208-4 5.008-4.832 5.152z"
        transform="translate(-1 -1)"
        style={{ fill: Colors.TextGray }}
      />
    </SvgIcon>
  );
};

export default CoinIcon;
