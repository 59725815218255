import React, { ChangeEvent, useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { useStyles } from '../personalInformation.styles';
import {
  Errors,
  IPersonalInformationJointFields,
  Types,
} from '../../../../shared/interfaces/iform';
import {
  PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_PRIMARY,
  PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_SECONDARY,
  REQUIRED_FIELD_MISSING,
} from '../../../../shared/utils/textConstants';
import { isFormInputValid } from '../../../../shared/utils/formUtils';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';

const errorsDefault: Errors = {
  firstName: false,
  lastName: false,
  ssn: false,
  dateOfBirth: false,
  phoneNumber: false,
  email: false,
  address: false,
  zipCode: false,
  city: false,
  state: false,
  firstName2: false,
  lastName2: false,
  ssn2: false,
  dateOfBirth2: false,
  phoneNumber2: false,
  email2: false,
  address2: false,
  zipCode2: false,
  city2: false,
  state2: false,
};

const types: Types = {
  firstName: FormTypes.Text,
  lastName: FormTypes.Text,
  ssn: FormTypes.SSN,
  dateOfBirth: FormTypes.Date,
  phoneNumber: FormTypes.Phone,
  email: FormTypes.Email,
  address: FormTypes.Address,
  zipCode: FormTypes.ZipCode,
  city: FormTypes.Text,
  state: FormTypes.Text,
  firstName2: FormTypes.Text,
  lastName2: FormTypes.Text,
  ssn2: FormTypes.SSN,
  dateOfBirth2: FormTypes.Date,
  phoneNumber2: FormTypes.Phone,
  email2: FormTypes.Email,
  address2: FormTypes.Address,
  zipCode2: FormTypes.ZipCode,
  city2: FormTypes.Text,
  state2: FormTypes.Text,
};

interface IProps {
  id: string;
  formFields: IPersonalInformationJointFields;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
}

const JointAccount = (props: IProps) => {
  const { id, formFields, handleSetStepCanAdvance, handleValueChange } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const classes = useStyles();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      const hasDifferentEmails = compareEmails(formFields["email"], formFields["email2"])

      if (!hasDifferentEmails && (label === "email" || label === "email2"))
        return {
          ...prevErrors,
          [label]: true
        }
      
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const compareEmails = (email: string, email2: string): boolean => {
    return email !== email2;
  }

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;

    const hasDifferentEmails = compareEmails(
      formFields[types.email],
      formFields["email2"]
    );

    if (isFormValid &&  hasDifferentEmails) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  return (
    <div>
      <div className={classes.title}>
        <span>{PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_PRIMARY}</span>
      </div>
      <br />
      <FormControl className={classes.root} component="fieldset">
        <TextField
          id="firstName"
          name="firstName"
          label="First Name"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.firstName}
          value={formFields.firstName}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
          helperText={errors.firstName && REQUIRED_FIELD_MISSING}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Last Name"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.lastName}
          value={formFields.lastName}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
          helperText={errors.lastName && REQUIRED_FIELD_MISSING}
        />
        <TextField
          id="ssn"
          name="ssn"
          label="Social Security Number"
          variant="outlined"
          required
          style={{ width: "15rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          inputProps={{ maxLength: 12 }}
          error={errors.ssn}
          value={formFields.ssn}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="dateOfBirth"
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="MM/DD/YYYY"
          variant="outlined"
          required
          style={{ width: "8.688rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.dateOfBirth}
          value={formFields.dateOfBirth}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="phoneNumber"
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          style={{ width: "11.75rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          inputProps={{ maxLength: 16 }}
          error={errors.phoneNumber}
          value={formFields.phoneNumber}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.email}
          value={formFields.email}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />

        <TextField
          id="address"
          name="address"
          label="Address"
          variant="outlined"
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          required
          size="small"
          error={errors.address}
          value={formFields.address}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="city"
          name="city"
          label="City"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.city}
          value={formFields.city}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
          helperText={errors.city && REQUIRED_FIELD_MISSING}
        />
        <TextField
          id="state"
          name="state"
          label="State"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.state}
          value={formFields.state}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
          helperText={errors.state && REQUIRED_FIELD_MISSING}
        />
        <TextField
          id="zipCode"
          name="zipCode"
          label="Zip Code"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.zipCode}
          value={formFields.zipCode}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
      </FormControl>
      <br />
      <div className={classes.title}>
        <span>{PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_SECONDARY}</span>
      </div>
      <br />
      <FormControl className={classes.root} component="fieldset">
        <TextField
          id="firstName2"
          name="firstName2"
          label="First Name"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.firstName2}
          value={formFields.firstName2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="lastName2"
          name="lastName2"
          label="Last Name"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.lastName2}
          value={formFields.lastName2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="ssn2"
          name="ssn2"
          label="Social Security Number"
          variant="outlined"
          required
          style={{ width: "15rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          inputProps={{ maxLength: 12 }}
          error={errors.ssn2}
          value={formFields.ssn2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="dateOfBirth2"
          name="dateOfBirth2"
          label="Date of Birth"
          placeholder="MM/DD/YYYY"
          variant="outlined"
          required
          style={{ width: "8.688rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.dateOfBirth2}
          value={formFields.dateOfBirth2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="phoneNumber2"
          name="phoneNumber2"
          label="Phone Number"
          variant="outlined"
          style={{ width: "11.75rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          inputProps={{ maxLength: 16 }}
          error={errors.phoneNumber2}
          value={formFields.phoneNumber2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="email2"
          name="email2"
          label="Email"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.email2}
          value={formFields.email2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />

        <TextField
          id="address2"
          name="address2"
          label="Address"
          variant="outlined"
          style={{ width: "20rem" }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          required
          error={errors.address2}
          value={formFields.address2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />

        <TextField
          id="city2"
          name="city2"
          label="City"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.city2}
          value={formFields.city2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="state2"
          name="state2"
          label="State"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.state2}
          value={formFields.state2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
        <TextField
          id="zipCode2"
          name="zipCode2"
          label="Zip Code"
          variant="outlined"
          required
          style={{ width: "20rem" }}
          inputProps={{ maxLength: 250 }}
          className={classes.textField}
          InputLabelProps={{
            className: classes.textFieldInput,
          }}
          size="small"
          error={errors.zipCode2}
          value={formFields.zipCode2}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
          onBlur={handleOnBlur}
        />
      </FormControl>
    </div>
  );
};

export default JointAccount;
