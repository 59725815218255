import { SvgIcon } from '@material-ui/core';
import { Colors } from '../theme/styles.enum';

const FileIcon = (props: { alt: string; className?: any }) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      className={props.className}
    >
      <path data-name="Path 419" d="M0 0h34v34H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 422"
        d="M7.027 2a2.926 2.926 0 0 0-3.012 2.825L4 27.423a2.926 2.926 0 0 0 3.011 2.825h18.175a2.939 2.939 0 0 0 3.027-2.825V10.474L19.133 2zm10.593 9.887V4.119l8.323 7.768z"
        transform="translate(.894 .855)"
        style={{ fill: Colors.TextGray }}
      />
    </SvgIcon>
  );
};

export default FileIcon;
