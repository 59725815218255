import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core';
import { Colors } from '../../../shared/theme/styles.enum';
// import { useStyles } from './assetsGraph.styles';
import {
  formatCurrencyCompact,
  formatCurrencyNoDecimals,
} from '../../../shared/utils/gridUtils';

const AssetsChart = (props: any) => {
  const { assets } = props;
  // const classes = useStyles(); In case we need to give styles once the other chart be available
  const theme = useTheme();

  const { light, main, dark } = theme.palette.primary;

  const barColors = [light, main, dark, main];

  const labelColors = ['#646464'];

  const category = assets?.map((asset: any) => asset.type);
  const values = assets?.map((asset: any) => asset.amount);

  const allZeros = values.every((val: any) => val === '0');

  // NO TYPE DECLARATIONS FOR APEXCHATRS
  const options: any = {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    grid: {
      show: true,
      strokeDashArray: 3,
    },

    legend: {
      show: false,
    },

    colors: barColors,

    plotOptions: {
      bar: {
        columnWidth: '80%',
        borderRadius: 4,
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },

    dataLabels: {
      enabled: true,
      offsetY: -10,
      style: {
        fontSize: '1rem',
        fontWeight: 500,
        fontFamily: 'Poppins',
        colors: labelColors,
        lineHeight: 0.56,
      },
      formatter: (values: any) => {
        return formatCurrencyNoDecimals(values);
      },
    },

    xaxis: {
      categories: category,
      labels: {
        style: {
          fontSize: '1rem',
          fontFamily: 'Poppins',
          colors: Colors.TextGray,
          fontWeight: 'bold',
        },
      },
    },

    yaxis: {
      decimalsInFloat: 0,
      forceNiceScale: true,
      tickAmount: 3,
      min: 0,
      axisTicks: {
        tickAmount: 6,
      },
      labels: {
        show: true,
        style: {
          fontFamily: 'Poppins',
          fontSize: '0.875rem',
          fontWeight: 500,
          colors: Colors.TextGray,
        },
        offsetY: 3,
        formatter: (value: any, index: string) => {
          if (allZeros) return '$' + formatCurrencyCompact(index + '00000');
          return '$' + formatCurrencyCompact(value);
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value: any) => {
          return formatCurrencyNoDecimals(value);
        },
      },
    },

    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  const series = [
    {
      name: 'Deposit',
      data: values,
    },
  ];

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="480px"
        height="530px"
      />
    </div>
  );
};

export default AssetsChart;
