import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import { error, success, warning } from '../../shared/utils/pathConstants';
import { useStyles } from './toast.styles';

type ToastType = 'success' | 'warning' | 'error';

const toastIcons = {
  success: {
    icon: success,
  },
  warning: {
    icon: warning,
  },
  error: {
    icon: error,
  },
};

interface ITriggerProps {
  type: ToastType;
  title: string;
  msg: string;
  autoClose?: number | false;
  toastContentProps?: Partial<ToastContentProps>;
}

const Msg = (triggerProps: ITriggerProps) => {
  const { type, title, msg } = triggerProps;
  const classes = useStyles();
  return (
    <div className={classes.toastMsgContainer}>
      <div className={classes.title}>
        <img src={toastIcons[type].icon} alt={`${toastIcons[type]} icon`} />
        <p>
          <b>{title}</b>
        </p>
      </div>
      <div className={classes.mainText}>
        <p>{msg}</p>
      </div>
    </div>
  );
};

export const triggerAlert = (triggerProps: ITriggerProps) => {
  const { type, title, msg, autoClose } = triggerProps;
  type === 'success'
    ? toast.success(<Msg type={type} title={title} msg={msg} />, {
        autoClose: autoClose !== undefined ? autoClose : 5000,
      })
    : type === 'warning'
    ? toast.warning(<Msg type={type} title={title} msg={msg} />, {
        autoClose: autoClose !== undefined ? autoClose : 5000,
      })
    : toast.error(<Msg type={type} title={title} msg={msg} />, {
        autoClose: autoClose !== undefined ? autoClose : 5000,
      });
};

const ToastWrapper = () => {
  const classes = useStyles();
  return (
    <ToastContainer
      className={classes.toastContainer}
      style={{ width: '350px' }}
      position="top-right"
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      hideProgressBar={false}
      icon={false}
    />
  );
};

export default ToastWrapper;
