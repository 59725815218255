import { SvgIcon } from '@material-ui/core';

const ExportIcon = (props: {
  alt: string;
  maincolor: string;
  className?: any;
  size: string | number;
}) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="30"
      viewBox="0 0 19 19"
    >
      <path
        data-name="Trazado 124"
        d="M0 0h24v30H0z"
        style={{ fill: 'none' }}
      />
      <path
        data-name="Trazado 125"
        d="M19 9h-4V3H9v6H5l7 7zM5 18v2h14v-2z"
        style={{ fill: `${props.maincolor}` }}
      />
    </SvgIcon>
  );
};

export default ExportIcon;
