import { SvgIcon } from '@material-ui/core';

const DateIcon = (props: {
  alt: string;
  maincolor: string;
  className?: any;
  size: string | number;
}) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        data-name="Trazado 161"
        d="M0 0h24v24H0z"
        style={{ fill: 'none' }}
      />
      <path
        data-name="Trazado 162"
        d="M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z"
        style={{ fill: `${props.maincolor}` }}
      />
    </SvgIcon>
  );
};

export default DateIcon;
