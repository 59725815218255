import { gql } from '@apollo/client';

export const GET_ADVISOR_FIRM_THEME = gql`
  query AdvisorFirmTheme {
    advisorFirmConfig {
      primaryColor
      secondaryColor
    }
  }
`;
