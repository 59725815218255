import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useStyles } from "./productCard.styles";
import {
  bank,
  defaultIcon,
  coins,
  womenIcon,
} from "../../../shared/utils/pathConstants";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../shared/context/userContext";
import ReturnChart from "../returnChart/returnChart";
import ProductInfo from "../productInfo/productInfo";
import TabComponent from "../../tabComponent/tabComponent";
import TransactionsTable from "../../bankAllocationTables/transactionsTable/transactionsTable";
import { IReturnAverage } from "../../../shared/interfaces/ireturnAverage.interface";
import PositionsTable from "../../bankAllocationTables/positionsTable/positionsTable";
import StatementsTable from "../../bankAllocationTables/statementsTable/statementsTable";
import ProductBankTable from "./productBankTable";
import { Roles } from "../../../shared/interfaces/roles.enum";
import { IComplexProductData } from "../../../shared/interfaces/icomplexProductData.interface";
import { GET_POSITIONS_BY_BANK_ALLOCATION_ID } from "../../bankAllocationTables/positionsTable/positionsTable.service";
import { IPosition } from "../../../shared/interfaces/iposition.interface";
import { useQuery } from "@apollo/client";

interface IProps {
  heading: string;
  amount: string;
  availableNowAmount: string;
  details: string;
  advisorCanTransact: boolean;
  shortName: string;
  description: string;
  bankReturnAverage: IReturnAverage[];
  productReturn: IReturnAverage[];
  bankAllocationId: string;
  complexProductBankTableDetails?: IComplexProductData;
}

const ProductCard: any = (props: IProps) => {
  const {
    heading,
    amount,
    availableNowAmount,
    advisorCanTransact,
    shortName,
    description,
    bankReturnAverage,
    productReturn,
    bankAllocationId,
    complexProductBankTableDetails,
  } = props;

  const { userData } = useContext(UserContext);
  const roleName = userData.user.role.name;
  const [expanded, setExpanded] = useState(true);
  const isComplexProduct = () =>
    complexProductBankTableDetails ? true : false;

  const classes = useStyles({ isComplexProduct: isComplexProduct(), expanded });
  const { loading, data, error, refetch } = useQuery(
    GET_POSITIONS_BY_BANK_ALLOCATION_ID,
    {
      variables: {
        where: {
          bankAllocationId,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [amount]);

  if (loading) {
    return (
      <main className={classes.container}>
        <Accordion className={classes.accordion} key={`accordion-loading`}>
          <AccordionSummary
            className={classes.accordionSummary}
            aria-controls="accordionSummary-content"
            id={`accordionSummary-loading`}
          >
            <Grid container className={classes.mainGrid}>
              <Grid container item sm={2} md={2} lg={1} direction="column">
                <Skeleton
                  className={classes.productIcon}
                  variant="rect"
                  width={50}
                  height={50}
                />
              </Grid>
              <Grid container item sm={10} md={7} lg={6} direction="column">
                <Typography className={classes.heading}>
                  {" "}
                  <Skeleton variant="text" width={210} height={35} />
                </Typography>
              </Grid>
              <Grid container item sm={6} md={3} lg={2} direction="column">
                <Typography className={classes.apyTitle}>
                  <Skeleton variant="text" width={100} height={35} />
                </Typography>
              </Grid>
              <Grid container item sm={6} md={12} lg={3} direction="column">
                <Typography
                  className={classes.totalTitle}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Skeleton variant="text" width={200} height={35} />
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </main>
    );
  }

  if (error) return null;

  const positions: IPosition[] = data.positions;

  const showButton = (
    amount: string,
    role: string = roleName,
    canTransact: boolean = advisorCanTransact
  ) => {
    if (
      (role === Roles.HildaUser && amount === "0.00") ||
      (role === Roles.AdvisorAdmin && canTransact && amount === "0.00") ||
      (role === Roles.AdvisorRep && canTransact && amount === "0.00")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeposit = () => {
    return console.log("Deposit Now!");
  };

  const renderIcon = (heading: string) => {
    if (heading.includes("Black")) {
      return bank;
    } else if (heading.includes("Women")) {
      return womenIcon;
    } else if (heading.includes("HYBA")) {
      return coins;
    } else {
      return defaultIcon;
    }
  };
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <main className={classes.container}>
      <Accordion className={classes.accordion} key={`accordion-${heading}`}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="accordionSummary-content"
          id={`accordionSummary-${heading}`}
          onClick={handleExpand}
        >
          {!complexProductBankTableDetails ? (
            <Grid container className={classes.mainGrid}>
              <Grid container item sm={2} md={2} lg={1} direction="column">
                <img
                  className={classes.productIcon}
                  src={`${renderIcon(heading)}`}
                  alt={`${heading} icon`}
                />
              </Grid>
              <Grid container item sm={10} md={7} lg={6} direction="column">
                <Typography className={classes.heading}>{heading}</Typography>
              </Grid>
              <Grid container item sm={6} md={3} lg={2} direction="column">
                <Typography className={classes.apyTitle}>
                  APY: &nbsp;
                  <span>
                    {isNaN(Number(positions[0].apy))
                      ? "- "
                      : Number(positions[0].apy).toFixed(2)}
                    %
                  </span>
                </Typography>
              </Grid>
              <Grid container item sm={6} md={12} lg={3} direction="column">
                {showButton(amount) ? (
                  <Button
                    className={classes.depositButton}
                    onClick={handleDeposit}
                  >
                    Deposit Now!
                  </Button>
                ) : (
                  <Typography className={classes.totalTitle}>
                    Total: &nbsp;
                    {amount === "0.00" ? (
                      <span className={classes.total}>$-</span>
                    ) : (
                      <span className={classes.total}>${amount}</span>
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <div className={classes.hyba}>
              <Grid container className={classes.mainGrid}>
                <Grid container item md={2} lg={1} direction="column">
                  <img
                    className={classes.productIcon}
                    src={`${renderIcon(heading)}`}
                    alt={`${heading} icon`}
                  />
                </Grid>
                <Grid container item md={5} lg={8} direction="column">
                  <Typography className={classes.heading}>{heading}</Typography>
                </Grid>
                <Grid container item md={5} lg={3} direction="column">
                  <Typography className={classes.totalTitle}>
                    Total: &nbsp;
                    {amount === "0.00" ? (
                      <span className={classes.total}>$-</span>
                    ) : (
                      <span className={classes.total}>${amount}</span>
                    )}
                  </Typography>
                </Grid>
                <Grid container item sm={12} md={12} lg={12} direction="column">
                  {expanded && (
                    <ProductBankTable
                      complexProductBankTableDetails={
                        complexProductBankTableDetails
                      }
                      showButton={showButton}
                      handleDeposit={handleDeposit}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item md={6}>
              <ProductInfo
                text={description}
                amount={amount}
                availableNowAmount={availableNowAmount}
              />
            </Grid>
            <Grid item md={6}>
              <ReturnChart
                bankReturnAverage={bankReturnAverage}
                productReturn={productReturn}
                shortName={shortName}
              />
            </Grid>
            <Grid item lg>
              <TabComponent
                tabItems={[
                  {
                    label: "Positions",
                    children: (
                      <PositionsTable
                        amount={amount}
                        bankAllocationId={bankAllocationId}
                        isComplexProduct={isComplexProduct()}
                      />
                    ),
                  },
                  {
                    label: "Transactions",
                    children: (
                      <TransactionsTable
                        amount={amount}
                        bankAllocationId={bankAllocationId}
                      />
                    ),
                  },
                  {
                    label: "Statements",
                    children: (
                      <StatementsTable
                        amount={amount}
                        bankAllocationId={bankAllocationId}
                      />
                    ),
                  },
                ]}
                containerStyle={classes.containerStyle}
                tabsRowStyle={classes.tabsRowStyle}
                childrenStyle={classes.childrenStyle}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </main>
  );
};

export default ProductCard;
