import { Typography } from '@material-ui/core';
import { useStyles } from './productInfo.styles';
import {
  AVAILABLE_NOW,
  PENDING_TRANSACTIONS,
} from '../../../shared/utils/textConstants';
import { formatCurrencyNumber } from '../../../shared/utils/gridUtils';

interface IProductInfoProps {
  text: string;
  amount: string;
  availableNowAmount: string;
}

const ProductInfo = (props: IProductInfoProps) => {
  const { text, amount, availableNowAmount } = props;
  const classes = useStyles();

  const amountFixed = Number(amount.replace(/,/g, ''));
  const availableNowAmountFixed = Number(availableNowAmount.replace(/,/g, ''));
  const pendingTransactions = (
    amountFixed - availableNowAmountFixed
  ).toString();

  let Htmltext = String(text)

  return (
    <>
      <div className={classes.wrapperText}>
        <Typography className={classes.text} variant="h3">
          {/*UNSAFE: Added as per customer direct request */}
          <div dangerouslySetInnerHTML={{ __html: Htmltext }} />
        </Typography>
      </div>
      <div className={classes.grid}>
        <Typography className={classes.title}>{AVAILABLE_NOW}</Typography>
        <hr className={classes.line} />
        <Typography className={classes.amount}>
          {availableNowAmount === '0.00' ? (
            <span>$-</span>
          ) : (
            <>{`$${availableNowAmount}`}</>
          )}
        </Typography>
      </div>
      <div className={classes.grid}>
        <Typography className={classes.title}>
          {PENDING_TRANSACTIONS}
        </Typography>
        <hr className={classes.line} />
        <Typography className={classes.amount}>
          {amount === '0.00' ? (
            <span>$-</span>
          ) : (
            <>{`$${formatCurrencyNumber(pendingTransactions)}`}</>
          )}
        </Typography>
      </div>
    </>
  );
};

export default ProductInfo;
