import { useEffect, useRef } from "react";
import sharedStorage from "../utils/sharedStorage";

const useHostname = (advisorFirmSubDomain: string) => {
  const { REACT_APP_ENV } = process.env;
  const hostname = useRef<string>("");

  useEffect(() => {
    setHostnameData();
    // eslint-disable-next-line
  }, []);

  const setHostnameData = () => {
    if (REACT_APP_ENV === "local") {
      if (advisorFirmSubDomain !== undefined) {
        hostname.current = advisorFirmSubDomain;
        localStorage.setItem("hostname", advisorFirmSubDomain);
      }
    } else {
      hostname.current = window.location.host.split(".")[0];
      localStorage.setItem("hostname", window.location.host.split(".")[0]);
    }
  };

  const changeHostname = async (advisorFirmSubDomain: string) => {
    const path = window.location.host.split(".");
    if (hostname.current === advisorFirmSubDomain) {
      return;
    } else {
      if (REACT_APP_ENV === "prod") {
        if (path.length === 2) {
          await unshiftHostname(advisorFirmSubDomain, path);
          return;
        }
        await spliceHostname(advisorFirmSubDomain, path);
      }
      if (
        REACT_APP_ENV === "dev" ||
        REACT_APP_ENV === "qa" ||
        REACT_APP_ENV === "stage"
      ) {
        if (path.length === 3) {
          await unshiftHostname(advisorFirmSubDomain, path);
          return;
        }
        await spliceHostname(advisorFirmSubDomain, path);
      }
    }
  };

  const spliceHostname = async (
    advisorFirmSubDomain: string,
    path: string[]
  ) => {
    path.splice(0, 1, advisorFirmSubDomain);
    const pathString = path.join(".");
    await sharedStorage.shareStorage(`https://${pathString}`);
    window.location.host = pathString;
  };

  const unshiftHostname = async (
    advisorFirmSubDomain: string,
    path: string[]
  ) => {
    path.unshift(advisorFirmSubDomain);
    const pathString = path.join(".");
    await sharedStorage.shareStorage(`https://${pathString}`);
    window.location.host = pathString;
  };

  return { changeHostname };
};
export default useHostname;
