import { gql } from '@apollo/client';

export const GET_MARKETING_MATERIALS = gql`
  query MaterialsWrapper {
    allMarketingMaterials {
      type {
        name
        id
      }
      materials {
        materialType {
          name
          id
        }
        date
        title
        url
      }
    }
  }
`;
