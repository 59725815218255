import { gql } from '@apollo/client';

export const GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR = gql`
  query AccountsByHildaStatus($searchCriteria: String) {
    accountsByHildaStatus(searchCriteria: $searchCriteria) {
      description
      accounts {
        id
        name
        statusId
        submitted
      }
      count
    }
  }
`;
