import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        width: '25ch',
      },
    },
    title: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%',
    },
    personalInformationTitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      marginTop: '1.5rem',
    },
    accountType: {
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
    },
    helper: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      margintTop: 0,
      fontFamily: Typography.FontFamily2,
    },
    textField: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginBottom: 20,
      '& .MuiInputBase-root': {
        fontFamily: Typography.FontFamily2,
      },
    },
    textFieldInput: { fontFamily: Typography.FontFamily2 },
    radioGroupTitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Captions,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    radioGroup: {
      width: '20rem',
      border: `1px solid ${Colors.InputBorder}`,
      borderRadius: 5,
      fontFamily: Typography.FontFamily2,
      padding: '2px 8px 12px 15px',
      marginLeft: 0,
      marginBottom: 12,
    },
    radioButton: {
      '& .MuiRadio-root': {
        padding: 5,
      },
      '& .MuiFormControlLabel-label': {
        fontFamily: Typography.FontFamily2,
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 0,
        textAlign: 'left',
        color: Colors.TextGray,
      },
      '& svg': {
        width: '1em !important',
        height: '0.9em !important',
      },
    },
    input: {
      fontFamily: Typography.FontFamily2,
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0,
      textAlign: 'left',
      width: '60%',
    },
    otherContainer: {
      '& .MuiInputBase-root': {
        marginTop: 0,
      },
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    textFieldArea: {
      width: '100%',
      padding: 20,
      borderRadius: 12,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: Colors.White,
      '& .MuiInputBase-input': {
        fontFamily: Typography.FontFamily2,
        fontSize: Typography.Footnotes,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: Colors.TextGray,
        paddingRight: 18,
      },
    },
    agree: {
      width: 'fit-content',
      '& .MuiFormControlLabel-label': {
        fontFamily: Typography.FontFamily2,
        fontSize: Typography.Body,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& svg': {
        width: '1em !important',
      },
    },
    agreementTitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Captions,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    agreement: {
      width: '100%',
      border: `1px solid ${Colors.InputBorder}`,
      borderRadius: 5,
      fontFamily: Typography.FontFamily2,
      marginBottom: 12,
    },
    textFieldAreaAgreement: {
      width: '100%',
      padding: '15px 5px 0px 5px',
      backgroundColor: Colors.White,
      marginBottom: '1rem',
      '& .MuiInputBase-input': {
        fontFamily: Typography.FontFamily2,
        fontSize: Typography.Footnotes,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: Colors.TextGray,
        paddingRight: 18,
      },
    },
    uploadButton: {
      padding: '8px 17.5px 7px 14px',
      marginBottom: 15,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H6,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.95,
      letterSpacing: 0.32,
      textAlign: 'center',
      color: Colors.White,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    labelText: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    text: {
      color: Colors.Black,
    },
  })
);
