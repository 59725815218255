import { makeStyles, withStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) => ({
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: palette.primary.main,
    backgroundColor: Colors.White,
  },

  mayorque: {
    color: palette.primary.main,
    fontSize: '1.3rem',
    paddingRight: 5,
  },

  calendarIcon: {
    margin: '0 8px 0 12px',
  },

  clear: {
    padding: '5px 15.2px 5px 15px',
    borderRadius: 4,
    border: 'solid 1px',
    color: palette.primary.main,
    backgroundColor: Colors.White,
    fontFamily: Typography.FontFamily2,
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
}));

export const DatePickerOverrideCss = withStyles(({ palette }) => ({
  '@global': {
    '.MuiFormControl-root > .MuiInputBase-root': {
      padding: '1px 5px 1px 0',
      width: '135px',
    },

    '.MuiInputBase-input.MuiInput-input': {
      textAlign: 'center',
      fontSize: '1.125rem',
      fontWeight: 500,
      fontFamily: Typography.FontFamily2,
      color: palette.primary.main,
    },

    '.MuiPickersCalendarHeader-switchHeader *': {
      '&>:nth-child(n+1)': {
        color: `${palette.primary.main}!important`,
      },
      '&:is(.MuiTypography-body1)': {
        fontFamily: Typography.FontFamily2,
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.5,
      },
    },

    '.MuiDialog-container': {
      height: '100%',
    },

    '.MuiDialog-scrollPaper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.MuiPickersModal-dialogRoot': {
      minWidth: 360,
    },

    '.MuiPickersBasePicker-pickerView': {
      minWidth: 360,
    },

    '.MuiPickersCalendarHeader-transitionContainer': {
      width: '100%',
      height: '23px',
      overflow: 'hidden',
      color: palette.primary.main,
    },

    '.MuiPickersCalendarHeader-daysHeader > .MuiTypography-caption': {
      fontFamily: Typography.FontFamily2,
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1.67,
    },

    '.MuiInput-underline': {
      '&:after': {
        border: 'none !important',
        borderWidth: '0 !important',
      },
      '&:before': {
        border: 'none !important',
        borderWidth: '0 !important',
      },
    },

    '.MuiPickersCalendar-transitionContainer * ': {
      fontSize: '0.875rem !important',
      fontFamily: Typography.FontFamily2,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0px',
    },

    '.MuiPickersDay-current': {
      color: `${Colors.TextGray}!important`,
      fontWeight: 600,
    },

    '.MuiPickersDay-daySelected': {
      color: `${Colors.White}!important`,
      fontWeight: 600,
      backgroundColor: `${palette.primary.main}!important`,
    },
  },
}))(() => null);
