import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      margin: '65px 0px 0px 65px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    header: {
      fontFamily: Typography.FontFamily1,
      fontSize: Typography.H4,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.83,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.primary.main,
    },
    box: {
      margin: '50px 55px 80px 55px',
      borderRadius: 12,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '54px 55px 10px 55px',
      justifyContent: 'center',
    },
    content: {
      margin: '20px 0 50px 0',
    },
    title: {
      alignSelf: 'center',
      margin: '0 0px 22px 0px',
      fontFamily: Typography.FontFamily1,
      fontSize: '2.125rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    subtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: '1.25rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.TextGray,
      marginBottom: '10px',
    },
    divider: {
      margin: '31.5px 0 45.5px 0.5px',
      borderTop: `1px solid ${Colors.BorderAndBG}`,
    },
    accountSummary: {
      fontFamily: Typography.FontFamily2,
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: Colors.Black,
      marginBottom: 62,
    },
  })
);
