import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: Colors.Black,
    },
    subtitle: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "left",
    },
    radioGroup: {
      marginBottom: 35,
      width: "100%",
    },
    radioButton: {
      "& .MuiRadio-root": {
        padding: 5,
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: Typography.FontFamily2,
        fontSize: 14,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.71,
        letterSpacing: 0,
        textAlign: "left",
        marginRight: 20,
      },
      "& svg": {
        width: "1em !important",
        height: "0.9em !important",
      },
    },
    text: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      display: "flex",
    },
    divider: {
      margin: "25.5px 0 45.5px 0px",
      opacity: 0.33,
    },
    footer: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "left",
      color: Colors.Black,
    },
    colorTextGrey: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
      color: Colors.TextGray,
      fontWeight: "normal",
    },
    primaryColor: {
      color: theme.palette.primary.main,
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Footnotes,
    },
    instructions: {
      marginBottom: theme.spacing(2.5),
      fontSize: Typography.Footnotes,
      fontFamily: Typography.FontFamily2,
    },
    list: {
      paddingLeft: 14,
    },
    wireForm: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    wireTextField: {
      width: "25rem",
      marginBottom: 20,
      "& .MuiInputBase-root": {
        fontFamily: Typography.FontFamily2,
      },
    },
    advisorSelectInput: {
      fontSize: Typography.H6,
      lineHeight: 1.5,
      letterSpacing: 0,
      textAlign: "left",
      marginTop: theme.spacing(1),
      "& .MuiFilledInput-input": {
        padding: "15px 20px",
        fontFamily: Typography.FontFamily2,
      },
      "& .MuiInputBase-formControl": {
        width: "auto",
      },
    },
    wireTextFieldInput: {
      fontFamily: Typography.FontFamily2,
    },
    menuItem: {
      width: "100%",
      fontFamily: Typography.FontFamily2,
      color: Colors.TextGray,
      padding: "10px 20px",
      border: "0.8px solid #f1f1f1",
      "& .MuiList-padding": {
        padding: "0px !important",
        backgroundColor: "red",
      },
    },
  })
);
