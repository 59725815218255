import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    border: '1px solid',
    borderColor: palette.primary.main,
    borderRadius: '20px',
    margin: '0 auto',
    padding: '1rem 1rem 0',
    width: '90%',
    marginTop: '2.5rem',
    marginBottom: '1rem',
    height: '55%',
  },
}));
