import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { IStatementPDF } from '../../shared/interfaces/istatementPDF.interface';
import { Colors } from '../../shared/theme/styles.enum';
import { formatCurrency, getLocaleDate } from '../../shared/utils/gridUtils';
import { getStatementPeriod } from '../../shared/utils/pdfUtils';
import {
  CUSTOMER_SERVICE_TITLE1,
  CUSTOMER_SERVICE_SUBTITLE1,
  CUSTOMER_SERVICE_TITLE2,
  CUSTOMER_SERVICE_TITLE3,
  CUSTOMER_SERVICE_SUBTITLE3_1,
  CUSTOMER_SERVICE_SUBTITLE3_3,
  DISCLOSURES_TITLE1,
  DISCLOSURES_PARAGRAPH1,
  DISCLOSURES_TITLE2,
  DISCLOSURES_PARAGRAPH2,
  CUSTOMER_SERVICE_SUBTITLE2,
  CUSTOMER_SERVICE_SUBTITLE3_2,
} from '../../shared/utils/textConstants';

interface IStatementPDFProps {
  statements: IStatementPDF[];
}

Font.register({
  family: 'Josefin Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/josefinsans/v23/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXMFrLgTsQV0.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/josefinsans/v23/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/josefinsans/v23/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf',
      fontWeight: 700,
    },
  ],
});

const COL1_WIDTH = 28;
const COLN_WIDTH = 14.5;
const SUB_COL1_WIDTH = 25;
const SUB_COL2_WIDTH = 30;
const SUB_COLN_WIDTH = 15;

const styles = StyleSheet.create({
  container: {
    padding: '30px 35px',
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontFamily: 'Josefin Sans',
    fontSize: 40,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  subdomain: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  subtitles: {
    display: 'flex',
    margin: '10px 0px 25px 0px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subtitle1: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
    marginRight: 20,
  },
  subtitle2: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
    marginRight: 20,
  },
  table: {
    width: 'auto',
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
    marginBottom: 25,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  subTableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
    backgroundColor: Colors.TableHover,
    margin: '10px 0px 5px 0px',
    padding: '5px 0px 5px 0px',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
    backgroundColor: Colors.TableHover,
    margin: '10px 0px 5px 0px',
    padding: '5px 0px 5px 0px',
  },
  tableCol1SubHeader: {
    width: SUB_COL1_WIDTH + '%',
    backgroundColor: Colors.TableHover,
    margin: '10px 0px 5px 0px',
    padding: '5px 0px 5px 0px',
  },
  tableCol2SubHeader: {
    width: SUB_COL2_WIDTH + '%',
    backgroundColor: Colors.TableHover,
    margin: '10px 0px 5px 0px',
    padding: '5px 0px 5px 0px',
  },
  tableColSubHeader: {
    width: SUB_COLN_WIDTH + '%',
    backgroundColor: Colors.TableHover,
    margin: '10px 0px 5px 0px',
    padding: '5px 0px 5px 0px',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableColSub1: {
    width: SUB_COL1_WIDTH + '%',
  },
  tableColSub2: {
    width: SUB_COL2_WIDTH + '%',
  },
  tableColSub: {
    width: SUB_COLN_WIDTH + '%',
  },
  tableCellHeader: {
    margin: 'auto 5px auto 0px',
    fontSize: 9,
    fontWeight: 500,
  },
  tableCell: {
    margin: '10px 5px 10px 2px',
    fontSize: 8.5,
  },
  emptyTable: {
    width: 'auto',
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
    marginBottom: 25,
    alignItems: 'center',
  },
  customerContainer: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 1.5,
    textAlign: 'left',
    marginBottom: 25,
  },
  disclosuresContainer: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 1.5,
    textAlign: 'left',
    marginTop: 30,
  },
  pageHeader: {
    display: 'flex',
    backgroundColor: Colors.TableHover,
    width: '100%',
    padding: '5px 10px',
    margin: '10px 0px',
  },
  titleHeader: {
    fontFamily: 'Poppins',
    fontSize: 11.5,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  headerContainer: {
    padding: '0.5px 1px',
  },
  divider: {
    position: 'absolute',
    marginBottom: 5,
    borderBottomColor: 'solid 0.1px #5b6464',
    borderBottomWidth: 1,
    opacity: 0.1,
    bottom: 30,
    left: 35,
    right: 35,
  },
  footnote: {
    position: 'absolute',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'left',
    bottom: 15,
    left: 35,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    right: 35,
  },
  bold: {
    fontWeight: 700,
  },
  space: {
    marginBottom: 10,
  },
});

const StatementPDF = (props: IStatementPDFProps) => {
  const { statements } = props;
  const customerServiceAddress = CUSTOMER_SERVICE_SUBTITLE2;
  const customerServiceErrors = CUSTOMER_SERVICE_SUBTITLE3_2;

  const getFormattedAPY = (apy: string): string => {
    return parseFloat(apy).toFixed(2)
  }

  const filteredStatements = statements.filter(
    (statement: IStatementPDF) =>
      statement.transactions.length > 0 ||
      (statement.begginingBalance !== "0" || statement.endingBalance !== "0")
  );

  return (
    <Document
      title={
        getStatementPeriod(statements[0].startPeriodDate.toString()) +
        " Statement"
      }
    >
      <Page size="A4" style={styles.container}>
        <View>
          <View style={styles.titles}>
            <Text style={styles.title}>{statements[0].accountName}</Text>
            <Text style={styles.subdomain}>
              {statements[0].subdomain}.ifinetwork.com
            </Text>
          </View>
          <View style={styles.subtitles}>
            <View>
              <Text style={styles.subtitle1}>Account:</Text>
              <Text style={styles.subtitle2}>{statements[0].accountId}</Text>
            </View>
            <View>
              <Text style={styles.subtitle1}>Statement Period:</Text>
              <Text style={styles.subtitle2}>
                {getStatementPeriod(statements[0].startPeriodDate.toString())}
              </Text>
            </View>
            <View>
              <Text style={styles.subtitle1}>Advisor:</Text>
              <Text style={styles.subtitle2}>{statements[0].advisorName}</Text>
            </View>
          </View>
          {statements.length > 0 ? (
            <View>
              {filteredStatements.map((row, index) => (
                <View style={styles.table} wrap={false}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1Header}>
                      <Text style={styles.tableCellHeader}>
                        &nbsp;&nbsp;&nbsp;Products
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Beginning{"\n"}Balance *
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Net{"\n"}Transactions
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Ending{"\n"}Balance
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Net Accrued{"\n"}Interest **
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        APY{"\n"}this period
                      </Text>
                    </View>
                  </View>
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>
                        {row.productName} - {row.bankName}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        ${formatCurrency(row.begginingBalance)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        ${formatCurrency(row.netTransaction)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.bold]}>
                        ${formatCurrency(row.endingBalance)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        ${formatCurrency(row.netAccruedInterest)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {row.apyPeriod === "0" || isNaN(Number(row.apyPeriod))
                          ? "- "
                          : getFormattedAPY(row.apyPeriod)}
                        %
                      </Text>
                    </View>
                  </View>
                  {row.transactions.length > 0 && (
                    <View break={true}>
                      <View style={styles.subTableRow}>
                        <View style={styles.tableCol1SubHeader}>
                          <Text style={styles.tableCellHeader}>
                            &nbsp;&nbsp;&nbsp;Date
                          </Text>
                        </View>
                        <View style={styles.tableCol2SubHeader}>
                          <Text style={styles.tableCellHeader}>
                            Description
                          </Text>
                        </View>
                        <View style={styles.tableColSubHeader}>
                          <Text style={styles.tableCellHeader}>Amount</Text>
                        </View>
                      </View>
                      {row.transactions.map((transaction, index) => (
                        <View key={index} style={styles.subTableRow}>
                          <View style={styles.tableColSub1}>
                            <Text style={styles.tableCell}>
                              {getLocaleDate(transaction.postedDate)}
                            </Text>
                          </View>
                          <View style={styles.tableColSub2}>
                            <Text style={styles.tableCell}>
                              {transaction.type.type}
                            </Text>
                          </View>
                          <View style={styles.tableColSub}>
                            <Text style={styles.tableCell}>
                              ${formatCurrency(transaction.amount)}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                  {row.allocationHistory.length > 0 && (
                    <View wrap={false}>
                      <View style={styles.subTableRow}>
                        <View style={styles.tableCol1SubHeader}>
                          <Text style={styles.tableCellHeader}>
                            &nbsp;&nbsp;&nbsp;Bank Name
                          </Text>
                        </View>
                        <View style={styles.tableCol2SubHeader}>
                          <Text style={styles.tableCellHeader}>Federal ID</Text>
                        </View>
                        <View style={styles.tableColSubHeader}>
                          <Text style={styles.tableCellHeader}>Amount</Text>
                        </View>
                      </View>
                      {row.allocationHistory.map((historyItem, index) => (
                        <View key={index} style={styles.subTableRow}>
                          <View style={styles.tableColSub1}>
                            <Text style={styles.tableCell}>
                              {historyItem.bank.name}
                            </Text>
                          </View>
                          <View style={styles.tableColSub2}>
                            <Text style={styles.tableCell}>
                              {historyItem.bank.fdic}
                            </Text>
                          </View>
                          <View style={styles.tableColSub}>
                            <Text style={styles.tableCell}>
                              ${formatCurrency(historyItem.amount)}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              ))}
            </View>
          ) : (
            <View>
              <View style={styles.table} wrap={false}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1Header}>
                    <Text style={styles.tableCellHeader}>
                      &nbsp;&nbsp;&nbsp;Products
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Beginning{"\n"}Balance *
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Net{"\n"}Transactions
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Net Accrued{"\n"}Interest **
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Ending{"\n"}Balance
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      APY{"\n"}this period
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.emptyTable}>
                <Text>No rows to display</Text>
              </View>
            </View>
          )}
          <View style={styles.customerContainer} wrap={false}>
            <View style={styles.pageHeader}>
              <View style={styles.headerContainer}>
                <Text style={styles.titleHeader}>CUSTOMER SERVICE</Text>
              </View>
            </View>
            <Text style={styles.bold}>{CUSTOMER_SERVICE_TITLE1}</Text>
            <Text style={styles.space}>{CUSTOMER_SERVICE_SUBTITLE1}</Text>
            <Text style={styles.bold}>{CUSTOMER_SERVICE_TITLE2}</Text>
            {customerServiceAddress.map((paragraph, index) => (
              <Text key={index}>{paragraph}</Text>
            ))}
            <Text style={styles.space} />
            <Text style={styles.bold}>{CUSTOMER_SERVICE_TITLE3}</Text>
            <Text style={styles.space}>{CUSTOMER_SERVICE_SUBTITLE3_1}</Text>
            <Text style={styles.space} break />
            {customerServiceErrors.map((paragraph, index) => (
              <Text key={index}>{paragraph}</Text>
            ))}
            <Text style={styles.space} />
            <Text style={styles.space}>{CUSTOMER_SERVICE_SUBTITLE3_3}</Text>
          </View>
          <View style={styles.disclosuresContainer} wrap={false}>
            <View style={styles.pageHeader}>
              <View style={styles.headerContainer}>
                <Text style={styles.titleHeader}>DISCLOSURES</Text>
              </View>
            </View>
            <Text style={styles.space}>
              *<Text style={styles.bold}>{DISCLOSURES_TITLE1}</Text>
              {DISCLOSURES_PARAGRAPH1}
            </Text>
            <Text style={styles.space}>
              **
              <Text style={styles.bold}>{DISCLOSURES_TITLE2}</Text>
              {DISCLOSURES_PARAGRAPH2}
            </Text>
          </View>
          {/* <Text style={styles.divider} fixed />
          <Text style={styles.footnote} fixed>
            powered by www.ifinetwork.com
          </Text> */}
        </View>
      </Page>
    </Document>
  );
};

export default StatementPDF;
