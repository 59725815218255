import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  ButtonGroup,
  InputBase,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './tableHeader.styles';
import DatePickerCalendar from '../datePickerCalendar/datePickerCalendar';
import ExportIcon from '../../shared/icons/exportIcon';
import { Typography as Typography2 } from '../../shared/theme/styles.enum';
import { ITabItem } from '../../shared/interfaces/itabItem.interface';
interface IButtonItem {
  icon?: any;
  label: string;
  onClick?: () => void;
  style: string;
}

interface ITableHeaderProps {
  title?: string;
  filter?: boolean;
  buttonItems?: IButtonItem[];
  tabItems?: ITabItem[];
  setDateRange?: Dispatch<SetStateAction<string[]>>;
  yearMonthOnly?: boolean | undefined;
  defaultStart?: moment.Moment;
  defaultEnd?: moment.Moment;
}

const TableHeader = (props: ITableHeaderProps) => {
  const {
    title,
    filter,
    buttonItems,
    tabItems,
    setDateRange,
    yearMonthOnly,
    defaultStart,
    defaultEnd,
  } = props;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.titleRow}>
        {title && (
          <div className={classes.titleHeader}>
            <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
          </div>
        )}
        <div className={classes.rowItems}>
          {filter && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          )}
          {setDateRange && (
            <DatePickerCalendar
              defaultEnd={defaultEnd}
              defaultStart={defaultStart}
              yearMonthOnly={yearMonthOnly}
              setDateRange={setDateRange}
            />
          )}
          {buttonItems && (
            <ButtonGroup>
              {buttonItems?.map(({ icon, label, onClick, style }, index) => (
                <Button
                  className={style}
                  key={index}
                  // startIcon={icon && <img src={icon} alt="button icon" />}
                  startIcon={
                    icon && (
                      <ExportIcon
                        alt="button icon"
                        maincolor={theme.palette.primary.main}
                        size={Typography2.BasicText}
                      />
                    )
                  }
                  onClick={onClick}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </div>
      </div>

      {tabItems && (
        <Tabs
          className={classes.tabsRow}
          value={value}
          indicatorColor="primary"
          scrollButtons="auto"
          variant="fullWidth"
          onChange={handleChange}
        >
          {tabItems?.map(({ label }, index) => (
            <Tab
              className={classes.tab}
              key={index}
              label={label}
              {...tabProps(index)}
            />
          ))}
        </Tabs>
      )}
    </>
  );
};

export default TableHeader;
