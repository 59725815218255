import { useQuery } from '@apollo/client';
import MarketingCard from '../../../components/card/marketingCard';
import Loading from '../../../components/loading/loading';
import { GET_MARKETING_MATERIALS } from './marketing.service';
import { convertDateFormat } from '../../../shared/utils/gridUtils';
import { useStyles } from './marketingMaterialDetails.styles';
import TableHeader from '../../../components/tableHeader/tableHeader';
import { IMarketingWrapper } from '../../../shared/interfaces/imarketingMaterials.interface';
import { MaterialType } from '../../../shared/interfaces/marketingMaterials.enum';
import { Grid } from '@material-ui/core';

const MarketingMaterialDetails = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_MARKETING_MATERIALS);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading isLoading />
      </div>
    );

  if (error) return null;

  const marketingWrapper: IMarketingWrapper[] = data?.allMarketingMaterials;
  const marketingMaterials = marketingWrapper.filter(
    (m) => m.type.id === MaterialType.MarketingMaterial.toString()
  )[0].materials;
  const articles = marketingWrapper.filter(
    (m) => m.type.id === MaterialType.Article.toString()
  )[0].materials;

  return (
    <div className={classes.container}>
      <Grid container className={classes.grid} spacing={4}>
        {marketingMaterials.length > 0 ? (
          marketingMaterials.map((item, index) => (
            <Grid container item sm={12} md={12} lg={6} direction="column">
              <MarketingCard
                key={index}
                date={convertDateFormat(item.date)}
                title={item.title}
                url={item.url}
                isMarketing={true}
              />
            </Grid>
          ))
        ) : (
          <div className={classes.container}>
            <h4 className={classes.noAvailable}>
              No Marketing Materials Available
            </h4>
          </div>
        )}
      </Grid>
      <TableHeader title="News articles" />
      <Grid container className={classes.grid} spacing={4}>
        {articles.length > 0 ? (
          articles.map((item, index) => (
            <Grid container item sm={12} md={12} lg={6} direction="column">
              <MarketingCard
                key={index}
                date={convertDateFormat(item.date)}
                title={item.title}
                url={item.url}
                isMarketing={false}
              />
            </Grid>
          ))
        ) : (
          <div className={classes.container}>
            <h4 className={classes.noAvailable}>No Articles Available</h4>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default MarketingMaterialDetails;
