import { gql } from '@apollo/client';

export const GET_ADVISOR_FIRM_DROPDOWN = gql`
  query AdvisorFirmDropdown {
    allAdvisorFirms {
      companyName
      id
    }
  }
`;

export const UPDATE_SUPPORT_ADMIN_FIRM = gql`
  mutation UpdateSupportAdminFirm($whereAdvisorFirm: WhereAdvisorFirm!) {
    updateSupportAdminFirm(whereAdvisorFirm: $whereAdvisorFirm) {
      email
      advisorFirmId
    }
  }
`;
