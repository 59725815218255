import { gql } from '@apollo/client';

export interface ICreateLinkTokenRes {
  createLinkToken: {
    success: boolean;
    message: string;
    linkToken: string;
  };
}

export const CREATE_LINK_TOKEN = gql`
  mutation CreateLinkToken {
    createLinkToken {
      success
      message
      linkToken
    }
  }
`;

export interface IExchangePublicTokenRes {
  exchangePublicToken: {
    success: boolean;
    message: string;
  };
}

export const EXCHANGE_PUBLIC_TOKEN = gql`
  mutation ExchangePublicToken($publicToken: String!, $hildaId: BigInt!) {
    exchangePublicToken(publicToken: $publicToken, hildaId: $hildaId) {
      success
      message
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query BankAccounts($hildaId: BigInt!) {
    bankAccounts(hildaId: $hildaId) {
      hildaId
      accountRef
      plaidBankId
      plaidBankName
      plaidAccountName
      plaidAccountOfficialName
      plaidAccountSubtype
      plaidAccountType
    }
  }
`;
