import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, Typography } from "../../shared/theme/styles.enum";

export const useStyles = (overwriteEllipis: boolean = false) =>
  makeStyles((theme: Theme) =>
    createStyles({
      textField: {
        width: "auto",
        margin: "0px 40px 0px 40px",
        fontSize: Typography.H6,
        lineHeight: 1.5,
        letterSpacing: 0,
        textAlign: "left",
        border: "none !important",
        color: Colors.BorderAndBG,
        "& .MuiFilledInput-input": {
          padding: "15px 20px",
          fontFamily: Typography.FontFamily2,
        },
        "& .MuiInputBase-formControl": {
          width: "auto",
        },
      },
      select: {
        color: Colors.TextGray,
      },
      menuItem: {
        width: overwriteEllipis ? "100%" : "520px",
        fontFamily: Typography.FontFamily2,
        color: Colors.TextGray,
        padding: "10px 20px",
        border: "0.8px solid #f1f1f1",
        "& .MuiList-padding": {
          padding: "0px !important",
          backgroundColor: "red",
        },
      },
    })
  );
