import { useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { EXCHANGE_PUBLIC_TOKEN } from "./plaidLink.service";
import { triggerAlert } from "../toast/toast";
import { ERROR } from "../../shared/utils/textConstants";
import { useHistory } from "react-router-dom";
import { LoadingModalContext } from "../loadingModal/loadingModalContext";
import * as Sentry from "@sentry/react";

const PlaidLinkComponent = () => {
  const [exchangePublicToken] = useMutation(EXCHANGE_PUBLIC_TOKEN);
  const history = useHistory();

  const { setOpenModal } = useContext(LoadingModalContext);

  const onSuccess = useCallback(async (public_token, metadata) => {
    try {
      setOpenModal(true);
      const hildaAccountId = localStorage.getItem("linkBankHildaId");
      await exchangePublicToken({
        variables: { publicToken: public_token, hildaId: hildaAccountId },
      });
      history.push("/");
      setOpenModal(false);
    } catch (e) {
      Sentry.captureException(e)
      setOpenModal(false);
      console.log(e);
    }

    // eslint-disable-next-line
  }, []);

  const onExit = useCallback((err, metadata) => {
    if (err) {
      triggerAlert({
        type: "error",
        title: ERROR,
        msg: "Something went wrong initializing Plaid Connection. Please try again.",
      });
    }

    history.replace("/")
  }, []);

  const tkn = localStorage.getItem("linkToken");
  const linkToken = tkn !== null ? JSON.parse(tkn) : "";

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    receivedRedirectUri: window.location.href.includes("?oauth_state_id=")
      ? window.location.href
      : undefined,
    onSuccess,
    onExit,
    env: process.env.REACT_APP_PLAID_ENV,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (!ready) return;
    if (error) {

      triggerAlert({
        type: "error",
        title: ERROR,
        msg: "Something went wrong initializing Plaid Connection. Please try again.",
      });

      Sentry.captureException(error);
      console.error("Failed to load", error)
    }
    open();
  }, [open, ready, error]);

  return <></>;
};

export default PlaidLinkComponent;
