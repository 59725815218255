import React from 'react';
import { useStyles } from './loading.styles';

interface ILoadingProps {
    isLoading?: boolean;
  }

const Loading = ({ isLoading = false }: ILoadingProps) => {
    const classes = useStyles();
    return (
        <>
            {isLoading && 
                <section className={classes.container}>
                    <div className={classes.snippet}>
                        <div className={classes.stage}>
                            <div className={classes.dotFlashing}></div>
                        </div>
                    </div>
                </section>
            }           
        </>
    )
}

export default Loading
