import { Button, useTheme } from '@material-ui/core';
import { useState } from 'react';
import UrlCopyButtonIcon from '../../../shared/icons/urlButtonCopyIcon';
import { Colors } from '../../../shared/theme/styles.enum';
import { COPIED_URL, PERSONAL_URL } from '../../../shared/utils/textConstants';
import { useStyles } from './urlCopyButton.styles';

const UrlCopyButton = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [status, setStatus] = useState<Boolean>(true);

  const handleClick = () => {
    const currentUrl = window.location.hostname;
    clipboardCopy(currentUrl);
    setStatus(false);
  };

  const clipboardCopy = async (input: string) => {
    await navigator.clipboard.writeText(input);
  };

  return (
    <div className={classes.container}>
      {status ? (
        <Button
          className={classes.greenButton}
          startIcon={
            <UrlCopyButtonIcon
              alt="url copy button"
              maincolor={theme.palette.primary.main}
              size={28}
            />
          }
          onClick={handleClick}
        >
          {PERSONAL_URL}
        </Button>
      ) : (
        <Button
          className={classes.redButton}
          startIcon={
            <UrlCopyButtonIcon
              alt="url copy button"
              maincolor={Colors.Red}
              size={28}
            />
          }
        >
          {COPIED_URL}
        </Button>
      )}
    </div>
  );
};

export default UrlCopyButton;
