import { gql } from '@apollo/client';

export const SUBMIT_PENDING_ADVISOR = gql`
  mutation Mutation(
    $onboardingForm: OnboardingForm!
    $userIds: UserIdsInput!
    $hildaId: BigInt!
  ) {
    submitPendingHildaForAdvisor(
      onboardingForm: $onboardingForm
      userIds: $userIds
      hildaId: $hildaId
    ) {
      success
      message
    }
  }
`;
