import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      margin: '21px 25px 16px 24px',
      padding: '5px 9.6px 5px 12px',
      borderRadius: 3,
      border: '1px solid',
      borderColor: Colors.BorderAndBG,
    },
    input: {
      opacity: 0.53,
      width: '100%',
      fontFamily: Typography.FontFamily2,
      fontSize: '0.9375em',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.93,
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    searchIcon: {
      margin: '4px 10px 1px 0',
    },
    clearIcon: {
      margin: '1px 0 1.4px 0px',
      objectFit: 'contain',
      cursor: 'pointer',
    },
  })
);
