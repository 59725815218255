import { gql } from '@apollo/client';
import { restWrapper } from '../../shared/utils/restWrapper';

export const CREATE_ACCOUNT_UPLOAD = gql`
  mutation CreateAccountUpload(
    $createAccountUploadInput: CreateAccountUploadInput!
  ) {
    createAccountUpload(createAccountUploadInput: $createAccountUploadInput) {
      url
    }
  }
`;

export const uploadFileToBucket = async (file: File, folder: string) => {
  const url = process.env.REACT_APP_SERVERLESS + '/uploadFile';
  const params = `?type=file&folder=${folder}`;
  const formData = new FormData();
  formData.append('file', file);
  const res = await restWrapper.IfiFetch({
    url,
    method: 'POST',
    data: formData,
    params,
  });
  return res;
};

export const uploadLogoToBucket = async (file: File) => {
  const url = process.env.REACT_APP_SERVERLESS + '/uploadFile';
  const params = '?type=logo';
  const formData = new FormData();
  formData.append('file', file);
  const res = await restWrapper.IfiFetch({
    url,
    method: 'POST',
    data: formData,
    params,
  });
  return res;
};
