import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DRAG_AND_DROP_HERE,
  ACCOUNT_PROCESS,
  UPLOAD_SUCCESS_MSG,
  UPLOAD_ERROR_MSG,
  DEFAULT_UPLOAD_COMMENT,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
  UPLOAD_WARNING,
  UPLOAD_TOO_BIG,
} from '../../shared/utils/textConstants';
import { useStyles } from './uploadFile.styles';
import { errase, uploadFile } from '../../shared/utils/pathConstants';
import { useMutation } from '@apollo/client';
import {
  CREATE_ACCOUNT_UPLOAD,
  uploadFileToBucket,
} from './uploadFile.service';
import { triggerAlert } from '../toast/toast';
import { GET_ACCOUNTS_UPLOAD_BY_ADVISOR_FIRM } from '../../views/accountsLoad/accountsLoadTable/accountsLoadTable.service';
import Loading from '../loading/loading';
import SearchFolderIcon from '../../shared/icons/searchFolderIcon';
import * as Sentry from "@sentry/react";

interface IUploadFileProps {
  isMicro?: boolean;
  folderFile?: string; // Only needed for normal mode. Micro mode handles upload from outside.
  handleSetFileToUpload?: (file: File) => void;
  isLogo?: boolean;
  setLogo?: () => void;
  handleSetLogoFile?: (file: File) => void;
  defaultLogo?: string;
  logoSubtitle?: string;
}

const MAX_BYTE_SIZE = 9000000;

const UploadFile = (props: IUploadFileProps) => {
  const {
    isMicro,
    folderFile,
    handleSetFileToUpload,
    isLogo,
    handleSetLogoFile,
    defaultLogo,
    logoSubtitle,
  } = props;

  const [loading, setLoading] = useState(false);
  const [createAccountUpload] = useMutation(CREATE_ACCOUNT_UPLOAD, {
    refetchQueries: [
      GET_ACCOUNTS_UPLOAD_BY_ADVISOR_FIRM, // DocumentNode object parsed with gql
      'AccountUploadsByCurrentAdvisorFirm', // Query name
    ],
  });
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (isMicro) handleSelectFileForMicro();
    // eslint-disable-next-line
  }, [myFiles]);

  useEffect(() => {
    if (isLogo) {
      setLogoImage();
      handleSelectLogoFile();
    }
    // eslint-disable-next-line
  }, [myFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  const acceptStyle = {
    backgroundColor: 'rgba(63, 167, 149, 0.31)',
  };
  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
    }),
    // eslint-disable-next-line
    [isDragAccept]
  );

  const file = myFiles.map((item) => <p key={item.name}>{item.name}</p>);

  const removeFiles = () => {
    setMyFiles([]);
  };

  const handleUploadFile: any = async () => {
    setLoading(true);
    const file = myFiles[0];
    if (file.size > MAX_BYTE_SIZE) {
      triggerAlert({
        type: 'warning',
        title: UPLOAD_WARNING,
        msg: UPLOAD_TOO_BIG,
      });
      setLoading(false);
      removeFiles();
    } else {
      try {
        // Upload to bucket
        const bucketRes =
          folderFile && (await uploadFileToBucket(file, folderFile));
        // Then, store reference in Api
        await createAccountUpload({
          variables: {
            createAccountUploadInput: {
              url: bucketRes.uploadUrl,
              comments: DEFAULT_UPLOAD_COMMENT,
            },
          },
        });

        triggerAlert({
          type: 'success',
          title: UPLOAD_SUCCESS,
          msg: UPLOAD_SUCCESS_MSG,
        });
      } catch (e) {
        Sentry.captureException(e,{
          extra:{
            variables:JSON.stringify({reateAccountUploadInput: {
              comments: DEFAULT_UPLOAD_COMMENT,
            }})
          }
        })
        console.error(e);
        triggerAlert({
          type: 'error',
          title: UPLOAD_FAILURE,
          msg: UPLOAD_ERROR_MSG,
        });
      }
      setLoading(false);
      removeFiles();
    }
  };

  const handleSelectFileForMicro: any = async () => {
    const file = myFiles[0];
    if (file) {
      if (file.size > MAX_BYTE_SIZE) {
        triggerAlert({
          type: 'warning',
          title: UPLOAD_WARNING,
          msg: UPLOAD_TOO_BIG,
        });
        setLoading(false);
        removeFiles();
      }
      handleSetFileToUpload && handleSetFileToUpload(file);
    }
  };

  const handleSelectLogoFile: any = async () => {
    const file = myFiles[0];
    if (file) {
      if (file.size > MAX_BYTE_SIZE) {
        triggerAlert({
          type: 'warning',
          title: UPLOAD_WARNING,
          msg: UPLOAD_TOO_BIG,
        });
        setLoading(false);
        removeFiles();
      }
      handleSetLogoFile && handleSetLogoFile(file);
    }
  };

  const openForMicro = () => {
    removeFiles();
    open();
  };

  const openForLogo = () => {
    removeFiles();
    open();
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const setLogoImage = async () => {
    if (myFiles[0]) {
      const image: any = await toBase64(myFiles[0]);
      const logoImg = document.getElementById('advisorFirmLogo');
      logoImg?.setAttribute('src', image);
    }
  };

  return (
    <>
      {isMicro ? (
        <div className={classes.fileNameContainer}>
          <input {...getInputProps()} />
          <Button
            startIcon={<img src={uploadFile} alt="upload icon" />}
            id="trustDocumentURL"
            name="trustDocumentURL"
            className={classes.uploadButtonWorkflow}
            type="button"
            onClick={openForMicro}
          >
            Upload File
          </Button>
          {myFiles.length !== 0 && (
            <p className={classes.uploadedFileText}>{myFiles[0].name}</p>
          )}
        </div>
      ) : isLogo ? (
        <div className={classes.fileNameContainer}>
          <input {...getInputProps()} />
          <div className={classes.logoButtonContainer}>
            <Button
              startIcon={<img src={uploadFile} alt="upload icon" />}
              id="logoImg"
              name="logoImg"
              className={classes.uploadLogoButton}
              type="button"
              onClick={openForLogo}
            >
              Upload Logo
            </Button>
            <Typography className={classes.logoSizetext}>
              {logoSubtitle}
            </Typography>
          </div>
          {myFiles.length !== 0 ? (
            <>
              <img
                src=""
                id="advisorFirmLogo"
                alt="Advisor Firm Logo"
                width="350px"
                height="75px"
                className={classes.logoImage}
              />
              <div className={classes.deleteButton}>
                <IconButton
                  id="deleteImg"
                  name="deleteImg"
                  onClick={removeFiles}
                >
                  <img src={errase} alt="delete icon" />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              {defaultLogo && (
                <img
                  src={defaultLogo}
                  id="advisorFirmLogo"
                  alt="Advisor Firm Logo"
                  width="350px"
                  height="75px"
                  className={classes.logoImage}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className={classes.container} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <SearchFolderIcon
              alt="upload file icon"
              className={classes.folderIcon}
            />
            {file.length === 0 ? (
              <Button
                className={classes.uploadButton}
                type="button"
                onClick={open}
                variant="contained"
              >
                Browse File
              </Button>
            ) : (
              <Button
                startIcon={<img src={uploadFile} alt="upload icon url" />}
                className={classes.uploadButton}
                type="button"
                onClick={handleUploadFile}
              >
                Upload File
              </Button>
            )}
            {loading ? (
              <>
                <Box className={classes.progressBar} sx={{ width: '30%' }}>
                  <Loading isLoading={loading} />
                </Box>
              </>
            ) : (
              ''
            )}
            {file.length > 0 ? (
              <p className={classes.subtext}>{file}</p>
            ) : (
              <p className={classes.subtext}>{DRAG_AND_DROP_HERE}</p>
            )}
            <p className={classes.maintext}>{ACCOUNT_PROCESS}</p>
          </div>
        </>
      )}
    </>
  );
};

export default UploadFile;
