import { useQuery } from "@apollo/client";
import { Auth, Hub } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { IHildaResult } from "../interfaces/ihildaResult.interface";
import { IUser } from "../interfaces/iuser.interface";
import { GlobalMessageContext } from "../context/globalMessage.context";

import useHostname from "./useHostname";
import { defaultUserData, IUserData } from "../context/userContext";
import { GET_CURRENT_USER } from "../context/userContext.service";
import * as Sentry from "@sentry/react";

const useUserData = () => {
  const { showMessage, closeMessage } = useContext(GlobalMessageContext);
  const [dataState, setDataState] = useState<any>(null);
  const [userData, setUserData] = useState<IUserData>(defaultUserData);

  const { data, loading, error } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  const setQueryDataInState = (data: any) => {
    if (!data) return;
    setDataState(data);
  };

  useEffect(() => {
    Sentry.setUser({ email: userData?.user?.email || undefined });
  }, [userData]);

  const setUserDataIfAuthenticated = async (
    data: any,
    reRoute: any,
    token: string
  ) => {
    if (data === null || data === undefined) {
      return;
    }

    const { advisorFirmId, ...user }: IUser = data.currentUser;
    const hildaResults: IHildaResult[] = data.accountsByHildaStatus;
    const logo = data?.advisorFirmConfig?.logo || "";
    const subDomainName = data?.advisorFirmConfig?.subDomainName || null;
    const companyName = data?.advisorFirm?.companyName || "";

    await changeHostname(subDomainName);

    setUserData((previousState) => {
      return {
        ...previousState,
        user: {
          ...user,
          advisorFirmId: advisorFirmId ? advisorFirmId : "0",
        },
        authState: "signedIn",
        reRoute: reRoute ? reRoute : "/",
        accountsSearch: {
          ...previousState.accountsSearch,
          accountList: hildaResults,
        },
        logo,
        companyName,
        subDomainName,
      };
    });
  };

  const getUser = async (dataState: any, reRoute?: any) => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (!authenticatedUser) {
        setUserData({ ...defaultUserData, authState: "signedOut" });
        setDataState(null);
      } else {
        await setUserDataIfAuthenticated(
          dataState,
          reRoute,
          authenticatedUser.signInUserSession.idToken.jwtToken
        );
      }
    } catch (e) {
      Sentry.captureException(e)
      setUserData({ ...defaultUserData, authState: "signedOut" });
      console.warn(e);
    }
  };
  const { changeHostname } = useHostname(
    data?.advisorFirmConfig?.subDomainName
  );

  useEffect(() => {
    if (userData.authState === "loading") {
      showMessage("Loading your information");
    } else {
      closeMessage();
    }
  }, [userData, showMessage, closeMessage]);

  useEffect(() => {
    setQueryDataInState(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !loading && !error) setQueryDataInState(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getUser(dataState);
    // eslint-disable-next-line
  }, [dataState]);

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            await getUser(dataState);
            break;
          case "signout":
          case "oAuthSignOut":
            setUserData({ ...defaultUserData, authState: "signedOut" });
            break;
          case "signIn_failure":
            console.warn("Failed to sign In");
            break;
          case "customOAuthState":
            if (data !== undefined) {
              await getUser(dataState, data);
            }
            await getUser(dataState);
            break;
        }
      }
    );
    return () => {
      hubListenerCancelToken();
    };
  }, [dataState, getUser]);

  return { userData, setUserData };
};

export default useUserData;
