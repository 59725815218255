import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { DatePickerOverrideCss, useStyles } from "./datePickerCalendar.styles";
import moment from "moment";
import DateIcon from "../../shared/icons/dateIcon";
import { Button, useTheme } from "@material-ui/core";
import { Typography } from "../../shared/theme/styles.enum";

interface IProps {
  setDateRange: Dispatch<SetStateAction<string[]>>;
  yearMonthOnly?: boolean | undefined;
  defaultStart?: moment.Moment;
  defaultEnd?: moment.Moment;
}

const DatePickerCalendar = (props: IProps) => {
  const { setDateRange, yearMonthOnly, defaultStart, defaultEnd } = props;
  const classes = useStyles();
  const [startSelectedDate, setStartSelectedDate] = useState<any>(
    defaultStart || null
  );
  const [endSelectedDate, setEndSelectedDate] = useState<any>(
    defaultEnd || null
  );
  const theme = useTheme();

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      if (
        startSelectedDate.format("YYYY-MM-DD") >=
        endSelectedDate.format("YYYY-MM-DD")
      ) {
        setEndSelectedDate(
          moment(startSelectedDate, "YYYY-MM-DD").add(1, "days")
        );
      }
    }
    // eslint-disable-next-line
  }, [startSelectedDate]);

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      if (
        startSelectedDate.format("YYYY-MM-DD") >=
        endSelectedDate.format("YYYY-MM-DD")
      ) {
        setStartSelectedDate(moment(endSelectedDate).subtract(1, "days"));
      }
    }
    // eslint-disable-next-line
  }, [endSelectedDate]);

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      setDateRange([
        startSelectedDate.format("YYYY-MM-DD"),
        endSelectedDate.format("YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line
  }, [startSelectedDate, endSelectedDate]);

  const handleStartDate = (event: any) => {
    if (yearMonthOnly) {
      const startOfMonth = moment(event).startOf("month");
      setStartSelectedDate(startOfMonth);
    } else {
      setStartSelectedDate(moment(event));
    }
  };

  const handleEndDate = (event: any) => {
    if (yearMonthOnly) {
      const endOfMonth = moment(event).endOf("month");
      setEndSelectedDate(endOfMonth);
    } else {
      setEndSelectedDate(moment(event));
    }
  };

  const handleClear = () => {
    setStartSelectedDate(defaultStart || null);
    setEndSelectedDate(defaultEnd || null);
    setDateRange([]);
  };
  return (
    <>
      <Button className={classes.clear} onClick={handleClear}>
        Clear
      </Button>
      <div className={classes.datePickerContainer}>
        <DatePickerOverrideCss />
        <DateIcon
          alt="Calendar icon"
          className={classes.calendarIcon}
          maincolor={theme.palette.primary.main}
          size={Typography.BasicText}
        />
        <DatePicker
          views={yearMonthOnly ? ["year", "month"] : ["year", "month", "date"]}
          emptyLabel={yearMonthOnly ? "MM/YYYY" : "MM/DD/YYYY"}
          value={startSelectedDate}
          format={yearMonthOnly ? "MMM, yyyy" : "MMM, dd yyyy"}
          onChange={handleStartDate}
          disableToolbar={true}
          variant={"inline"}
          autoOk={true}
          PopoverProps={{
            anchorOrigin: { horizontal: "left", vertical: "bottom" },
            transformOrigin: { horizontal: "left", vertical: "top" },
          }}
        />
        <div className={classes.mayorque}> &gt; </div>
        <DatePicker
          views={yearMonthOnly ? ["year", "month"] : ["year", "month", "date"]}
          emptyLabel={yearMonthOnly ? "MM/YYYY" : "MM/DD/YYYY"}
          value={endSelectedDate}
          format={yearMonthOnly ? "MMM, yyyy" : "MMM, dd yyyy"}
          onChange={handleEndDate}
          disableToolbar={true}
          variant={"inline"}
          autoOk={true}
          PopoverProps={{
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
            transformOrigin: { horizontal: "right", vertical: "top" },
          }}
        />
      </div>
    </>
  );
};

export default DatePickerCalendar;
