import { gql } from '@apollo/client';

export const GET_ASSETS = gql`
  query AssetsSummary {
    deposits {
      totalAmount
      type
      assets {
        amount
        type
      }
    }
  }
`;
