import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      width: 'max-content',
    },
    menuText: {
      fontSize: '1.25rem',
      fontFamily: Typography.FontFamily2,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    menu: {
      color: Colors.TextGray,
      textDecoration: 'none',
    },
    menuActive: {
      color: `${Colors.White} !important`,
      borderBottom: `4px solid ${theme.palette.primary.main} !important`,
    },
    //TABS
    containerStyle: {
      width: '100%',
      marginTop: -50,
      '& .Mui-selected': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& .MuiTab-root': {
        padding: '0px 10px',
        minWidth: 'max-content',
      },
    },
    tabsRowStyle: {
      marginTop: 0,
      marginRight: 15,
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'end',
      },
    },
    childrenStyle: {
      padding: 0,
    },
  })
);
