import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh',
    },
    noResultsContainer: {
      padding: 35,
    },
    noResults: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Body,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
  })
);
