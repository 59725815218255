import { createStyles, makeStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    pageHeader: {
      display: 'flex',
      backgroundColor: Colors.TableHover,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    container: {
      padding: '1.5em',
    },
    infoContainer: {
      padding: '0.45em 1.5em 0.42em 1.5em',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontFamily: Typography.FontFamily1,
      fontSize: '2.12rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      textAlign: 'left',
      paddingLeft: '0.5rem',
      '@media (max-width: 950px)': {
        // eslint-disable-line no-useless-computed-key
        fontSize: '1.315rem',
      },
    },
    titleIconContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleIcon: {
      marginLeft: 12,
    },
    subheader: { display: 'flex', marginBottom: 6 },
    subtitle: {
      margin: '2px 6.7px 0px 6.6px',
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.Captions,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    subtitleIcon: {
      width: 10.1,
      height: 10.1,
      margin: '5px 0px 0px 8px',
    },
    menuTabs: {
      display: 'flex',
      alignItems: 'end',
      alignSelf: 'end',
      //marginTop: 30,
      //marginRight: 32,
      '& #listItem0': {
        color: `${palette.primary.main} !important`,
        borderBottom: `5px solid ${palette.primary.main} !important`,
        marginBottom: '-7px',
      },
    },
    tabsRow: {
      backgroundColor: Colors.White,
      position: 'absolute',
    },
    tab: {
      fontWeight: 600,
      color: Colors.TextGray,
    },
  })
);
