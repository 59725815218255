import { Typography } from '@material-ui/core';
import UrlCopyButton from './urlCopyButton/urlCopyButton';
import { useStyles } from './pageHeader.styles';
// import AccountPopup from './accountPopup/accountPopup';
import { advisor } from '../../shared/utils/pathConstants';
interface IPageHeaderProps {
  title: string;
  accountInfo?: { accountNumber: string; joined: string };
  advisorName?: string;
  urlButton?: boolean;
  menuTabs?: JSX.Element;
}

const PageHeader = (props: IPageHeaderProps) => {
  const { title, accountInfo, advisorName, urlButton, menuTabs } = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.pageHeader}>
        {!accountInfo ? (
          <div className={classes.container}>
            <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
          </div>
        ) : (
          <div className={classes.infoContainer}>
            <div className={classes.header}>
              <Typography className={classes.title} variant="h3">
                {title}
              </Typography>
              {/* {accountInfo && <AccountPopup {...accountInfo} />} */}
            </div>
            {advisorName && (
              <div className={classes.subheader}>
                <img
                  src={advisor}
                  className={classes.subtitleIcon}
                  alt="advisor icon"
                />
                <Typography className={classes.subtitle}>
                  Advisor: &nbsp;{advisorName}
                </Typography>
              </div>
            )}
          </div>
        )}
        {urlButton && <UrlCopyButton />}
      </div>
      {menuTabs && <div className={classes.menuTabs}>{menuTabs}</div>}
    </div>
  );
};

export default PageHeader;
