import { createStyles, makeStyles, SvgIcon, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fillPrimary: {
      fill: theme.palette.primary.main,
    },
  })
);

const SearchFolderIcon = (props: { alt: string; className: any }) => {
  const innerClasses = useStyles();
  return (
    <SvgIcon
      {...props}
      id="Search"
      xmlns="http://www.w3.org/2000/svg"
      width="133.008"
      height="105.313"
      viewBox="0 0 133.008 105.313"
      className={props.className}
      style={{ margin: 0 }}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Rectangle_61"
            data-name="Rectangle 61"
            className={innerClasses.fillPrimary}
            d="M0 0h133.008v105.313H0z"
          />
        </clipPath>
      </defs>
      <g id="file_upload">
        <g id="Group_41" data-name="Group 41">
          <g id="Group_40" data-name="Group 40">
            <path
              id="Path_170"
              data-name="Path 170"
              className={innerClasses.fillPrimary}
              d="M116.593 105.313H16.414A16.258 16.258 0 0 1 0 89.253V16.06A16.258 16.258 0 0 1 16.414 0h37.845l12.8 12.537h49.538a16.258 16.258 0 0 1 16.41 16.063v60.653a16.258 16.258 0 0 1-16.414 16.06M16.414 6.018A10.166 10.166 0 0 0 6.151 16.06v73.193A10.166 10.166 0 0 0 16.414 99.3h100.179a10.166 10.166 0 0 0 10.263-10.042V28.6a10.166 10.166 0 0 0-10.263-10.042H64.5L51.709 6.018z"
            />
          </g>
        </g>
      </g>
      <g id="search-2" data-name="search" transform="translate(38 30)">
        <path
          id="Boundary"
          transform="translate(.5)"
          style={{ fill: 'none' }}
          d="M0 0h57v57H0z"
        />
        <path
          id="_Color"
          data-name="↳Color"
          className={innerClasses.fillPrimary}
          d="M46.657 51 32.075 36.45v-2.3l-.787-.816a18.98 18.98 0 1 1 2.042-2.042l.816.787h2.3L51 46.657 46.659 51zM18.953 5.831a13.122 13.122 0 1 0 13.122 13.122A13.137 13.137 0 0 0 18.953 5.831z"
          transform="translate(3.5 3)"
        />
      </g>
    </SvgIcon>
  );
};

export default SearchFolderIcon;
