//IMAGES
export const Avalon = '../assets/images/Avalon.png';
export const logoImage = '../assets/images/Logo.svg';
export const Artboard1 = '../assets/images/Artboard 1.png';
export const graphNeutral = '../assets/images/graph-neutral.png';

// ICONS
export const accountUpload = '../assets/icons/accountUpload.svg';
export const arrowDown = '../assets/icons/arrowDown.svg';
export const clear = '../assets/icons/clear.svg';
export const coin = '../assets/icons/coin.svg';
export const error = '../assets/icons/error.svg';
export const exportCSV = '../assets/icons/export.svg';
export const file = '../assets/icons/file.svg';
export const magnifier = '../assets/icons/magnifier.svg';
export const person = '../assets/icons/person.svg';
export const search = '../assets/icons/Search.svg';
export const success = '../assets/icons/success.svg';
export const urlCopyGreen = '../assets/icons/urlCopyGreen.svg';
export const urlCopyRed = '../assets/icons/urlCopyRed.svg';
export const warning = '../assets/icons/warning.svg';
export const bank = '../assets/icons/bank.svg';
export const defaultIcon = '../assets/icons/defaultIcon.svg';
export const coins = '../assets/icons/coins.svg';
export const womenIcon = '../assets/icons/womenIcon.svg';
export const info = '../assets/icons/info.svg';
export const eye = '../assets/icons/eye.svg';
export const advisor = '../assets/icons/advisor.svg';
export const sort = '../assets/icons/sort.svg';
export const dateIcon = '../assets/icons/Date.svg';
export const addBox = '../assets/icons/addBox.svg';
export const uploadFile = '../assets/icons/uploadFile.svg';
export const link = '../assets/icons/link.svg';
export const errase = '../assets/icons/delete.svg';
export const login = '../assets/images/login.png';
export const diagonals = '../assets/images/diagonals.png';

// FONTS
export const poppinsBold = '/fonts/poppins/Poppins-Bold.ttf';

// URLs
export const serverlessPath = '';
