import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./dropDown.styles";

export interface IDropDownOption {
  value: string;
  label: string;
}
interface IDropDownProps {
  menuItems: { value: string; label: string }[];
  setValueFunc: (value: string) => void;
  ellipsisSize?: number;
}

const DropDown = (props: IDropDownProps) => {
  const { menuItems, setValueFunc, ellipsisSize } = props;
  const [item, setItem] = useState(menuItems[0]);
  const classes = useStyles(!!ellipsisSize)();

  useEffect(() => {
    // Set initial value for outside state
    setValueFunc(menuItems[0].value);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: any) => {
    const value = event.target.value;
    setItem(menuItems.filter((item) => item.value === value)[0]);
    setValueFunc(value);
  };

  const getEllipsisText = (text: string) => {
    const size = ellipsisSize || 50;
    const maxSize = size - 10;
    if (text.length > size) {
      return (
        text.substring(0, maxSize) +
        "..." +
        text.substring(text.length - 10, text.length)
      );
    }
    return text;
  };

  return (
    <FormControl className={classes.textField} variant="filled">
      <Select
        labelId="select-label"
        value={item.value}
        label={item.label}
        className={classes.select}
        IconComponent={ExpandMoreIcon}
        disableUnderline
        onChange={handleChange}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            className={classes.menuItem}
          >
            {getEllipsisText(item.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
