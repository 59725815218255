import { Button, Menu, MenuItem, Fade, Typography } from '@material-ui/core';
import { useStyles } from './logoDropDown.styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IProps {
  imageUrl: string | null;
  companyName: string;
  canChangeFirm: boolean;
  dropdownOptions: { id: string; companyName: string }[] | undefined;
  handleChange: (id: string) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  open: boolean;
  anchorEl: null | HTMLElement;
}

const LogoDropDown = (props: IProps) => {
  const {
    imageUrl,
    companyName,
    canChangeFirm,
    dropdownOptions,
    handleChange,
    handleClick,
    handleClose,
    open,
    anchorEl,
  } = props;
  const classes = useStyles({ open });

  return (
    <div className={classes.container}>
      {!canChangeFirm ? (
        <Button className={classes.logoButton} disabled>
          {imageUrl && imageUrl !== '' ? (
            <img
              className={classes.logo}
              src={imageUrl}
              alt={`${companyName} Logo`}
              width="320px"
              height="75px"
            />
          ) : (
            <Typography className={classes.logoFallbackText}>
              {companyName}
            </Typography>
          )}
        </Button>
      ) : (
        <>
          <Button
            className={classes.logoButton}
            id="fade-button"
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
          >
            {imageUrl && imageUrl !== '' ? (
              <img
                className={classes.logo}
                src={imageUrl}
                alt={`${companyName} Logo`}
                width="320px"
                height="75px"
              />
            ) : (
              <Typography className={classes.logoFallbackText}>
                {companyName}
              </Typography>
            )}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
              className: classes.itemsContainer,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {dropdownOptions &&
              dropdownOptions.map((option, index) => (
                <div key={index}>
                  <MenuItem
                    className={classes.menuItems}
                    key={index}
                    onClick={() => handleChange(option.id)}
                  >
                    <Typography
                      className={classes.menuItemFont}
                      variant="subtitle1"
                    >
                      {option.companyName}
                    </Typography>
                  </MenuItem>
                  <hr className={classes.divider} />
                </div>
              ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default LogoDropDown;
