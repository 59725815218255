import { useQuery } from "@apollo/client";
import { Theme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { IUserData } from "../context/userContext";
import { Colors, Typography } from "./styles.enum";
import { GET_ADVISOR_FIRM_THEME } from "./theme.service";

interface IAdvisorFirmConfig {
  primaryColor: string;
  secondaryColor: string;
}

const getTheme = (primaryColor: string, secondaryColor: string) => {
  const theme = createTheme({
    typography: {
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
      contrastThreshold: 3,
      tonalOffset: 0.4,
    },
  });
  theme.typography = {
    ...theme.typography,
    body1: {
      fontSize: Typography.Body,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    body2: {
      fontSize: Typography.Body,
      fontFamily: [Typography.FontFamily2, Typography.FontFamily1].join(","),
    },
    h1: {
      fontSize: Typography.H1,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    h2: {
      fontSize: Typography.H2,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    h3: {
      fontSize: Typography.H3,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    h4: {
      fontSize: Typography.H4,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    h5: {
      fontSize: Typography.H5,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    h6: {
      fontSize: Typography.H6,
      fontFamily: [Typography.FontFamily2, Typography.FontFamily1].join(","),
    },
    subtitle1: {
      fontSize: Typography.Footnotes,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
    subtitle2: {
      fontSize: Typography.Captions,
      fontFamily: [Typography.FontFamily1, Typography.FontFamily2].join(","),
    },
  };
  return theme;
};

export const defaultTheme = getTheme(Colors.Teal, Colors.Black);

const useDynamicTheme = (userData: IUserData) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const { data, error, loading, refetch } = useQuery(GET_ADVISOR_FIRM_THEME);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (!error && !loading && data && data.advisorFirmConfig) {
      const { primaryColor, secondaryColor }: IAdvisorFirmConfig =
        data.advisorFirmConfig;
      if (primaryColor === "" || secondaryColor === "") return;
      const theme = getTheme(primaryColor, secondaryColor);
      setTheme(theme);
    }
    // eslint-disable-next-line
  }, [data]);

  return [theme];
};
export default useDynamicTheme;
