import { gql } from '@apollo/client';

export const GET_STATEMENTS_PDF_BY_HILDA_ID = gql`
  query StatementsPDFByHildaId(
    $searchCriteria: SearchCriteria
    $where: WhereHilda!
  ) {
    statementsPDFByHildaId(searchCriteria: $searchCriteria, where: $where) {
      accountName
      subdomain
      accountId
      startPeriodDate
      endPeriodDate
      advisorName
      productName
      bankName
      begginingBalance
      netTransaction
      netAccruedInterest
      endingBalance
      apyPeriod
      transactions {
        amount
        postedDate
        createdAt
        status {
          status
        }
        type {
          type
        }
      }
      allocationHistory {
        amount
        bank {
          name
          fdic
        }
      }
    }
  }
`;
