export enum HildaType {
  Individual = '1',
  Joint = '2',
  Trust = '3',
  Institutional = '4',
}

type KeyString = { [key: string]: any };

export const hildaTypesDic: KeyString = {
  '1': 'individual',
  '2': 'joint',
  '3': 'trust',
  '4': 'institutional',
};
