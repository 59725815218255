import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
} from '@material-ui/core';
import {
  BUTTON_APPLY,
  BUTTON_CANCEL,
  BUTTON_FILTER_BY,
} from '../../../shared/utils/textConstants';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { useStyles } from './checkboxFilter.styles';
import { ICheckboxItem } from '../accountsSearch';

interface ICheckboxFilterProps {
  checkboxItems: ICheckboxItem[];
  setCheckboxItems: any;
}

const CheckboxFilter = (props: ICheckboxFilterProps) => {
  const { checkboxItems, setCheckboxItems } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [localCheckboxes, setLocalCheckboxes] = useState(checkboxItems);

  useEffect(() => {
    setLocalCheckboxes(checkboxItems);
  }, [checkboxItems]);

  useEffect(() => {
      if (localCheckboxes.filter((item) => !item.checked).length > 0) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
  }, [localCheckboxes]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLocalCheckboxes(checkboxItems);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      const newArr = localCheckboxes.map((item) => {
        return { ...item, checked: false };
      });
      setLocalCheckboxes(newArr);
      setSelectAll(false);
    } else {
      const newArr = localCheckboxes.map((item) => {
        return { ...item, checked: true };
      });
      setLocalCheckboxes(newArr);
      setSelectAll(true);
    }
  };

  const handleSelect = (checkboxItem: ICheckboxItem) => {
    const newArr = localCheckboxes.map((item) => {
      if (item.status === checkboxItem.status) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setLocalCheckboxes(newArr);
  };

  const handleApply = () => {
    setAnchorEl(null);
    setCheckboxItems(localCheckboxes);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        aria-haspopup="true"
        variant="contained"
        className={classes.filterButton}
        onClick={handleClick}
      >
        {BUTTON_FILTER_BY}
      </Button>
      <Popover
        id={id}
        className={classes.popoverContainer}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverContainer}>
          <FormControl component="fieldset" className={classes.selectAll}>
            <FormGroup aria-label="position">
              <FormControlLabel
                className={classes.checkboxText}
                value="all"
                control={
                  <Checkbox
                    id="selectAll"
                    color="primary"
                    icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                }
                label="Select All"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
          <Divider />
          <FormControl component="fieldset" className={classes.selectStatus}>
            <FormGroup aria-label="position">
              {localCheckboxes.map((item, index) => (
                <FormControlLabel
                  key={index}
                  className={classes.checkboxText}
                  value={item.status}
                  control={
                    <Checkbox
                      id={`checkbox${index}`}
                      color="primary"
                      icon={
                        <CheckBoxOutlineBlankOutlinedIcon color="primary" />
                      }
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      checked={item.checked}
                      onChange={() => handleSelect(item)}
                    />
                  }
                  label={item.status}
                  labelPlacement="end"
                />
              ))}
            </FormGroup>
          </FormControl>
          <Divider />
          <div className={classes.buttonsContainer}>
            <Button
              size="small"
              color="primary"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              {BUTTON_CANCEL}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.applyButton}
              onClick={handleApply}
            >
              {BUTTON_APPLY}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CheckboxFilter;
