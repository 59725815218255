import { createStyles, makeStyles, withStyles } from '@material-ui/core';
import { Colors, Typography } from '../../../shared/theme/styles.enum';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    accordionSummary: {
      display: 'flex',
      borderTop: 'none',
      borderBottom: '0.5px solid',
      borderBottomColor: Colors.BorderLine,
      justifyContent: 'flex-start',
      boxShadow: 'none',
      '&>:nth-child(1)': {
        flexGrow: 0,
      },
    },
    link:{
      textDecoration: 'none'
    },
    statusDescriptionItems: {
      padding: '5px 0px 5px 10px',
      fontFamily: Typography.FontFamily2,
      fontSize: '1.375rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.91,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
    list: {
      width: '100%',
      padding: 0,
    },
    navLink: {},
    navLinkActive: {
      display: 'flex',
      borderLeft: '5px solid',
      borderLeftColor: palette.primary.main,
      backgroundColor: Colors.TableHover,
    },
    listItem: {
      color: Colors.TextGray,
      cursor: 'pointer',
      padding: '15px 0px 15px 22.9px',
      '&:hover': {
        backgroundColor: Colors.TableHover,
      },
    },
    accountNamesItems: {
      margin: '5px 0px 5px 36px',
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H5,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.11,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: Colors.TextGray,
    },
  })
);

export const AccordionOverrideCss = withStyles({
  '@global': {
    '.MuiAccordion-root': {
      margin: '0 !important',
      border: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 !important',
    },
    '.MuiAccordionSummary-content': {
      margin: '16px 0',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      minHeight: '60 !important',
      margin: '-12px 0 -12px 0',
    },
    '.MuiAccordionSummary-expandIcon': {
      transform: 'rotate(90deg)',
    },
  },
})(() => null);
