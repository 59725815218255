import { makeStyles, StepConnector, withStyles } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';

export const StepperOverrideCss = withStyles(({ palette }) => ({
  '@global': {
    '.MuiStepIcon-root': {
      // color: 'rgba(63, 167, 149, 0.5)',
      color: palette.primary.main,
      opacity: 1,
    },
    '.MuiStepIcon-root .MuiStepIcon-completed': {
      color: palette.primary.main,
    },
  },
}))(() => null);

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
  },
  active: {
    '& $line': {
      borderColor: Colors.BorderAndBG,
    },
  },
  completed: {
    '& $line': {
      borderColor: Colors.BorderAndBG,
    },
  },
  line: {
    borderColor: Colors.BorderAndBG,
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

export const useQontoStepIconStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    opacity: 0.5,
    // color: 'rgba(63, 167, 149, 0.5)',
    // color: 'rgba(`${palette.primary.main}`, 0.5)',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  circle: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    // backgroundColor: 'currentColor',
    backgroundColor: palette.primary.main,
    // opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: palette.primary.main,
    opacity: 1,
  },

  completed: {
    color: palette.primary.main,
    // opacity: 0.9,
    width: 34,
    height: 34,
    borderRadius: '50%',
    backgroundColor: palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    color: Colors.White,
    fontSize: '1.25rem',
  },
  text: {
    fontSize: Typography.Captions,
  },
}));
