import {
  Backdrop,
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Typography as MUITypography,
} from "@material-ui/core";
import { Typography } from "../../shared/theme/styles.enum";

import * as React from "react";

type GlobalMessageContextData = {
  showMessage: (message: string) => void;
  closeMessage: () => void;
};

export const GlobalMessageContext =
  React.createContext<GlobalMessageContextData>({
    showMessage: (message: string) => undefined,
    closeMessage: () => undefined,
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backDrop: {
      color: "black",
      zIndex: 10000,
      backgroundColor: "white",
      textAlign: "center",
    },
    backDropMessage: {
      fontFamily: Typography.FontFamily2,
      fontSize: Typography.H3,
    },
    linearProgress: {
      marginTop: "20px",
    },
  })
);
const GlobalMessageProvider: React.FC<{ children: any }> = ({ children }) => {
  const [showBackdrop, setShowBackdrop] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const classes = useStyles();
  const showMessage = React.useCallback((newMessage: string) => {
    setMessage(newMessage);
    setShowBackdrop(true);
  }, []);
  const closeMessage = React.useCallback(() => {
    setMessage("");
    setShowBackdrop(false);
  }, []);
  return (
    <GlobalMessageContext.Provider value={{ showMessage, closeMessage }}>
      <Backdrop className={classes.backDrop} open={showBackdrop}>
        <Box sx={{ width: "70%" }}>
          <MUITypography variant="caption" className={classes.backDropMessage}>
            {message}
          </MUITypography>
          <LinearProgress className={classes.linearProgress} />
        </Box>
      </Backdrop>
      {children}
    </GlobalMessageContext.Provider>
  );
};

export default GlobalMessageProvider;
