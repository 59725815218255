import { NumberFormatBase } from "react-number-format";
export const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormatBase
      {...other}
      getInputRef={inputRef}
      format={(numStr) => {
        if (numStr === "") return "";
        let newValue = numStr;
        newValue = newValue.replace(/\D/g, "");
        newValue = newValue.replace(/(\d)(\d{2})$/, "$1.$2");
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, ",");
        return newValue;
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
    />
  );
};
